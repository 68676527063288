import React, { lazy, useEffect, useState, type ComponentType, type FC } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { Button } from '@ldfeplatform/componentlibrary.ui.atoms.button';

import { IErrorDisplay } from './error-display.interface';
import styles from './error-display.module.scss';

import Footer from '@/components/footer';
import Masthead from '@/components/masthead';
import { Spinner } from '@/components/shared';
import { alertOctagonIcon, SdmProvince, banners } from '@/data/constants';
import { getErrorMessage } from '@/utils';
import { useDetermineBanner } from '@/hooks';
import { initialData, themeTokens } from '../../__mocks__/data/header-footer-design-data';

const ErrorDisplay: FC<IErrorDisplay> = ({ error = {} }) => {
  const { b3, status } = error;
  const router = useRouter();
  const { formatMessage, locale } = useIntl();
  const [ViewResolverSDM, setViewResolverSDM] = useState<ComponentType<any> | null>(null);
  const isPharmaprix = useDetermineBanner();
  const banner = isPharmaprix ? banners.PHARMAPRIX : banners.SHOPPERS;
  const { titleId, subtitleId } = getErrorMessage(status);
  const commonContextProps = {
    settings: {
      locale,
    },
    analytics: {
      trackEvent: () => {},
    },
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      setViewResolverSDM(lazy(() => import('ldpViewResolverSdm/Resolver')));
    }
  }, []);

  if (!ViewResolverSDM) {
    return <Spinner />;
  }

  return (
    <ViewResolverSDM
      hadExistingSentry={true}
      isPreview={false}
      previewDatetime={'2023-06-16T15:50-04:00'}
      commonContexts={commonContextProps}
      queryConfig={{
        initialData,
        staleTime: Infinity,
      }}
      themeTokens={themeTokens}
      PageLoadingFallback={() => <Spinner />}
      locale={locale}
      env={process.env.NEXT_PUBLIC_VIEW_RESOLVER_ENV}
    >
      <Masthead headerSection={initialData['header']} />
      <div className={styles.pageNotFoundContainer}>
        <img src={alertOctagonIcon} alt="alert" width={35} height={35} />
        <h1>{formatMessage({ id: titleId })}</h1>
        <h2>{formatMessage({ id: subtitleId })}</h2>
        <button onClick={() => router.push('/')}>{formatMessage({ id: 'goToHomePage' })}</button>
      </div>
      <p className={styles.traceId}>traceID: {b3}</p>
      <Footer />
    </ViewResolverSDM>
  );
};

export default ErrorDisplay;
