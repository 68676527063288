import { useSDMCustomerContext } from '@ldp/sdm-contexts';
import { lazy, useEffect, useState, type ComponentType, type FC } from 'react';

import { SdmProvince } from '@/data/constants';
import { isPharmaprixBanner, setSelectedProvinceCookie } from '@/utils';
import { IMasthead } from './masthead.interface';

let provinceSelectorLock = false;

const Masthead: FC<IMasthead> = ({ headerSection }) => {
  const { region } = headerSection as any;
  const { provinceId, setProvinceId } = useSDMCustomerContext();

  const [MastheadComponent, setMastheadComponent] = useState<ComponentType<any> | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      setMastheadComponent(lazy(() => import('ldpViewResolverSdm/MastheadComponent')));
    }
  }, []);

  useEffect(() => {
    if (!isPharmaprixBanner() && region && Object.values(SdmProvince).includes(region as SdmProvince)) {
      setProvinceId(region);
    }
  }, []);

  useEffect(() => {
    // this only happens during page initialization, so we make sure to ignore the province value jumping back & forth
    provinceSelectorLock = true;
    setTimeout(() => {
      provinceSelectorLock = false;
    }, 500);
  }, []);

  useEffect(() => {
    setSelectedProvinceCookie(provinceId);
    if (!isPharmaprixBanner() && !provinceSelectorLock) {
      window.location.href = window.location.href.replace(/province=[A-Z]{2}&?/, '').replace(/[&?]$/, '');
    }
  }, [provinceId]);

  return headerSection && MastheadComponent ? <MastheadComponent /> : null;
};

export default Masthead;
