import React from 'react';

export const initialData = {
  header: {
    typeId: 'mastheadLayout',
    topLeftSection: {
      backgroundColour: '#CB333B',
      alignment: 'LEFT',
      components: [
        {
          componentId: 'informationTextComponent',
          contentId: '5QIa8WezUTVccCDKAOBn6D',
          variantId: 'LIGHT',
          id: '5QIa8WezUTVccCDKAOBn6D',
          data: {
            variantId: 'LIGHT',
            displayText1: 'Same-day delivery when you shop Shoppers Drug Mart products on Instacart and DoorDash.',
            callToAction: {
              text: 'Learn more',
              url: 'https://www.shoppersdrugmart.ca/page/same-day-delivery',
            },
          },
        },
      ],
    },
    topRightSection: {
      alignment: 'RIGHT',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: 'vEZ4ol5YkOUfseFSS84wV',
          variantId: 'LIGHT',
          id: 'vEZ4ol5YkOUfseFSS84wV',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Sign%20in_padded.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Sign%20in_my%20account_mobileinitialicon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/sign%20in_my%20account_mobilefilledicon.svg',
            },
            label: 'Sign In',
            callToAction: {
              text: 'Sign In',
              url: '/login',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
        {
          componentId: 'navigationItemComponent',
          contentId: '6Pv9I36SCfRPcOKUyvp62H',
          variantId: 'DARK',
          id: '6Pv9I36SCfRPcOKUyvp62H',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/location_on.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/location_on_Red%20on%20hover.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Store%20service_initial_icon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Store%20services_mobilefilledicon.svg',
            },
            label: 'Store & Services',
            callToAction: {
              text: 'Store & Services',
              url: 'https://www.shoppersdrugmart.ca/en/store-locator',
            },
            showCount: false,
            dropdownItems: [
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Passport Photos',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/passport',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Fresh Food',
                  url: '/en/more-by-shoppers/fresh-food',
                },
              },
              {
                variantId: 'BUTTON',
                callToAction: {
                  text: 'Presto',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/presto',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Find a Store',
                  url: 'https://www.shoppersdrugmart.ca/en/store-locator',
                },
              },
            ],
            dropdownOpenIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/expand_more_white.svg',
            },
            dropdownCloseIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/expand_less_white_icon.svg',
            },
          },
        },
      ],
    },
    logoSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'logoWithCtaComponent',
          contentId: 'TKYMh0r7NuCAwlmTzRiLD',
          id: 'TKYMh0r7NuCAwlmTzRiLD',
          data: {
            largeLogo: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/SDM%20Logo.png',
              altText: 'Shoppers Drug Mart',
            },
            mediumLogo: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/SDM%20Logo.png',
              altText: 'Shoppers Drug Mart',
            },
            smallLogo: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/SDM%20logo-small.svg',
            },
            callToAction: {
              text: ' ',
              url: 'https://www.shoppersdrugmart.ca/?lang=en',
            },
          },
        },
      ],
    },
    storeSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: '6Pv9I36SCfRPcOKUyvp62H',
          variantId: 'DARK',
          id: '6Pv9I36SCfRPcOKUyvp62H',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/location_on.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/location_on_Red%20on%20hover.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Store%20service_initial_icon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Store%20services_mobilefilledicon.svg',
            },
            label: 'Store & Services',
            callToAction: {
              text: 'Store & Services',
              url: 'https://www.shoppersdrugmart.ca/en/store-locator',
            },
            showCount: false,
            dropdownItems: [
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Passport Photos',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/passport',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Fresh Food',
                  url: '/en/more-by-shoppers/fresh-food',
                },
              },
              {
                variantId: 'BUTTON',
                callToAction: {
                  text: 'Presto',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/presto',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Find a Store',
                  url: 'https://www.shoppersdrugmart.ca/en/store-locator',
                },
              },
            ],
            dropdownOpenIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/expand_more_white.svg',
            },
            dropdownCloseIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/expand_less_white_icon.svg',
            },
          },
        },
      ],
    },
    searchSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'searchBarComponent',
          contentId: '1qiJ8xCCLGfwnSZPq5h7tX',
          id: '1qiJ8xCCLGfwnSZPq5h7tX',
          data: {
            displayText1: 'Search',
          },
        },
      ],
    },
    featuredServicesSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: '15VOjOPnRT8slfxjG51iaw',
          variantId: 'DARK',
          id: '15VOjOPnRT8slfxjG51iaw',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Pill%20icon_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/Pill%20icon_on%20hover.svg',
            },
            label: 'Refills',
            callToAction: {
              text: 'Refills',
              url: 'https://www.shoppersdrugmart.ca/en/health/online-prescription-management',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
        {
          componentId: 'navigationItemComponent',
          contentId: '2FIQsFerZDVVt7kKNCG5NY',
          variantId: 'DARK',
          id: '2FIQsFerZDVVt7kKNCG5NY',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/favorite_border_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/my%20shop_on%20hover.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/my%20shop_mobileinitialicon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/my%20shop_mobilefilledicon.svg',
            },
            label: 'Favourites',
            callToAction: {
              text: 'Favourites',
              url: 'https://shop.shoppersdrugmart.ca/favourite',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
      ],
    },
    cartSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: '47yrTTtV6rc1SDwFvznhz9',
          variantId: 'DARK',
          id: '47yrTTtV6rc1SDwFvznhz9',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/shopping_bag_empty_on%20Hover.svg',
            },
            filledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_items%20in%20bag_padded.svg',
            },
            label: 'Bag',
            callToAction: {
              text: 'Bag',
              url: '/shoppingbag?lang=en',
            },
            showCount: false,
            dropdownItems: [],
            count: 0,
          },
        },
        {
          componentId: 'navigationItemComponent',
          contentId: 'FjNx0bkO2fFqz1JI9G7FR',
          variantId: 'DARK',
          id: 'FjNx0bkO2fFqz1JI9G7FR',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_items%20in%20bag_padded.svg',
            },
            label: 'Checkout',
            callToAction: {
              text: 'Checkout',
              url: 'https://shop.shoppersdrugmart.ca/purchase',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
      ],
    },
    categoryNavigationSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '2BcjlKpVtPQJtx4LCvZFUX',
          id: '2BcjlKpVtPQJtx4LCvZFUX',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '2BcjlKpVtPQJtx4LCvZFUX',
            id: '2BcjlKpVtPQJtx4LCvZFUX',
            callToAction: {
              text: 'Flyer & Deals',
              url: 'https://www.shoppersdrugmart.ca/en/flyer',
            },
            l2Data: [
              {
                hierarchy: ['Flyer & Deals', 'See Flyer & Deals'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7pzkjritwncBmvaraMrUvc',
                id: '7pzkjritwncBmvaraMrUvc',
                callToAction: {
                  text: 'See Flyer & Deals',
                  url: 'https://shop.shoppersdrugmart.ca/Promotions',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Flyer & Deals', 'Offers'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4fVKbSifVagEWyCSIJkJ5p',
                id: '4fVKbSifVagEWyCSIJkJ5p',
                callToAction: {
                  text: 'Offers',
                  url: 'https://shop.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2Foffers&sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2qQ6od3gGDR43z5N3FM0Rc',
                    id: '2qQ6od3gGDR43z5N3FM0Rc',
                    l3Data: [
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Shop All Offers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2m0ityEDCWVg5SS6Mxfcqf',
                        id: '2m0ityEDCWVg5SS6Mxfcqf',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Offers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Sale'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5xKfqqX8X8RJ51DoIrdbmy',
                        id: '5xKfqqX8X8RJ51DoIrdbmy',
                        l4Data: [],
                        callToAction: {
                          text: 'Sale',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3Apromotions%3ASale%3AshowInStock%3Atrue&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Clearance'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4JVaOe6an35PwgF42cJUdx',
                        id: '4JVaOe6an35PwgF42cJUdx',
                        l4Data: [],
                        callToAction: {
                          text: 'Clearance',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2Fclearance&q=%3Atrending%3Apromotions%3AClearance%3AshowInStock%3Atrue&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Gift With Purchase'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3NAj7kGTPAnfNtXz36sN3Y',
                        id: '3NAj7kGTPAnfNtXz36sN3Y',
                        l4Data: [],
                        callToAction: {
                          text: 'Gift With Purchase',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FGWP&q=%3Atrending%3Apromotions%3AGift%2Bwith%2BPurchase%3AshowInStock%3Atrue&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'PC Optimum Offers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5VZkaScF13hWAqWlrXqDa1',
                        id: '5VZkaScF13hWAqWlrXqDa1',
                        l4Data: [],
                        callToAction: {
                          text: 'PC Optimum Offers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2Foffers&sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue%3Apromotions%3APC%2BOptimum%2BOffer',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '2tEXN3tn7NN5pnLjgaDrGY',
                    id: '2tEXN3tn7NN5pnLjgaDrGY',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/personal-care/wk25_featurecollecctiontile_toms_enfr.png',
                      },
                      callToAction: {
                        text: "Tom's of Maine",
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-PC-BathBodyCare?sort=trending&page=0&q=%3Atrending%3AbrandName%3ATom%27s+of+Maine',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: 'hxOl6SC0mTzjwYJzOIg2w',
                    id: 'hxOl6SC0mTzjwYJzOIg2w',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/health/wk25_featurecollecctiontile_webber_enfr.png',
                      },
                      callToAction: {
                        text: 'Vitamins & Supplements by Webber',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-HT-VitaminsAndSupplements?page=0&q=%3Atrending%3AshowInStock%3Atrue%3AbrandName%3AWebber&sort=trending',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '1r2QliRx0742W8kkcVJRt5',
                    id: '1r2QliRx0742W8kkcVJRt5',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk21/personal-care/wk21_featurecollecctiontile_rawsugar_enfr.png',
                      },
                      callToAction: {
                        text: 'Me-time with Raw Sugar',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-PC-BathBodyCare?page=0&q=%3Atrending%3AshowInStock%3Atrue%3AbrandName%3ARaw+Sugar&sort=trending',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '1OFaq6hSgfjxwrWuu2Yi8l',
                    id: '1OFaq6hSgfjxwrWuu2Yi8l',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk21/personal-care/wk21_featurecollecctiontile_native_enfr.png',
                      },
                      callToAction: {
                        text: 'New by Native',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-PC-HairCare?page=0&q=%3Atrending%3AbrandName%3ANative&sort=trending',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Flyer & Deals', 'Flyer'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4jQNc0nBxPH1cNva4Zxf2S',
                id: '4jQNc0nBxPH1cNva4Zxf2S',
                callToAction: {
                  text: 'Flyer',
                  url: 'https://www.shoppersdrugmart.ca/en/flyer',
                },
                columns: [],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '7m83adY5vSgsvFLJjwWvDc',
          id: '7m83adY5vSgsvFLJjwWvDc',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '7m83adY5vSgsvFLJjwWvDc',
            id: '7m83adY5vSgsvFLJjwWvDc',
            callToAction: {
              text: 'The Wellness Shop',
              url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3tTZcMFyxAkNKa71cLFbKm',
                id: '3tTZcMFyxAkNKa71cLFbKm',
                callToAction: {
                  text: 'Shop The Wellness Shop',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3tTZcMFyxAkNKa71cLFbKm',
                id: '3tTZcMFyxAkNKa71cLFbKm',
                callToAction: {
                  text: 'Shop The Wellness Shop',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7t60FULzFPCm1QMZ4oQjrj',
                id: '7t60FULzFPCm1QMZ4oQjrj',
                callToAction: {
                  text: 'Shop The Wellness Shop',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3DaIsAnYUY9D2jxlTg0V52',
                    id: '3DaIsAnYUY9D2jxlTg0V52',
                    l3Data: [
                      {
                        hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop', 'The Wellness Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7zt9WspnfFdBLtPoXd5DMd',
                        id: '7zt9WspnfFdBLtPoXd5DMd',
                        l4Data: [],
                        callToAction: {
                          text: 'The Wellness Shop',
                          url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop', 'Vitamins & Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '75BXLDW11oBLbGjp7SF8Lh',
                        id: '75BXLDW11oBLbGjp7SF8Lh',
                        l4Data: [],
                        callToAction: {
                          text: 'Vitamins & Supplements',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/vitamins-&-supplements/c/57140?nav=/shop/vitaminssupplements',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop', 'Fitness & Exercise'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2oHx5s7dyohAnqVYYlZa8L',
                        id: '2oHx5s7dyohAnqVYYlZa8L',
                        l4Data: [],
                        callToAction: {
                          text: 'Fitness & Exercise',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSFitnessExercise?nav=/shop/fitnessexercise',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop', 'Sexual Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'BOxR39xvthelu7JN2OZaj',
                        id: 'BOxR39xvthelu7JN2OZaj',
                        l4Data: [],
                        callToAction: {
                          text: 'Sexual Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSexualHealth?nav=/shop/sexualhealth',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop', 'Sleep Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6yeDjwOjQMM17gIGRqfGGS',
                        id: '6yeDjwOjQMM17gIGRqfGGS',
                        l4Data: [],
                        callToAction: {
                          text: 'Sleep Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSleepHealth?nav=/shop/sleephealth',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop The Wellness Shop', 'Relaxation & Stress Relief'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2KbbUZLAQyaOxmPcedYCcd',
                        id: '2KbbUZLAQyaOxmPcedYCcd',
                        l4Data: [],
                        callToAction: {
                          text: 'Relaxation & Stress Relief',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSRelaxationStress?nav=/shop/relaxationstress',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: 'hxOl6SC0mTzjwYJzOIg2w',
                    id: 'hxOl6SC0mTzjwYJzOIg2w',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/health/wk25_featurecollecctiontile_webber_enfr.png',
                      },
                      callToAction: {
                        text: 'Vitamins & Supplements by Webber',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-HT-VitaminsAndSupplements?page=0&q=%3Atrending%3AshowInStock%3Atrue%3AbrandName%3AWebber&sort=trending',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['The Wellness Shop', 'Shop By Category'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2qou30uA4l8jQS0oEUZT1J',
                id: '2qou30uA4l8jQS0oEUZT1J',
                callToAction: {
                  text: 'Shop By Category',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7yvO17pHOszzX3wbooKoLi',
                    id: '7yvO17pHOszzX3wbooKoLi',
                    l3Data: [
                      {
                        hierarchy: ['The Wellness Shop', 'Shop By Category', 'Vitamins & Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '75BXLDW11oBLbGjp7SF8Lh',
                        id: '75BXLDW11oBLbGjp7SF8Lh',
                        l4Data: [],
                        callToAction: {
                          text: 'Vitamins & Supplements',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/vitamins-&-supplements/c/57140?nav=/shop/vitaminssupplements',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop By Category', 'Fitness & Exercise'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2oHx5s7dyohAnqVYYlZa8L',
                        id: '2oHx5s7dyohAnqVYYlZa8L',
                        l4Data: [],
                        callToAction: {
                          text: 'Fitness & Exercise',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSFitnessExercise?nav=/shop/fitnessexercise',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop By Category', 'Sexual Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'BOxR39xvthelu7JN2OZaj',
                        id: 'BOxR39xvthelu7JN2OZaj',
                        l4Data: [],
                        callToAction: {
                          text: 'Sexual Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSexualHealth?nav=/shop/sexualhealth',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop By Category', 'Sleep Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6yeDjwOjQMM17gIGRqfGGS',
                        id: '6yeDjwOjQMM17gIGRqfGGS',
                        l4Data: [],
                        callToAction: {
                          text: 'Sleep Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSleepHealth?nav=/shop/sleephealth',
                        },
                      },
                      {
                        hierarchy: ['The Wellness Shop', 'Shop By Category', 'Relaxation & Stress Relief'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2KbbUZLAQyaOxmPcedYCcd',
                        id: '2KbbUZLAQyaOxmPcedYCcd',
                        l4Data: [],
                        callToAction: {
                          text: 'Relaxation & Stress Relief',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSRelaxationStress?nav=/shop/relaxationstress',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '3QGXie9o0EB37ffqCuV0PJ',
          id: '3QGXie9o0EB37ffqCuV0PJ',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '3QGXie9o0EB37ffqCuV0PJ',
            id: '3QGXie9o0EB37ffqCuV0PJ',
            callToAction: {
              text: 'Beauty',
              url: 'https://shop.shoppersdrugmart.ca/Beauty?nav=/shop/beauty',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Beauty', 'Shop All Beauty'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5Ncv7Xj6DIgD8f0l9ocAcp',
                id: '5Ncv7Xj6DIgD8f0l9ocAcp',
                callToAction: {
                  text: 'Shop All Beauty',
                  url: 'https://shop.shoppersdrugmart.ca/Beauty?nav=/shop/beauty',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Beauty', 'Skin Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5oFpRsigfDQYe9tgQN0bgi',
                id: '5oFpRsigfDQYe9tgQN0bgi',
                callToAction: {
                  text: 'Skin Care',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Skin-Care/c/FS-SkinCare?nav=%252Fshop%252FCategories%252FSkinCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1gh9gEHKqMUtIfJNgFVI6B',
                    id: '1gh9gEHKqMUtIfJNgFVI6B',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Shop All Skin Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6ecbkwb8UXzYjCIM6AeZsV',
                        id: '6ecbkwb8UXzYjCIM6AeZsV',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Skin Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/skin-care/c/FS-SkinCare?nav=%2Fshop%2FCategories%2FSkinCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'QC0Lr4vfLniPYR6crivOC',
                        id: 'QC0Lr4vfLniPYR6crivOC',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-SkinCare&nav=%2Fshop%2FSkinCare%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7dfUYtNNyh8oi2Ijm3LaKO',
                        id: '7dfUYtNNyh8oi2Ijm3LaKO',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/best-sellers/skin-care-best-sellers/c/BeautySkinCareBestSellers?nav=%2Fshop%2FCategories%2FSkinCare%2FBestSellers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Face Moisturizers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4rKNnqding4xXaaoM1oDab',
                        id: '4rKNnqding4xXaaoM1oDab',
                        l4Data: [
                          {
                            text: 'Moisturizers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Moisturizers/c/FS-S101?nav=shop/Categories/SkinCare/Moisturizers',
                          },
                          {
                            text: 'Night Cream',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/NightCream/c/FS-S102?nav=shop/Categories/SkinCare/NightCream',
                          },
                          {
                            text: 'Face Oils',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FaceOils/c/FS-S103?nav=shop/Categories/SkinCare/FaceOils',
                          },
                          {
                            text: 'Neck & Décolleté',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/NeckAndD%C3%A9collet%C3%A9/c/FS-S104?nav=shop/Categories/SkinCare/NeckAndD%C3%A9collet%C3%A9',
                          },
                          {
                            text: 'Face Mists',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FaceMists/c/FS-S105?nav=shop/Categories/SkinCare/FaceMists',
                          },
                          {
                            text: 'Tinted Moisturizer',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/TintedMoisturizer/c/FS-A110?nav=/shop/Categories/Makeup/TintedMoisturizer',
                          },
                        ],
                        callToAction: {
                          text: 'Face Moisturizers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FaceMoisturizers/c/FS-S100?nav=/shop/Categories/SkinCare/FaceMoisturizers',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Sun Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2BSqok0gJpYMzkrt2wg7bO',
                        id: '2BSqok0gJpYMzkrt2wg7bO',
                        l4Data: [
                          {
                            text: 'Face Sunscreen',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/bath-and-body-care/sun-care/face-sunscreen/c/FS-PC-B601?nav=%252Fshop%252FCategories%252FBathBodyCare%252FFaceSunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'Body Sunscreen',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Sun-Care/Body-Sunscreen/c/FS-PC-B602?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodySunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'After Sun',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Sun-Care/After-Sun/c/FS-PC-B603?nav=%252Fshop%252FCategories%252FBathBodyCare%252FAfterSun&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Sun Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Sun-Care/c/FS-PC-B600?nav=%252Fshop%252FCategories%252FBathBodyCare%252FSunCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '75kZQijHypojtZ9EgVNxGR',
                    id: '75kZQijHypojtZ9EgVNxGR',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Cleansers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5BPYpuFBIkjzxXMBysAI2w',
                        id: '5BPYpuFBIkjzxXMBysAI2w',
                        l4Data: [
                          {
                            text: 'Face Wash & Cleansers',
                            url: 'https://shop.shoppersdrugmart.ca/c/FS-S201?nav=shop/Catrgories/SkinCare/Cleansers/FaceWashAndCleansers',
                          },
                          {
                            text: 'Face Scrubs & Exfoliators',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FaceScrubsAndExfoliators/c/FS-S202?nav=shop/Categories/SkinCare/FaceScrubsAndExfoliators',
                          },
                          {
                            text: 'Facial Wipes & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialWipesAndAccessories/c/FS-T202?nav=/shop/Categories/SkinCare/FacialWipesAndAccessories',
                          },
                          {
                            text: 'Makeup Remover',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupRemover/c/FS-A115?nav=/shop/Categories/Makeup/MakeupRemover',
                          },
                          {
                            text: 'Toners',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Toners/c/FS-S205?nav=shop/Categories/SkinCare/Toners',
                          },
                          {
                            text: 'Facial Cleansing Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialCleansingTools/c/FS-S206?nav=shop/Categories/SkinCare/FacialCleansingTools',
                          },
                        ],
                        callToAction: {
                          text: 'Cleansers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Cleansers/c/FS-S200?nav=/shop/Categories/SkinCare/Cleansers',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Treatments'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2pWL4XhONjZmgd32Y1T9QP',
                        id: '2pWL4XhONjZmgd32Y1T9QP',
                        l4Data: [
                          {
                            text: 'Serums',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Serums/c/FS-S301?nav=shop/Categories/SkinCare/Serums',
                          },
                          {
                            text: 'Anti-Aging',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/AntiAging/c/FS-S302?nav=shop/Categories/SkinCare/AntiAging',
                          },
                          {
                            text: 'Brightening',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Brightening/c/FS-S305?nav=shop/Categories/SkinCare/Brightening',
                          },
                          {
                            text: 'Acne & Blemish Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/AcneAndBlemishTreatments/c/FS-S303?nav=shop/Categories/SkinCare/AcneAndBlemishTreatments',
                          },
                          {
                            text: 'Facial Peels',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialPeels/c/FS-S304?nav=shop/Categories/SkinCare/FacialPeels',
                          },
                        ],
                        callToAction: {
                          text: 'Treatments',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Treatments/c/FS-S300?nav=/shop/Categories/SkinCare/Treatments',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7CzP9WzBzj0EahVVGHMS0D',
                    id: '7CzP9WzBzj0EahVVGHMS0D',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Masks'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '39FKXyV81IsBYs7DgfhVbO',
                        id: '39FKXyV81IsBYs7DgfhVbO',
                        l4Data: [
                          {
                            text: 'Face Masks',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FaceMasks/c/FS-S401?nav=shop/Categories/SkinCare/FaceMasks',
                          },
                          {
                            text: 'Sheet Masks',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/SheetMasks/c/FS-S402?nav=shop/Categories/SkinCare/SheetMasks',
                          },
                          {
                            text: 'Eye Masks',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/EyeMasks/c/FS-S403?nav=shop/Categories/SkinCare/EyeMasks',
                          },
                        ],
                        callToAction: {
                          text: 'Masks',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/Masks/c/FS-S400?nav=shop/Categories/SkinCare/Masks',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Eye Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2yxg008aYpx7i8b2QkdPa2',
                        id: '2yxg008aYpx7i8b2QkdPa2',
                        l4Data: [
                          {
                            text: 'Eye Creams & Treatment',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/EyeCreamsAndTreatment/c/FS-S501?nav=/shop/Categories/SkinCare/EyeCreamsAndTreatment',
                          },
                          {
                            text: 'Eye Masks',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/EyeMasks/c/FS-S403?nav=shop/Categories/SkinCare/EyeMasks',
                          },
                        ],
                        callToAction: {
                          text: 'Eye Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/EyeCare/c/FS-S500?nav=/shop/Categories/SkinCare/EyeCare',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Lip Balm & Treatment'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '32LMDXYjHPC5buir8coyPa',
                        id: '32LMDXYjHPC5buir8coyPa',
                        l4Data: [],
                        callToAction: {
                          text: 'Lip Balm & Treatment',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/LipBalmAndTreatment/c/FS-S999?nav=/shop/Categories/SkinCare/LipBalmAndTreatment',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Skin Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4sNrSzP5JOFpLGao7cTLiI',
                        id: '4sNrSzP5JOFpLGao7cTLiI',
                        l4Data: [
                          {
                            text: 'Cleansers & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/cleansers-and-accessories/c/FS-M102?nav=shop%2FCategories%2FSkinCare%2FFaceSunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'Lip Balm',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/lip-balm/c/FS-M104?nav=%2Fshop%2FCategories%2FSkinCare%2FBodySunscreen&sort=trending&page=0&lang=en',
                          },
                          {
                            text: 'Moisturizers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/moisturizers/c/FS-M101?nav=shop%2FCategories%2FSkinCare%2FAfterSun&sort=trending&page=0',
                          },
                          {
                            text: 'Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/treatments/c/FS-M103?nav=shop%2FCategories%2FSkinCare%2FAfterSun&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Skin Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/c/FS-M100?nav=/shop/Categories/SkinCare/SunCare',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5gIu9ay4UCeIyImFneq55Y',
                    id: '5gIu9ay4UCeIyImFneq55Y',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Self Tanners'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4kHEs7VHoNJ7zTKBLzMGX3',
                        id: '4kHEs7VHoNJ7zTKBLzMGX3',
                        l4Data: [],
                        callToAction: {
                          text: 'Self Tanners',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/SelfTanners/c/FS-S900?nav=/shop/Categories/SkinCare/SelfTanners',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Skin Care Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3YZDV8ZH19TT0kRp8T8zY7',
                        id: '3YZDV8ZH19TT0kRp8T8zY7',
                        l4Data: [
                          {
                            text: 'Facial Cleansing Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialCleansingTools/c/FS-S206?nav=shop/Categories/SkinCare/FacialCleansingTools',
                          },
                          {
                            text: 'Facial Wipes & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialWipesAndAccessories/c/FS-T202?nav=/shop/Categories/SkinCare/FacialWipesAndAccessories',
                          },
                          {
                            text: 'Skin Care Treatment Devices',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/SkinCareTreatmentDevices/c/FS-T203?nav=/shop/Categories/ToolsAndAccessories/SkinCareTreatmentDevices',
                          },
                        ],
                        callToAction: {
                          text: 'Skin Care Tools & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/SkinCareToolsAndAccessories/c/FS-T200?nav=/shop/Categories/SkinCare/SkinCareToolsAndAccessories',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Body Skin Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7wmvxgYqULNdYtVT3Y0tUf',
                        id: '7wmvxgYqULNdYtVT3Y0tUf',
                        l4Data: [],
                        callToAction: {
                          text: 'Body Skin Care',
                          url: 'https://shop.shoppersdrugmart.ca/c/FS-S666?nav=beauty/skincare/bodyskincare',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Skin Care Refills'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3PvMlp9oF89IBzsQWQb1d5',
                        id: '3PvMlp9oF89IBzsQWQb1d5',
                        l4Data: [],
                        callToAction: {
                          text: 'Skin Care Refills',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Skincare-Refills/c/FS-S600',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Skin Care', 'Skin Care Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5YBpm9PcAdnQEHWwP3bPHc',
                        id: '5YBpm9PcAdnQEHWwP3bPHc',
                        l4Data: [],
                        callToAction: {
                          text: 'Skin Care Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/SkinCareGiftsAndSets/c/FS-G200?nav=/shop/Categories/SkinCare/SkinCareGiftsAndSets',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '78dMlToZJGXxtJX9yfBbqH',
                    id: '78dMlToZJGXxtJX9yfBbqH',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk02_featuredcollectiontile_skintool_b1_en.png',
                      },
                      callToAction: {
                        text: 'Try Our Virtual Skin Analysis Tool',
                        url: 'https://shop.shoppersdrugmart.ca/skindiagnostic?nav=shop/beauty/skincare/featuredcollection/skindiagnostic',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '1jbukMY99onAJdNdbln7v2',
                    id: '1jbukMY99onAJdNdbln7v2',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk09_featurecollecctiontile_p3clinicalskincare_enfr.png',
                      },
                      callToAction: {
                        text: 'Shop Skin Care By Ingredients',
                        url: 'https://shop.shoppersdrugmart.ca/Shop/Ingredients-that-work/c/2022P3CLSK?sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue&nav=%2Fshop%2FCategories%3FBeauty%2FSkinCare%2FFeaturedCollections%2FShopSkinCareByIngredients',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Beauty', 'Makeup'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5U7Cjt78FvAGFJz0vROt0W',
                id: '5U7Cjt78FvAGFJz0vROt0W',
                callToAction: {
                  text: 'Makeup',
                  url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/c/FS-MakeUp?nav=/shop/Categories/Makeup',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2uKoL5OmMpxiqGa9oYJK2B',
                    id: '2uKoL5OmMpxiqGa9oYJK2B',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Shop All Makeup'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6z3abAOIv3VDHY48VT4WJT',
                        id: '6z3abAOIv3VDHY48VT4WJT',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Makeup',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/makeup/c/FS-MakeUp?nav=/shop/Categories/Makeup',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '18ATducM0p2V9s3VI2QpJk',
                        id: '18ATducM0p2V9s3VI2QpJk',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-MakeUp&nav=%2Fshop%2FMakeUp%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '10aIfJHwuXDyBa7q4nJT2v',
                        id: '10aIfJHwuXDyBa7q4nJT2v',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/best-sellers/makeup-best-sellers/c/BeautyMakeupBestSellers?nav=%2Fshop%2FCategories%2FMakeup%2FBestSellers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Face'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1QUbkkgSnsOQrGoI6g4BUD',
                        id: '1QUbkkgSnsOQrGoI6g4BUD',
                        l4Data: [
                          {
                            text: 'Foundation',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Foundation/c/FS-A107?nav=/shop/Categories/Makeup/Foundation',
                          },
                          {
                            text: 'BB & CC Creams',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/BBAndCCCreams/c/FS-A101?nav=/shop/Categories/Makeup/BBAndCCCreams',
                          },
                          {
                            text: 'Tinted Moisturizer',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/TintedMoisturizer/c/FS-A110?nav=/shop/Categories/Makeup/TintedMoisturizer',
                          },
                          {
                            text: 'Concealer',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Concealer/c/FS-A104?nav=/shop/Categories/Makeup/Concealer',
                          },
                          {
                            text: 'Primer',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Primer/c/FS-A106?nav=/shop/Categories/Makeup/Primer',
                          },
                          {
                            text: 'Powder',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Powder/c/FS-A105?nav=/shop/Categories/Makeup/Powder',
                          },
                          {
                            text: 'Blush',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Blush/c/FS-A102?nav=/shop/Categories/Makeup/Blush',
                          },
                          {
                            text: 'Bronzer',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Bronzer/c/FS-A103?nav=/shop/Categories/Makeup/Bronzer',
                          },
                          {
                            text: 'Contour',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Contour/c/FS-A112?nav=/shop/Categories/Makeup/Contour',
                          },
                          {
                            text: 'Highlighter',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Highlighter/c/FS-A113?nav=/shop/Categories/Makeup/Highlighter',
                          },
                          {
                            text: 'Face & Cheek Palettes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/FaceAndCheekPalettes/c/FS-A114?nav=/shop/Categories/Makeup/FaceAndCheekPalettes',
                          },
                          {
                            text: 'Setting Spray',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/SettingSpray/c/FS-A111?nav=/shop/Categories/Makeup/SettingSpray',
                          },
                          {
                            text: 'Makeup Remover',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupRemover/c/FS-A115?nav=/shop/Categories/Makeup/MakeupRemover',
                          },
                        ],
                        callToAction: {
                          text: 'Face',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Face/c/FS-A100?nav=/shop/Categories/Makeup/Face',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7JlxPT1EQAk1nYbdRIVn1M',
                    id: '7JlxPT1EQAk1nYbdRIVn1M',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Eyes'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'pFQpS4O3hGhd3xUPpM4KN',
                        id: 'pFQpS4O3hGhd3xUPpM4KN',
                        l4Data: [
                          {
                            text: 'Mascara',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Mascara/c/FS-A204?nav=/shop/Categories/Makeup/Mascara',
                          },
                          {
                            text: 'Eyeshadow',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Eyeshadow/c/FS-A203?nav=/shop/Categories/Makeup/Eyeshadow',
                          },
                          {
                            text: 'Eyeliner',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Eyeliner/c/FS-A202?nav=/shop/Categories/Makeup/Eyeliner',
                          },
                          {
                            text: 'Eye Primer',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/EyePrimer/c/FS-A205?nav=/shop/Categories/Makeup/EyePrimer',
                          },
                          {
                            text: 'Eye Palettes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/EyePalettes/c/FS-A206?nav=/shop/Categories/Makeup/EyePalettes',
                          },
                          {
                            text: 'Brows',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Brows/c/FS-A201?nav=/shop/Categories/Makeup/Brows',
                          },
                          {
                            text: 'Brow & Lash Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BrowAndLashTools/c/FS-T103?nav=/shop/Categories/ToolsAndAccessories/BrowAndLashTools',
                          },
                          {
                            text: 'Brow & Lash Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/BrowLashTreatments/c/FS-A208?nav=/shop/Categories/Makeup/BrowLashTreatments',
                          },
                          {
                            text: 'False Eyelashes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/FalseEyelashes/c/FS-A209?nav=/shop/Categories/Makeup/FalseEyelashes',
                          },
                        ],
                        callToAction: {
                          text: 'Eyes',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Makeup/Eyes/c/FS-A200?nav=%252Fshop%252FCategories%252FMakeup%252FEyes&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Makeup Palettes'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4D8NcoBmWqktgEpWcT6sii',
                        id: '4D8NcoBmWqktgEpWcT6sii',
                        l4Data: [
                          {
                            text: 'Eye Palettes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/EyePalettes/c/FS-A206?nav=/shop/Categories/Makeup/EyePalettes',
                          },
                          {
                            text: 'Face & Cheek Palettes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/FaceAndCheekPalettes/c/FS-A114?nav=/shop/Categories/Makeup/FaceAndCheekPalettes',
                          },
                        ],
                        callToAction: {
                          text: 'Makeup Palettes',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupPalettes/c/FS-A600?nav=/shop/Categories/Makeup/MakeupPalettes',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '299zszPoW7QvCPqwU1fUUZ',
                    id: '299zszPoW7QvCPqwU1fUUZ',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Lips'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3iqvWOn92ZadggCzalGser',
                        id: '3iqvWOn92ZadggCzalGser',
                        l4Data: [
                          {
                            text: 'Lipstick',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Lipstick/c/FS-A301?nav=/shop/Categories/Makeup/Lipstick',
                          },
                          {
                            text: 'Lip Gloss',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/LipGloss/c/FS-A302?nav=/shop/Categories/Makeup/LipGloss',
                          },
                          {
                            text: 'Lip Stain',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/LipStain/c/FS-A305?nav=/shop/Categories/Makeup/LipStain',
                          },
                          {
                            text: 'Lip Plumper',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/LipPlumper/c/FS-A304?nav=/shop/Categories/Makeup/LipPlumper',
                          },
                          {
                            text: 'Lip Balm & Treatment',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/LipBalmAndTreatment/c/FS-S999?nav=/shop/Categories/SkinCare/LipBalmAndTreatment',
                          },
                          {
                            text: 'Lip Liner',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/LipLiner/c/FS-A303?nav=/shop/Categories/Makeup/LipLiner',
                          },
                        ],
                        callToAction: {
                          text: 'Lips',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Lips/c/FS-A300?nav=/shop/Categories/Makeup/Lips',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Nails'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5XFsaHftJYoPOPwW6IOJlY',
                        id: '5XFsaHftJYoPOPwW6IOJlY',
                        l4Data: [
                          {
                            text: 'Nail Colour',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/NailColour/c/FS-D300?nav=/shop/Categories/Makeup/NailColour',
                          },
                          {
                            text: 'Top & Base Coats',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/TopAndBaseCoats/c/FS-D400?nav=/shop/Categories/Makeup/TopAndBaseCoats',
                          },
                          {
                            text: 'False Nails',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/FalseNails/c/FS-D450?nav=/shop/Categories/Makeup/FalseNails',
                          },
                          {
                            text: 'Nail Care & Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/NailCareAndTools/c/FS-T106?nav=/shop/Categories/ToolsAndAccessories/NailCareAndTools',
                          },
                        ],
                        callToAction: {
                          text: 'Nails',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Nails/c/FS-Nails?nav=/shop/Categories/Makeup/Nails',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3HAXh2gzdSI9QSIlf73sHX',
                    id: '3HAXh2gzdSI9QSIlf73sHX',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Makeup Brushes & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2ttbuKHWuvymT8TzInaR1D',
                        id: '2ttbuKHWuvymT8TzInaR1D',
                        l4Data: [
                          {
                            text: 'Makeup Brushes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupBrushes/c/FS-T101?nav=/shop/Categories/Makeup/MakeupBrushes',
                          },
                          {
                            text: 'Sponges',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Sponges/c/FS-T102?nav=/shop/Categories/Makeup/Sponges',
                          },
                          {
                            text: 'Brow & Lash Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BrowAndLashTools/c/FS-T103?nav=/shop/Categories/ToolsAndAccessories/BrowAndLashTools',
                          },
                          {
                            text: 'Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Tools/c/FS-T104?nav=/shop/Categories/Makeup/Tools',
                          },
                          {
                            text: 'Makeup Bags & Cases',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/MakeupBagsAndCases/c/FS-T105?nav=/shop/Categories/ToolsAndAccessories/MakeupBagsAndCases',
                          },
                        ],
                        callToAction: {
                          text: 'Makeup Brushes & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupBrushesAndAccessories/c/FS-T100?nav=/shop/Categories/Makeup/MakeupBrushesAndAccessories',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Makeup Refills'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4kLrxgUpnzPiFOGzMVS1NS',
                        id: '4kLrxgUpnzPiFOGzMVS1NS',
                        l4Data: [],
                        callToAction: {
                          text: 'Makeup Refills',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Makeup-Refills/c/FS-A700',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Makeup', 'Makeup Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5L1OiJv0zU9VDulBFoBTKA',
                        id: '5L1OiJv0zU9VDulBFoBTKA',
                        l4Data: [],
                        callToAction: {
                          text: 'Makeup Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupGiftsAndSets/c/FS-G100?nav=/shop/Categories/Makeup/MakeupGiftsAndSets',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '2tpXTPy8vNhzWUsLZ0Us4x',
                    id: '2tpXTPy8vNhzWUsLZ0Us4x',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/feature-tile/wk05/wk13_featurecollecctiontile_thoughtfulchoices_v1_enfr.png',
                      },
                      callToAction: {
                        text: 'Thoughtful Choices',
                        url: 'https://shop.shoppersdrugmart.ca/c/SDM-TC?sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue&nav=/shop/Categories/Beauty/Makeup/FeaturedCollections/TC',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Beauty', 'Fragrance'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '6tMG5r8elX65GJtDnn3e5p',
                id: '6tMG5r8elX65GJtDnn3e5p',
                callToAction: {
                  text: 'Fragrance',
                  url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/c/FS-Fragrance?nav=/shop/Categories/Fragrance',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'kA7apahcD8iCVzYuiORoE',
                    id: 'kA7apahcD8iCVzYuiORoE',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Shop All Fragrance'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2CDEupfjB1t6BpfEbxRgp',
                        id: '2CDEupfjB1t6BpfEbxRgp',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Fragrance',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/fragrance/c/FS-Fragrance?nav=%2Fshop%2FCategories%2FFragrance&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'whLZ1JAyS5fGz5bE9YmqN',
                        id: 'whLZ1JAyS5fGz5bE9YmqN',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-Fragrance&nav=%2Fshop%2FFragrance%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5SoiaZZShfexd6OKEWmltJ',
                        id: '5SoiaZZShfexd6OKEWmltJ',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/best-sellers/fragrance-best-sellers/c/BeautyFragranceBestSellers?nav=%2Fshop%2FCategories%2FFragrance%2FBestSellers&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'QtEgyyaGimV2VwsNkWV6h',
                    id: 'QtEgyyaGimV2VwsNkWV6h',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Fragrance For Her'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3JX60uM9xPKRvaHZD491G9',
                        id: '3JX60uM9xPKRvaHZD491G9',
                        l4Data: [
                          {
                            text: 'Body Sprays & Fragrance Mists',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/BodySpraysAndFragranceMists/c/FS-F102?nav=/shop/Categories/Fragrance/BodySpraysAndFragranceMists',
                          },
                          {
                            text: 'Celebrity Fragrances For Her',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/CelebrityFragrancesforher/c/FS-F103?nav=/shop/Categories/Fragrance/CelebrityFragrancesforher',
                          },
                          {
                            text: 'Perfume',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/Perfume/c/FS-F101?nav=/shop/Categories/Fragrance/Perfume',
                          },
                          {
                            text: 'Fragrance Bath & Body For Her',
                            url: 'https://shop.shoppersdrugmart.ca/c/FS-F104?nav=beauty/fragrance/fragranceforher/fragrancebathandbody',
                          },
                        ],
                        callToAction: {
                          text: 'Fragrance For Her',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/FragranceforHer/c/FS-F100?nav=/shop/Categories/Fragrance/FragranceforHer',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '45KKEMy4ohlQehOYhHpOBZ',
                    id: '45KKEMy4ohlQehOYhHpOBZ',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Fragrance for Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7pGBiRarU9wPX0cG0eVJXL',
                        id: '7pGBiRarU9wPX0cG0eVJXL',
                        l4Data: [
                          {
                            text: 'Cologne',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/Cologne/c/FS-F201?nav=/shop/Categories/Fragrance/Cologne',
                          },
                          {
                            text: 'Body Sprays & Deodorant',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Body-Sprays-%2526-Deodorant/c/FS-F202?nav=%252Fshop%252FCategories%252FMen%252FBodySpraysAndDeodorant&sort=trending&page=0',
                          },
                          {
                            text: 'Celebrity Fragrances For Him',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/CelebrityFragrancesforhim/c/FS-F203?nav=/shop/Categories/Fragrance/CelebrityFragrancesforhim',
                          },
                          {
                            text: 'Fragrance Bath & Body For Him',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Fragrance-Bath-%2526-Body/c/FS-F204?nav=beauty%252Fmens%252Ffragranceforhim%252Ffragrancebathandbody&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Fragrance for Him',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/FragranceforHim/c/FS-F200?nav=/shop/Categories/Fragrance/FragranceforHim',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'UVf0MgeM67JNAzCAqCvo1',
                    id: 'UVf0MgeM67JNAzCAqCvo1',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Home Scents & Candles'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '64zSO1IJEnXnUG5YghKV6o',
                        id: '64zSO1IJEnXnUG5YghKV6o',
                        l4Data: [],
                        callToAction: {
                          text: 'Home Scents & Candles',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/HomeScentsAndCandles/c/FS-F300?nav=/shop/Categories/Fragrance/HomeScentsAndCandles',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Rollerballs & Travel Size'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7kkWExIiMegp6L8g9GLf9Q',
                        id: '7kkWExIiMegp6L8g9GLf9Q',
                        l4Data: [],
                        callToAction: {
                          text: 'Rollerballs & Travel Size',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/RollerballsAndTravelSize/c/FS-F500?nav=/shop/Categories/Fragrance/RollerballsAndTravelSize',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Fragrance Refills'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2q8bfa1YKNCnVsXGv6Q2Es',
                        id: '2q8bfa1YKNCnVsXGv6Q2Es',
                        l4Data: [],
                        callToAction: {
                          text: 'Fragrance Refills',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-Refills/c/FS-F600',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Fragrance', 'Fragrance Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3s8RdpWWD7HPZ3wW3dv0j7',
                        id: '3s8RdpWWD7HPZ3wW3dv0j7',
                        l4Data: [],
                        callToAction: {
                          text: 'Fragrance Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/FragranceGiftsAndSets/c/FS-G300?nav=/shop/Categories/Fragrance/FragranceGiftsAndSets',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7dRhwTculPLGFRbE5oTosK',
                    id: '7dRhwTculPLGFRbE5oTosK',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk41_featurecollecctiontile_fragrance_b1_enfr.png',
                      },
                      callToAction: {
                        text: 'Fragrance Gifts',
                        url: 'https://shop.shoppersdrugmart.ca/Shop/Explore-Holidays/Fragrances/c/FragrancesHoliday?sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Beauty', 'Hair Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4SHR6mPUZfQF4gcgcUcrKA',
                id: '4SHR6mPUZfQF4gcgcUcrKA',
                callToAction: {
                  text: 'Hair Care',
                  url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/c/FS-HairCare?nav=/shop/Categories/HairCare',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '14Tc0AsBEUQiVuPz3aZ04F',
                    id: '14Tc0AsBEUQiVuPz3aZ04F',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Shop All Hair Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2PzJB8DlSJapLVPMy4PftI',
                        id: '2PzJB8DlSJapLVPMy4PftI',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Hair Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%2Fshop%2FCategories%2FHairCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1S7PLvNAOohPDqVckXuYDV',
                        id: '1S7PLvNAOohPDqVckXuYDV',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-HairCare&nav=%2Fshop%2FHairCare%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'O5XAQPbeTUBGLmPUS1hcQ',
                        id: 'O5XAQPbeTUBGLmPUS1hcQ',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/best-sellers/hair-care-best-sellers/c/BeautyHairBestSellers?nav=%2Fshop%2FCategories%2FHairCare%2FBestSellers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Shampoos & Conditioners'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1lJQf17k8Z4mBdb166ymbp',
                        id: '1lJQf17k8Z4mBdb166ymbp',
                        l4Data: [
                          {
                            text: 'Shampoo',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/Shampoo/c/FS-H101?nav=/shop/Categories/HairCare/Shampoo',
                          },
                          {
                            text: 'Conditioner',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/Conditioner/c/FS-H102?nav=/shop/Categories/HairCare/Conditioner',
                          },
                          {
                            text: 'Dry Shampoo',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/DryShampoo/c/FS-H103?nav=/shop/Categories/HairCare/DryShampoo',
                          },
                        ],
                        callToAction: {
                          text: 'Shampoos & Conditioners',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/ShampoosAndConditioners/c/FS-H100?nav=/shop/Categories/HairCare/ShampoosAndConditioners',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Hair Type'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '39jcwjmJi6f32bToszdhKK',
                        id: '39jcwjmJi6f32bToszdhKK',
                        l4Data: [
                          {
                            text: 'Thin & Fine',
                            url: 'https://shop.shoppersdrugmart.ca/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairType&sort=trending&page=0&q=%253Atrending%253AhairType%253ADAMAGED',
                          },
                          {
                            text: 'Straight',
                            url: 'https://shop.shoppersdrugmart.ca/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairType&sort=trending&page=0&q=%253Atrending%253AhairType%253ANORMAL',
                          },
                          {
                            text: 'Wavy & Curly',
                            url: 'https://shop.shoppersdrugmart.ca/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairType&sort=trending&page=0&q=%253Atrending%253AhairType%253ACURLY',
                          },
                          {
                            text: 'Coarse & Thick',
                            url: 'https://shop.shoppersdrugmart.ca/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairType&sort=trending&page=0&q=%253Atrending%253AhairType%253ATHICK',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Type',
                          url: 'https://shop.shoppersdrugmart.ca/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairType&sort=trending&page=0&q=%253Atrending',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7kzWyDq44HC6R2AVu52UeN',
                    id: '7kzWyDq44HC6R2AVu52UeN',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Hair Styling Tools'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4iSuHp1U1l1NQGOzXQVCna',
                        id: '4iSuHp1U1l1NQGOzXQVCna',
                        l4Data: [
                          {
                            text: 'Blow Dryers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BlowDryers/c/FS-T401?nav=/shop/Categories/ToolsAndAccessories/BlowDryers',
                          },
                          {
                            text: 'Straighteners & Flat Irons',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/StraightenersAndFlatIrons/c/FS-T402?nav=/shop/Categories/HairCare/StraightenersAndFlatIrons',
                          },
                          {
                            text: 'Curling Irons',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/CurlingIrons/c/FS-T403?nav=/shop/Categories/ToolsAndAccessories/CurlingIrons',
                          },
                          {
                            text: 'Brushes & Combs',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BrushesAndCombs/c/FS-T404?nav=/shop/Categories/ToolsAndAccessories/BrushesAndCombs',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Styling Tools',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairStylingTools/c/FS-T400?nav=/shop/Categories/HairCare/HairStylingTools',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Hair Concern'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7uP3OMjcs9JNIOYToi4EeF',
                        id: '7uP3OMjcs9JNIOYToi4EeF',
                        l4Data: [
                          {
                            text: 'Shine',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairConcern&sort=trending&page=0&q=%3Atrending%3Aconcern%3ASHINE%3AshowInStock%3Atrue',
                          },
                          {
                            text: 'Damaged',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairConcern&sort=trending&page=0&q=%3Atrending%3Aconcern%3ADAMAGED%3AshowInStock%3Atrue',
                          },
                          {
                            text: 'Frizz Control',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairConcern&sort=trending&page=0&q=%3Atrending%3Aconcern%3AFRIZZ_CONTROL%3AshowInStock%3Atrue',
                          },
                          {
                            text: 'Dryness & Dandruff',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairConcern&sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue%3Aconcern%3ADRYNESS%3Aconcern%3ADANDRUFF_CONTROL',
                          },
                          {
                            text: 'Volumizing',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%252Fshop%252FCategories%252FHairCare%252FHairConcern&sort=trending&page=0&q=%3Atrending%3Aconcern%3AVOLUMIZING%3AshowInStock%3Atrue',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Concern',
                          url: 'https://shop.shoppersdrugmart.ca/c/FS-HairCare?sort=trending&page=0&q=%253Atrending',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2UJ4W3tY4JB1F5S4e1xw9k',
                    id: '2UJ4W3tY4JB1F5S4e1xw9k',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Styling Products & Treatments'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6jHNzK4FhLLrEJAdo72KRp',
                        id: '6jHNzK4FhLLrEJAdo72KRp',
                        l4Data: [
                          {
                            text: 'Hair Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairTreatments/c/FS-H301?nav=/shop/Categories/HairCare/HairTreatments',
                          },
                          {
                            text: 'Hair Masks',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairMasks/c/FS-H302?nav=/shop/Categories/HairCare/HairMasks',
                          },
                          {
                            text: 'Hair Spray',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairSpray/c/FS-H303?nav=/shop/Categories/HairCare/HairSpray',
                          },
                          {
                            text: 'Hair Mousse',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairMousse/c/FS-H304?nav=/shop/Categories/HairCare/HairMousse',
                          },
                          {
                            text: 'Hair Wax & Gel',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairWaxAndGel/c/FS-H305?nav=/shop/Categories/HairCare/HairWaxAndGel',
                          },
                          {
                            text: 'Hair Loss & Regrowth Supplements',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairLossAndRegrowthSupplements/c/FS-H306?nav=/shop/Categories/HairCare/HairLossAndRegrowthSupplements',
                          },
                        ],
                        callToAction: {
                          text: 'Styling Products & Treatments',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/StylingProductsAndTreatments/c/FS-H300?nav=/shop/Categories/HairCare/StylingProductsAndTreatments',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6JoOxD1jAy75PffQn0rrvz',
                    id: '6JoOxD1jAy75PffQn0rrvz',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Hair Colour'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'MWpfQxbpvlZy45PRTuHYx',
                        id: 'MWpfQxbpvlZy45PRTuHYx',
                        l4Data: [
                          {
                            text: 'Permanent Hair Colour',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/PermanentHairColour/c/FS-H401?nav=/shop/Categories/HairCare/PermanentHairColour',
                          },
                          {
                            text: 'Root Touch-Up',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/RootTouchUp/c/FS-H402?nav=/shop/Categories/HairCare/RootTouchUp',
                          },
                          {
                            text: 'Temporary Hair Colour',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/TemporaryHairColour/c/FS-H403?nav=/shop/Categories/HairCare/TemporaryHairColour',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Colour',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairColour/c/FS-H400?nav=/shop/Categories/HairCare/HairColour',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Hair Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4gL2nr0AKCWecPjl2gybAl',
                        id: '4gL2nr0AKCWecPjl2gybAl',
                        l4Data: [
                          {
                            text: 'Clips & Pins',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/ClipsAndPins/c/FS-T501?nav=/shop/Categories/HairCare/HairAccessories/ClipsAndPins',
                          },
                          {
                            text: 'Headbands & Head Wraps',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HeadbandsAndHeadWraps/c/FS-T502?nav=/shop/Categories/HairCare/HeadbandsAndHeadWraps',
                          },
                          {
                            text: 'Elastics',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/Elastics/c/FS-T503?nav=/shop/Categories/ToolsAndAccessories/Elastics',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/Categories/HairCare/HairAccessories',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Hair Care', 'Hair Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2wxeYUzDkQfmPY6YkoTCBQ',
                        id: '2wxeYUzDkQfmPY6YkoTCBQ',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairGiftsAndSets/c/FS-G400?nav=/shop/Categories/HairCare/HairGiftsAndSets',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '1MJHHJHYxBpKkp8KkWUxti',
                    id: '1MJHHJHYxBpKkp8KkWUxti',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk13_featurecollecctiontile_thoughtfulchoices_v1_enfr.png',
                      },
                      callToAction: {
                        text: 'Thoughtful Choices',
                        url: 'https://shop.shoppersdrugmart.ca/shop/thoughtful-choices/c/SDM-TC?sort=trending&page=0&q=%3Atrending%3AshowInStock%3Atrue%3AhairType%3AHAIR%3AhairType%3ACOLOR_TREATED%3AhairType%3ADAMAGED%3AhairType%3AVOLUMIZING%3AhairType%3ACURLY&nav=%2Fshop%2FCategories%2FBeauty%2FHairCare%2FFeaturedCollections%2FTC',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Beauty', 'Tools & Accessories'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7c25HpStHMNDFSV4btXMnD',
                id: '7c25HpStHMNDFSV4btXMnD',
                callToAction: {
                  text: 'Tools & Accessories',
                  url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/c/FS-ToolsAccess?nav=/shop/Categories/ToolsAndAccessories',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2EmTPHtwivuHyBeYZXDavg',
                    id: '2EmTPHtwivuHyBeYZXDavg',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'Shop All Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5RsSRnyNVmocHqU8Aay02q',
                        id: '5RsSRnyNVmocHqU8Aay02q',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Tools & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/tools-and-accessories/c/FS-ToolsAccess?nav=%2Fshop%2FCategories%2FToolsAndAccessories&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5WuVdyPBGGYmjD1uOjF7Jg',
                        id: '5WuVdyPBGGYmjD1uOjF7Jg',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-ToolsAccess&nav=%2Fshop%2FToolsAccess%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1BQrMrX77oakKwL6tPKYuA',
                        id: '1BQrMrX77oakKwL6tPKYuA',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/tools-and-accessories-best-sellers/c/BeautyToolsAndAccessoriesBestSellers?%3Fnav=%2Fshop%2FCategories%2FToolsAndAccessories%2FBestSellers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'Makeup Brushes & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2ttbuKHWuvymT8TzInaR1D',
                        id: '2ttbuKHWuvymT8TzInaR1D',
                        l4Data: [
                          {
                            text: 'Makeup Brushes',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupBrushes/c/FS-T101?nav=/shop/Categories/Makeup/MakeupBrushes',
                          },
                          {
                            text: 'Sponges',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Sponges/c/FS-T102?nav=/shop/Categories/Makeup/Sponges',
                          },
                          {
                            text: 'Brow & Lash Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BrowAndLashTools/c/FS-T103?nav=/shop/Categories/ToolsAndAccessories/BrowAndLashTools',
                          },
                          {
                            text: 'Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/Tools/c/FS-T104?nav=/shop/Categories/Makeup/Tools',
                          },
                          {
                            text: 'Makeup Bags & Cases',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/MakeupBagsAndCases/c/FS-T105?nav=/shop/Categories/ToolsAndAccessories/MakeupBagsAndCases',
                          },
                        ],
                        callToAction: {
                          text: 'Makeup Brushes & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupBrushesAndAccessories/c/FS-T100?nav=/shop/Categories/Makeup/MakeupBrushesAndAccessories',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3whneayXWMe8YSx2Ba6ZWF',
                    id: '3whneayXWMe8YSx2Ba6ZWF',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'Skin Care Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3YZDV8ZH19TT0kRp8T8zY7',
                        id: '3YZDV8ZH19TT0kRp8T8zY7',
                        l4Data: [
                          {
                            text: 'Facial Cleansing Tools',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialCleansingTools/c/FS-S206?nav=shop/Categories/SkinCare/FacialCleansingTools',
                          },
                          {
                            text: 'Facial Wipes & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/FacialWipesAndAccessories/c/FS-T202?nav=/shop/Categories/SkinCare/FacialWipesAndAccessories',
                          },
                          {
                            text: 'Skin Care Treatment Devices',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/SkinCareTreatmentDevices/c/FS-T203?nav=/shop/Categories/ToolsAndAccessories/SkinCareTreatmentDevices',
                          },
                        ],
                        callToAction: {
                          text: 'Skin Care Tools & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/SkinCareToolsAndAccessories/c/FS-T200?nav=/shop/Categories/SkinCare/SkinCareToolsAndAccessories',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7IAFV99XIfZB5Xh2aDBOMY',
                    id: '7IAFV99XIfZB5Xh2aDBOMY',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'Hair Styling Tools'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4iSuHp1U1l1NQGOzXQVCna',
                        id: '4iSuHp1U1l1NQGOzXQVCna',
                        l4Data: [
                          {
                            text: 'Blow Dryers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BlowDryers/c/FS-T401?nav=/shop/Categories/ToolsAndAccessories/BlowDryers',
                          },
                          {
                            text: 'Straighteners & Flat Irons',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/StraightenersAndFlatIrons/c/FS-T402?nav=/shop/Categories/HairCare/StraightenersAndFlatIrons',
                          },
                          {
                            text: 'Curling Irons',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/CurlingIrons/c/FS-T403?nav=/shop/Categories/ToolsAndAccessories/CurlingIrons',
                          },
                          {
                            text: 'Brushes & Combs',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/BrushesAndCombs/c/FS-T404?nav=/shop/Categories/ToolsAndAccessories/BrushesAndCombs',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Styling Tools',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairStylingTools/c/FS-T400?nav=/shop/Categories/HairCare/HairStylingTools',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5lL65AnWEREt3Zw1754ajO',
                    id: '5lL65AnWEREt3Zw1754ajO',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Tools & Accessories', 'Hair Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4gL2nr0AKCWecPjl2gybAl',
                        id: '4gL2nr0AKCWecPjl2gybAl',
                        l4Data: [
                          {
                            text: 'Clips & Pins',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/ClipsAndPins/c/FS-T501?nav=/shop/Categories/HairCare/HairAccessories/ClipsAndPins',
                          },
                          {
                            text: 'Headbands & Head Wraps',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HeadbandsAndHeadWraps/c/FS-T502?nav=/shop/Categories/HairCare/HeadbandsAndHeadWraps',
                          },
                          {
                            text: 'Elastics',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/Elastics/c/FS-T503?nav=/shop/Categories/ToolsAndAccessories/Elastics',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/Categories/HairCare/HairAccessories',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '53oNJjYuaPbNTXnW58GLxv',
                    id: '53oNJjYuaPbNTXnW58GLxv',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/menufeaturedtile_salontoolsaccessories_b1_en.jpg',
                      },
                      callToAction: {
                        text: 'Salon-Level Hair Tools ',
                        url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Tools-%26-Accessories/Hair-Styling-Tools/c/FS-T400?nav=%2Fshop%2Fbeauty%2Ftoolsandaccessories%2Ffeaturedcollection%2Fsalonlevelhairtools&sort=trending&page=0',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '6OoVWVBeXdA8fQCx1Df7gt',
                    id: '6OoVWVBeXdA8fQCx1Df7gt',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk16_featurecollectiontile_quo-tools-accessories_enfr.png',
                      },
                      callToAction: {
                        text: 'Tools & Accessories by Quo Beauty',
                        url: 'https://shop.shoppersdrugmart.ca/Shop/Quo-Beauty/Categories/Beauty/Tools-%26-Accessories/c/QUO%2CBEAUTY-FS-ToolsAccess?nav=shop%2Fbeauty%2Ftoolsandaccessories%2Ffeaturedcollections%2Ftoolsandaccesssoriesbyquobeauty&sort=trending&page=0',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Beauty', "Men's"],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '17eGUp3xD2PGP5gY6MrZGS',
                id: '17eGUp3xD2PGP5gY6MrZGS',
                callToAction: {
                  text: "Men's",
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/c/FS-Mens?nav=%252Fshop%252FCategories%252FMen&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2MUHFrK96CQfd2ogqDLnJm',
                    id: '2MUHFrK96CQfd2ogqDLnJm',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', "Men's", "Shop All Men's"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1ajC9PV4rBHUndtwPu5VJm',
                        id: '1ajC9PV4rBHUndtwPu5VJm',
                        l4Data: [],
                        callToAction: {
                          text: "Shop All Men's",
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/mens/c/FS-Mens?nav=%2Fshop%2FCategories%2FMen&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', "Men's", 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'epUASxLKCebIyvu7SRe7S',
                        id: 'epUASxLKCebIyvu7SRe7S',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-Mens&nav=%2Fshop%2FMens%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', "Men's", 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6QzWSIIgDPLEF5TxrkJmMW',
                        id: '6QzWSIIgDPLEF5TxrkJmMW',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/best-sellers/mens-best-sellers/c/BeautyMensBestSellers?nav=%2Fshop%2FCategories%2FMens%2FBestSellers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', "Men's", 'Shaving for Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3dWf4gNtH92ufxaoVIxxoL',
                        id: '3dWf4gNtH92ufxaoVIxxoL',
                        l4Data: [],
                        callToAction: {
                          text: 'Shaving for Him',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/shaving-for-him/c/FS-M500?nav=%252Fshop%252FCategories%252FMen%252FShavingforHim&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '52ukLM7RQEc8ddDypd96VX',
                    id: '52ukLM7RQEc8ddDypd96VX',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', "Men's", 'Skin Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4sNrSzP5JOFpLGao7cTLiI',
                        id: '4sNrSzP5JOFpLGao7cTLiI',
                        l4Data: [
                          {
                            text: 'Cleansers & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/cleansers-and-accessories/c/FS-M102?nav=shop%2FCategories%2FSkinCare%2FFaceSunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'Lip Balm',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/lip-balm/c/FS-M104?nav=%2Fshop%2FCategories%2FSkinCare%2FBodySunscreen&sort=trending&page=0&lang=en',
                          },
                          {
                            text: 'Moisturizers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/moisturizers/c/FS-M101?nav=shop%2FCategories%2FSkinCare%2FAfterSun&sort=trending&page=0',
                          },
                          {
                            text: 'Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/treatments/c/FS-M103?nav=shop%2FCategories%2FSkinCare%2FAfterSun&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Skin Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/c/FS-M100?nav=/shop/Categories/SkinCare/SunCare',
                        },
                      },
                      {
                        hierarchy: ['Beauty', "Men's", 'Fragrance For Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6WrelvBZuWTxXuaw7XZMJg',
                        id: '6WrelvBZuWTxXuaw7XZMJg',
                        l4Data: [
                          {
                            text: 'Cologne',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/Cologne/c/FS-F201?nav=/shop/Categories/Fragrance/Cologne',
                          },
                          {
                            text: 'Body Sprays & Deodorant',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Body-Sprays-%2526-Deodorant/c/FS-F202?nav=%252Fshop%252FCategories%252FMen%252FBodySpraysAndDeodorant&sort=trending&page=0',
                          },
                          {
                            text: 'Celebrity Fragrances',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Celebrity-Fragrances-for-him/c/FS-F203',
                          },
                          {
                            text: 'Fragrance Bath & Body For Him',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Fragrance-Bath-%2526-Body/c/FS-F204?nav=beauty%252Fmens%252Ffragranceforhim%252Ffragrancebathandbody&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Fragrance For Him',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/c/FS-F200?nav=%252Fshop%252FCategories%252FMen%252FFragranceforHim&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2yGljuXD1ItugXPGfzb2Og',
                    id: '2yGljuXD1ItugXPGfzb2Og',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', "Men's", 'Shaving For Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6QZ04gQqdI5U5SGuXe8QWK',
                        id: '6QZ04gQqdI5U5SGuXe8QWK',
                        l4Data: [
                          {
                            text: 'Shaving Creams & Gels',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Shaving-Creams-%2526-Gels/c/FS-M501?nav=%252Fshop%252FCategories%252FMen%252FShavingCreamsAndGels&sort=trending&page=0',
                          },
                          {
                            text: 'After Shave',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/After-Shave/c/FS-M502?nav=%252Fshop%252FCategories%252FMen%252FAfterShave&sort=trending&page=0',
                          },
                          {
                            text: 'Electric Shavers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Electric-Shavers/c/FS-M503?nav=%252Fshop%252FCategories%252FMen%252FElectricShavers&sort=trending&page=0',
                          },
                          {
                            text: 'Disposable Razors & Blades',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Disposable-Razors-%2526-Blades/c/FS-M504?nav=%252Fshop%252FCategories%252FMen%252FDisposableRazorAndBlades&sort=trending&page=0',
                          },
                          {
                            text: 'Grooming Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Grooming-Accessories/c/FS-M505?nav=%252Fshop%252FCategories%252FMen%252FGroomingAccessories&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Shaving For Him',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/c/FS-M500?nav=%252Fshop%252FCategories%252FMen%252FShavingforHim&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', "Men's", 'Hair For Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '59TxdG2fTnwPQUx6Kyd4D1',
                        id: '59TxdG2fTnwPQUx6Kyd4D1',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair For Him',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/hair-for-him/c/FS-M400?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6eRsOzujgf1WSyxmiyMubP',
                    id: '6eRsOzujgf1WSyxmiyMubP',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', "Men's", "Men's Gifts & Sets"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6cpu472gsLEVBL6Ml0SdAf',
                        id: '6cpu472gsLEVBL6Ml0SdAf',
                        l4Data: [],
                        callToAction: {
                          text: "Men's Gifts & Sets",
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Beauty-Gifts-%2526-Sets/Men%2527s-Gifts-%2526-Sets/c/FS-G600?nav=%252Fshop%252FCategories%252FMen%252FMensGiftsAndSets&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Beauty', 'Fashion & Accessories'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2WfSpWdCNlvbwq8rQHHgP',
                id: '2WfSpWdCNlvbwq8rQHHgP',
                callToAction: {
                  text: 'Fashion & Accessories',
                  url: 'https://shop.shoppersdrugmart.ca/shop/fashion-and-accessories/c/FS-FashAccess?nav=/shop/fashionaccessories',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '41dL8T5HKl7twFxKb0JbDT',
                    id: '41dL8T5HKl7twFxKb0JbDT',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Fashion & Accessories', 'Shop All Fashion & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'Ll8AoeVxTCafLY9SUIDcg',
                        id: 'Ll8AoeVxTCafLY9SUIDcg',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Fashion & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/fashion-and-accessories/c/FS-FashAccess??nav=/shop/fashionaccessories',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Fashion & Accessories', 'Fashion'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1BUM7gFOVkNIPeVet05mb9',
                        id: '1BUM7gFOVkNIPeVet05mb9',
                        l4Data: [
                          {
                            text: 'Luxury Sunglasses',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/tools-and-accessories/wardrobe-accessories/luxury-sunglasses/c/FS-PC-T406?sort=trending&page=0',
                          },
                          {
                            text: 'Watches',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/fashion/watches/c/FS-PC-T407?sort=trending&page=0',
                          },
                          {
                            text: 'Jewellery',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Jewellery/c/FS-PC-T401?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FJewellery&sort=trending&page=0',
                          },
                          {
                            text: 'Socks',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Socks/c/FS-PC-T404?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FSocks&sort=trending&page=0',
                          },
                          {
                            text: 'Beanies',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/fashion/beanies/c/FS-PC-T408?nav=beanies',
                          },
                          {
                            text: 'Hosiery & Tights',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Hosiery-%2526-Tights/c/FS-PC-T403?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FHosieryAndTights&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Fashion',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/fashion/c/FS-PC-T700?nav=/shop/fashion',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3ZZoKIeXfiklr5VtlS7hpS',
                    id: '3ZZoKIeXfiklr5VtlS7hpS',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Fashion & Accessories', 'Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7qWRIuCNORFVA3KWp2w68n',
                        id: '7qWRIuCNORFVA3KWp2w68n',
                        l4Data: [
                          {
                            text: 'Bra Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Bra-Accessories/c/FS-PC-T402?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FBraAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Winter Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Winter-Accessories/c/FS-PC-T405?nav=shop%252FPersonalCare%252FToolsAndAccessories%252FWardrobeAccessories%252FWinterAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Umbrella',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Weather-Accessories/c/FS-PC-T600?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FWeatherAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Hair Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/Categories/HairCare/HairAccessories',
                          },
                        ],
                        callToAction: {
                          text: 'Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/accessories/c/FS-PC-T800?nav=accessories',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Beauty', 'Gifts & Sets'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3UeOb3ROLLh39gpwZQMeT2',
                id: '3UeOb3ROLLh39gpwZQMeT2',
                callToAction: {
                  text: 'Gifts & Sets',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Beauty-Gifts-%26-Sets/c/FS-GiftSets?nav=%2Fshop%2FCategories%2FGiftsAndSets&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'ed245WRrrmQe3rDqR8E5I',
                    id: 'ed245WRrrmQe3rDqR8E5I',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Shop All Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7BQqsCrT3wYXFCw0judo6t',
                        id: '7BQqsCrT3wYXFCw0judo6t',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Beauty-Gifts-%26-Sets/c/FS-GiftSets?nav=%2Fshop%2FCategories%2FGiftsAndSets&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7F47vovGsiY8W33tUErUg2',
                        id: '7F47vovGsiY8W33tUErUg2',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Collections/New-Arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-GiftSets&nav=%2Fshop%2FGiftSets%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Makeup Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5L1OiJv0zU9VDulBFoBTKA',
                        id: '5L1OiJv0zU9VDulBFoBTKA',
                        l4Data: [],
                        callToAction: {
                          text: 'Makeup Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Makeup/MakeupGiftsAndSets/c/FS-G100?nav=/shop/Categories/Makeup/MakeupGiftsAndSets',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Skin Care Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5YBpm9PcAdnQEHWwP3bPHc',
                        id: '5YBpm9PcAdnQEHWwP3bPHc',
                        l4Data: [],
                        callToAction: {
                          text: 'Skin Care Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/SkinCare/SkinCareGiftsAndSets/c/FS-G200?nav=/shop/Categories/SkinCare/SkinCareGiftsAndSets',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Hair Care Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3TLIOKTAHdsKHnU0AoLmAK',
                        id: '3TLIOKTAHdsKHnU0AoLmAK',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair Care Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Beauty-Gifts-%2526-Sets/Hair-Care-Gifts-%2526-Sets/c/FS-G400?nav=%252Fshop%252FCategories%252FGiftsAndSets%252FHairCareGiftsAndSets&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Fragrance Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3s8RdpWWD7HPZ3wW3dv0j7',
                        id: '3s8RdpWWD7HPZ3wW3dv0j7',
                        l4Data: [],
                        callToAction: {
                          text: 'Fragrance Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/FragranceGiftsAndSets/c/FS-G300?nav=/shop/Categories/Fragrance/FragranceGiftsAndSets',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', "Men's Gifts & Sets"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6cpu472gsLEVBL6Ml0SdAf',
                        id: '6cpu472gsLEVBL6Ml0SdAf',
                        l4Data: [],
                        callToAction: {
                          text: "Men's Gifts & Sets",
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Beauty-Gifts-%2526-Sets/Men%2527s-Gifts-%2526-Sets/c/FS-G600?nav=%252Fshop%252FCategories%252FMen%252FMensGiftsAndSets&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Candles & Home Gifts'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'AiUUD0LynvvSWZ0Od7QGw',
                        id: 'AiUUD0LynvvSWZ0Od7QGw',
                        l4Data: [],
                        callToAction: {
                          text: 'Candles & Home Gifts',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Collections/Candles-%2526-Home-Gifts/c/CandlesAndHomeGifts?nav=beauty%252Fgiftsandsets%252Fcandlesandhomegifts&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Beauty', 'Gifts & Sets', 'Digital Gift Cards'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3KfYGGNfqxLGGFHJtsmG6U',
                        id: '3KfYGGNfqxLGGFHJtsmG6U',
                        l4Data: [],
                        callToAction: {
                          text: 'Digital Gift Cards',
                          url: 'https://shop.shoppersdrugmart.ca/shop/digital-gift-cards/c/DigitalGiftCards?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Beauty', 'Shop By Brand'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '1pZ3paHXIKmZoAk1T2SvDv',
                id: '1pZ3paHXIKmZoAk1T2SvDv',
                callToAction: {
                  text: 'Shop By Brand',
                  url: 'https://shop.shoppersdrugmart.ca/all-brands?nav=shop/beauty/shopallbrand',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6ic0n9ByTf1L3WKgJ2te6g',
                    id: '6ic0n9ByTf1L3WKgJ2te6g',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Shop By Brand', 'Popular Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7ddflCHhKYhSZBeh0wlpQN',
                        id: '7ddflCHhKYhSZBeh0wlpQN',
                        l4Data: [
                          {
                            text: 'Dyson',
                            url: 'https://shop.shoppersdrugmart.ca/shop.shoppersdrugmart.ca/c/DYSON?nav=/brands/dyson',
                          },
                          {
                            text: 'Lancome',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Lanc%C3%B4me/c/LANCOME?nav=%2Fbrands%2Flancome&sort=trending&page=0',
                          },
                          {
                            text: 'r.e.m. beauty',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/R-E-M-Beauty/c/R.E.M.BEAUTY?sort=trending&page=0',
                          },
                          {
                            text: 'La Roche Posay',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/La-Roche-Posay/c/LA%2CROCHE%2CPOSAY?nav=%2Fbrands%2Flarocheposay&sort=trending&page=0',
                          },
                          {
                            text: 'Cerave',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Brands/CeraVe/c/CERAVE?nav=%2Fbrands%2Fcerave&sort=trending&page=0',
                          },
                          {
                            text: 'Quo Beauty',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Quo-Beauty/c/QUO%2CBEAUTY?nav=shop%2Fbeauty%2Fshopbybrand%2Fquobeauty&sort=trending&page=0',
                          },
                          {
                            text: 'L’Oréal Paris',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/L%E2%80%99Or%C3%A9al-Paris/c/L%27OREAL?sort=trending&page=0',
                          },
                          {
                            text: 'Olay',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Olay/c/OLAY?nav=%2Fbrands%2Folay&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Popular Brands',
                          url: 'https://shop.shoppersdrugmart.ca/all-brands?nav=shop/beauty/shopallbrand',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7iT5xMFUXBKJnMKfEXQ7vC',
                    id: '7iT5xMFUXBKJnMKfEXQ7vC',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Shop By Brand', 'Featured Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6pq9SIxFEVaU4BdwA6s93H',
                        id: '6pq9SIxFEVaU4BdwA6s93H',
                        l4Data: [
                          {
                            text: 'CHANEL',
                            url: 'https://shop.shoppersdrugmart.ca/editorials/chanel',
                          },
                          {
                            text: 'Clarins',
                            url: 'https://shop.shoppersdrugmart.ca/shop/clarins/c/CLARINS?nav=/brands/clarins',
                          },
                          {
                            text: 'Tom Ford',
                            url: 'https://shop.shoppersdrugmart.ca/shop/tom-ford/c/TOM%2CFORD?nav=shop%2Fbeauty%2Fshopbybrand%2Ftomford&sort=trending&page=0',
                          },
                          {
                            text: 'Versed',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Versed/c/VERSED?nav=%252Fbrands%252Fversed&sort=trending&page=0',
                          },
                          {
                            text: 'Dior',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Dior/c/DIOR?nav=shop%252Fbeauty%252Fshopbybrand%252FDior&sort=trending&page=0',
                          },
                          {
                            text: 'Estee Lauder',
                            url: 'https://shop.shoppersdrugmart.ca/shop/estee-lauder/c/ESTEE?nav=/brands/estee',
                          },
                          {
                            text: 'Drunk Elephant',
                            url: 'https://shop.shoppersdrugmart.ca/shop/drunk-elephant/c/DRUNK%2CELEPHANT?nav=/brands/drunkelephant',
                          },
                          {
                            text: "Kiehl's",
                            url: 'https://shop.shoppersdrugmart.ca/shop/kiehl-s/c/KIEHL%27S?nav=/brands/kiehls',
                          },
                          {
                            text: 'NARS',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Nars/c/NARS?nav=%252Fbrands%252Fnars&sort=trending&page=0',
                          },
                          {
                            text: 'Kylie By Kylie Jenner',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Kylie-by-Kylie-Jenner/c/KYLIE?nav=shop%252Fbeauty%252Fshopbybrand%252Ffeaturedbrand%252Fkyliebykyliejenner&sort=trending&page=0',
                          },
                          {
                            text: 'Nudestix',
                            url: 'https://shop.shoppersdrugmart.ca/shop/nudestix/c/NUDESTIX?nav=/brands/nudestix',
                          },
                        ],
                        callToAction: {
                          text: 'Featured Brands',
                          url: 'https://shop.shoppersdrugmart.ca',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'ksn8BSsEkprK8KGpncxHv',
                    id: 'ksn8BSsEkprK8KGpncxHv',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Shop By Brand', 'Shop All Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2Ul8VmRkFNVx0saEz0z6ua',
                        id: '2Ul8VmRkFNVx0saEz0z6ua',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Brands',
                          url: 'https://shop.shoppersdrugmart.ca/all-brands',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Beauty', 'Shop By Price'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4KvCGsyTvpUA86u2d7fwgE',
                id: '4KvCGsyTvpUA86u2d7fwgE',
                callToAction: {
                  text: 'Shop By Price',
                  url: 'https://shop.shoppersdrugmart.ca/Beauty?nav=/shop/beauty',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1KVhrsdarrHCu54nwZU6Mm',
                    id: '1KVhrsdarrHCu54nwZU6Mm',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Shop By Price', '$25 Or Less'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4aerppRkSzvIpnSvV4NjaA',
                        id: '4aerppRkSzvIpnSvV4NjaA',
                        l4Data: [
                          {
                            text: 'Makeup under $25',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/makeup/c/FS-MakeUp?nav=%2Fshop%2FCategories%2FMakeup&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25',
                          },
                          {
                            text: 'Skincare under $25',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/skin-care/c/FS-SkinCare?nav=%2Fshop%2FCategories%2FSkinCare&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25',
                          },
                          {
                            text: 'Haircare under $25',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%2Fshop%2FCategories%2FHairCare&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25',
                          },
                          {
                            text: 'Tools & Accessories under $25',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/tools-and-accessories/c/FS-ToolsAccess?nav=%2Fshop%2FCategories%2FToolsAndAccessories&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25',
                          },
                          {
                            text: "Men's Beauty under $25",
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/c/FS-Mens?nav=%2Fshop%2FCategories%2FMen&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25',
                          },
                        ],
                        callToAction: {
                          text: '$25 Or Less',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/c/FS-BB?sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4NSqIGdO1I3xdKwJB5GySb',
                    id: '4NSqIGdO1I3xdKwJB5GySb',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Shop By Price', '$50 Or Less'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7sJ68jQuZCzet2z4Ir3yrA',
                        id: '7sJ68jQuZCzet2z4Ir3yrA',
                        l4Data: [
                          {
                            text: 'Makeup under $50',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/makeup/c/FS-MakeUp?nav=%2Fshop%2FCategories%2FMakeup&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450',
                          },
                          {
                            text: 'Skincare under $50',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/skin-care/c/FS-SkinCare?nav=%2Fshop%2FCategories%2FSkinCare&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450',
                          },
                          {
                            text: 'Haircare under $50',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%2Fshop%2FCategories%2FHairCare&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450',
                          },
                          {
                            text: 'Fragrance under $50',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/fragrance/c/FS-Fragrance?nav=%2Fshop%2FCategories%2FFragrance&sort=trending&page=0&q=%3Atrending%3Aprice%3A%2425-%2450',
                          },
                          {
                            text: 'Tools & Accessories under $50',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/tools-and-accessories/c/FS-ToolsAccess?nav=%2Fshop%2FCategories%2FToolsAndAccessories&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450',
                          },
                          {
                            text: "Men's Beauty under $50",
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/c/FS-Mens?nav=%2Fshop%2FCategories%2FMen&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450',
                          },
                        ],
                        callToAction: {
                          text: '$50 Or Less',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/c/FS-BB?sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7wk4gGHfEmqtmWoY0DwFzE',
                    id: '7wk4gGHfEmqtmWoY0DwFzE',
                    l3Data: [
                      {
                        hierarchy: ['Beauty', 'Shop By Price', '$100 Or Less'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'FPoNx68Xd97clE8SMI419',
                        id: 'FPoNx68Xd97clE8SMI419',
                        l4Data: [
                          {
                            text: 'Makeup under $100',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/makeup/c/FS-MakeUp?nav=%2Fshop%2FCategories%2FMakeup&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                          },
                          {
                            text: 'Skincare under $100',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/skin-care/c/FS-SkinCare?nav=%2Fshop%2FCategories%2FSkinCare&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                          },
                          {
                            text: 'Haircare under $100',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?nav=%2Fshop%2FCategories%2FHairCare&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                          },
                          {
                            text: 'Fragrance under $100',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/fragrance/c/FS-Fragrance?nav=%2Fshop%2FCategories%2FFragrance&sort=trending&page=0&q=%3Atrending%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                          },
                          {
                            text: 'Tools & Accessories under $100',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/tools-and-accessories/c/FS-ToolsAccess?nav=%2Fshop%2FCategories%2FToolsAndAccessories&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                          },
                          {
                            text: "Men's Beauty under $100",
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/c/FS-Mens?nav=%2Fshop%2FCategories%2FMen&sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                          },
                        ],
                        callToAction: {
                          text: '$100 Or Less',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/c/FS-BB?sort=trending&page=0&q=%3Atrending%3Aprice%3Aunder25%3Aprice%3A%2425-%2450%3Aprice%3A%2450-%24100',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '6mS0xXol1H2DYMgvhtDqOX',
          id: '6mS0xXol1H2DYMgvhtDqOX',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '6mS0xXol1H2DYMgvhtDqOX',
            id: '6mS0xXol1H2DYMgvhtDqOX',
            callToAction: {
              text: 'Personal Care',
              url: 'https://shop.shoppersdrugmart.ca/Personal-care?nav=/shop/personal',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Personal Care', 'Shop All Personal Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3IoizbcXKE8gAakR1SQrgv',
                id: '3IoizbcXKE8gAakR1SQrgv',
                callToAction: {
                  text: 'Shop All Personal Care',
                  url: 'https://shop.shoppersdrugmart.ca/Personal-care?nav=/shop/personal',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Shop The Wellness Shop'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7t60FULzFPCm1QMZ4oQjrj',
                id: '7t60FULzFPCm1QMZ4oQjrj',
                callToAction: {
                  text: 'Shop The Wellness Shop',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3DaIsAnYUY9D2jxlTg0V52',
                    id: '3DaIsAnYUY9D2jxlTg0V52',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Shop The Wellness Shop', 'The Wellness Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7zt9WspnfFdBLtPoXd5DMd',
                        id: '7zt9WspnfFdBLtPoXd5DMd',
                        l4Data: [],
                        callToAction: {
                          text: 'The Wellness Shop',
                          url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shop The Wellness Shop', 'Vitamins & Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '75BXLDW11oBLbGjp7SF8Lh',
                        id: '75BXLDW11oBLbGjp7SF8Lh',
                        l4Data: [],
                        callToAction: {
                          text: 'Vitamins & Supplements',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/vitamins-&-supplements/c/57140?nav=/shop/vitaminssupplements',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shop The Wellness Shop', 'Fitness & Exercise'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2oHx5s7dyohAnqVYYlZa8L',
                        id: '2oHx5s7dyohAnqVYYlZa8L',
                        l4Data: [],
                        callToAction: {
                          text: 'Fitness & Exercise',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSFitnessExercise?nav=/shop/fitnessexercise',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shop The Wellness Shop', 'Sexual Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'BOxR39xvthelu7JN2OZaj',
                        id: 'BOxR39xvthelu7JN2OZaj',
                        l4Data: [],
                        callToAction: {
                          text: 'Sexual Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSexualHealth?nav=/shop/sexualhealth',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shop The Wellness Shop', 'Sleep Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6yeDjwOjQMM17gIGRqfGGS',
                        id: '6yeDjwOjQMM17gIGRqfGGS',
                        l4Data: [],
                        callToAction: {
                          text: 'Sleep Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSleepHealth?nav=/shop/sleephealth',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shop The Wellness Shop', 'Relaxation & Stress Relief'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2KbbUZLAQyaOxmPcedYCcd',
                        id: '2KbbUZLAQyaOxmPcedYCcd',
                        l4Data: [],
                        callToAction: {
                          text: 'Relaxation & Stress Relief',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSRelaxationStress?nav=/shop/relaxationstress',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: 'hxOl6SC0mTzjwYJzOIg2w',
                    id: 'hxOl6SC0mTzjwYJzOIg2w',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/health/wk25_featurecollecctiontile_webber_enfr.png',
                      },
                      callToAction: {
                        text: 'Vitamins & Supplements by Webber',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-HT-VitaminsAndSupplements?page=0&q=%3Atrending%3AshowInStock%3Atrue%3AbrandName%3AWebber&sort=trending',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Personal Care', 'Bath & Body Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5A4cVKfLLpAQJZjOWefXrP',
                id: '5A4cVKfLLpAQJZjOWefXrP',
                callToAction: {
                  text: 'Bath & Body Care',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/c/FS-PC-BathBodyCare?nav=%252Fshop%252FCategories%252FBathBodyCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6FCQeXt0GMRv86KtwtZ5S6',
                    id: '6FCQeXt0GMRv86KtwtZ5S6',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Shop All Bath & Body Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2M5UtFNUYcOymTUc2TVkf8',
                        id: '2M5UtFNUYcOymTUc2TVkf8',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Bath & Body Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/bath-and-body-care/c/FS-PC-BathBodyCare?nav=%2Fshop%2FCategories%2FBathBodyCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Body Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2nSYUOy2etfJopRXMSfBaQ',
                        id: '2nSYUOy2etfJopRXMSfBaQ',
                        l4Data: [
                          {
                            text: 'Body Creams & Butters',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/Body-Creams-%2526-Butters/c/FS-PC-B101?sort=trending&page=0',
                          },
                          {
                            text: 'Body Lotions',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/Body-Lotions/c/FS-PC-B102?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodyLotions&sort=trending&page=0',
                          },
                          {
                            text: 'Firming & Stretch Mark Creams',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/Firming-%2526-Stretch-Mark-Creams/c/FS-PC-B103?nav=%252Fshop%252FCategories%252FBathBodyCare%252FFirmingAndStretchMarkCreams&sort=trending&page=0',
                          },
                          {
                            text: 'Body Oils',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/Body-Oils/c/FS-PC-B104?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodyOils&sort=trending&page=0',
                          },
                          {
                            text: 'Neck & Décolleté',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/Neck-%2526-D%C3%A9collet%C3%A9/c/FS-PC-B105?nav=%252Fshop%252FCategories%252FBathBodyCare%252FNeckAndD%C3%A9collet%C3%A9&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Body Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/c/FS-PC-B100?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodyCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2jfpYtMSYI9THQtHnlW8S7',
                    id: '2jfpYtMSYI9THQtHnlW8S7',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Bath & Shower'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5ssR7K4NQj2hYVUNboVCsi',
                        id: '5ssR7K4NQj2hYVUNboVCsi',
                        l4Data: [
                          {
                            text: 'Body Wash & Shower Gels',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Bath-%2526-Shower/Body-Wash-%2526-Shower-Gels/c/FS-PC-B201?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodyWashAndShowerGels&sort=trending&page=0',
                          },
                          {
                            text: 'Bath Soaks & Bubble Bath',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Bath-%2526-Shower/Bath-Soaks-%2526-Bubble-Bath/c/FS-PC-B202?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodyScrubs&sort=trending&page=0',
                          },
                          {
                            text: 'Body Scrubs',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Bath-%2526-Shower/Bath-Soaks-%2526-Bubble-Bath/c/FS-PC-B202?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodyScrubs&sort=trending&page=0',
                          },
                          {
                            text: 'Bath Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Bath-%2526-Shower/Bath-Accessories/c/FS-PC-B204?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBathAccessories&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Bath & Shower',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Bath-%2526-Shower/c/FS-PC-B200?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBathAndShower&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4PCG2sZDTCJYuixt2yhvYr',
                    id: '4PCG2sZDTCJYuixt2yhvYr',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Hand & Foot Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6vh28BmE5bUIpdQkeuH70W',
                        id: '6vh28BmE5bUIpdQkeuH70W',
                        l4Data: [
                          {
                            text: 'Hand Soaps',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Hand-%2526-Foot-Care/Hand-Soaps/c/FS-PC-B301?nav=%252Fshop%252FCategories%252FBathBodyCare%252FHandSoaps&sort=trending&page=0',
                          },
                          {
                            text: 'Hand Creams & Lotions',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Hand-%2526-Foot-Care/Hand-Creams-%2526-Lotions/c/FS-PC-B303?nav=%252Fshop%252FCategories%252FBathBodyCare%252FHandCreamsAndLotions&sort=trending&page=0',
                          },
                          {
                            text: 'Foot Creams & Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Hand-%2526-Foot-Care/Foot-Creams-%2526-Treatments/c/FS-PC-B304?nav=%252Fshop%252FCategories%252FBathBodyCare%252FFootCreamsAndTreatments&sort=trending&page=0',
                          },
                          {
                            text: 'Foot Care Tools',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Hand-%2526-Foot-Care/Foot-Care-Tools/c/FS-PC-B305?nav=%252Fshop%252FCategories%252FBathBodyCare%252FFootCareTools&sort=trending&page=0',
                          },
                          {
                            text: 'Hand Sanitizers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Hand-%2526-Foot-Care/Hand-Sanitizers/c/FS-PC-B302?nav=%252Fshop%252FCategories%252FBathBodyCare%252FHandSanitizers&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Hand & Foot Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Hand-%2526-Foot-Care/c/FS-PC-B300?nav=%252Fshop%252FCategories%252FBathBodyCare%252FHandCreamsAndLotions&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Lip Balms'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4YfjwugUwkh5VRF2ybZe1B',
                        id: '4YfjwugUwkh5VRF2ybZe1B',
                        l4Data: [],
                        callToAction: {
                          text: 'Lip Balms',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/BathBodyCare/LipBalms/c/FS-PC-B400?nav=%252Fshop%252FCategories%252FBathBodyCare%252FLipBalms&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7kTqjcrp5eFYXbOfKOaaoh',
                    id: '7kTqjcrp5eFYXbOfKOaaoh',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Deodorant & Antiperspirant'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'BBqnm1IIi2a2MngjBN5mo',
                        id: 'BBqnm1IIi2a2MngjBN5mo',
                        l4Data: [
                          {
                            text: 'Deodorant',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Deodorant-%2526-Antiperspirant/Deodorant/c/FS-PC-B501?nav=%252Fshop%252FCategories%252FBathBodyCare%252FDeodorant&sort=trending&page=0',
                          },
                          {
                            text: 'Body Sprays & Powder',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Deodorant-%2526-Antiperspirant/Body-Sprays-%2526-Powder/c/FS-PC-B502',
                          },
                        ],
                        callToAction: {
                          text: 'Deodorant & Antiperspirant',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Deodorant-%2526-Antiperspirant/c/FS-PC-B500?nav=%252Fshop%252FCategories%252FBathBodyCare%252FDeodorantAndAntiperspirant&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Sun Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2BSqok0gJpYMzkrt2wg7bO',
                        id: '2BSqok0gJpYMzkrt2wg7bO',
                        l4Data: [
                          {
                            text: 'Face Sunscreen',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/bath-and-body-care/sun-care/face-sunscreen/c/FS-PC-B601?nav=%252Fshop%252FCategories%252FBathBodyCare%252FFaceSunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'Body Sunscreen',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Sun-Care/Body-Sunscreen/c/FS-PC-B602?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBodySunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'After Sun',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Sun-Care/After-Sun/c/FS-PC-B603?nav=%252Fshop%252FCategories%252FBathBodyCare%252FAfterSun&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Sun Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Sun-Care/c/FS-PC-B600?nav=%252Fshop%252FCategories%252FBathBodyCare%252FSunCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Self Tanners'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '350HsLSMzRKOxtEHbLoR2y',
                        id: '350HsLSMzRKOxtEHbLoR2y',
                        l4Data: [],
                        callToAction: {
                          text: 'Self Tanners',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Self-Tanners/c/FS-PC-B700?nav=/shop/Categories/BathBodyCare/SelfTanners',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Bath & Body Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'aVI9FTMqaTR2cYOefS6pJ',
                        id: 'aVI9FTMqaTR2cYOefS6pJ',
                        l4Data: [],
                        callToAction: {
                          text: 'Bath & Body Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bath-%2526-Body-Care/Bath-%2526-Body-Gifts-%2526-Sets/c/FS-PC-B800?nav=%252Fshop%252FCategories%252FBathBodyCare%252FBathAndBodyGiftsAndSets&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bath & Body Care', 'Bath & Body Care Refills'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4jOqIJEoBuwVCuAD7WJZfZ',
                        id: '4jOqIJEoBuwVCuAD7WJZfZ',
                        l4Data: [],
                        callToAction: {
                          text: 'Bath & Body Care Refills',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/bath-and-body-care/bath-and-body-care-refills/c/FS-PC-B900?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '23C9F1Vh3P76lkbGgHiQkl',
                    id: '23C9F1Vh3P76lkbGgHiQkl',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/personal-care/wk25_featurecollecctiontile_squatch_enfr.png',
                      },
                      callToAction: {
                        text: 'Dr. Squatch',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-PC-BathBodyCare?sort=trending&page=0&q=%3Atrending%3AbrandName%3ADr.+Squatch%3AshowInStock%3Atrue',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '2tEXN3tn7NN5pnLjgaDrGY',
                    id: '2tEXN3tn7NN5pnLjgaDrGY',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/personal-care/wk25_featurecollecctiontile_toms_enfr.png',
                      },
                      callToAction: {
                        text: "Tom's of Maine",
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-PC-BathBodyCare?sort=trending&page=0&q=%3Atrending%3AbrandName%3ATom%27s+of+Maine',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Personal Care', 'Intimate Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5yYPzL9nalkzNoKXGF3Hht',
                id: '5yYPzL9nalkzNoKXGF3Hht',
                callToAction: {
                  text: 'Intimate Care',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Intimate-Care/c/FS-PC-FeminineCare?nav=%252Fshop%252FCategories%252FIntimateCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '9Dx6W8LeWazpsSl4l4WBC',
                    id: '9Dx6W8LeWazpsSl4l4WBC',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Intimate Care', 'Shop All Intimate Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3wFTb64UmFhLhwRFtXxcZN',
                        id: '3wFTb64UmFhLhwRFtXxcZN',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Intimate Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/c/FS-PC-FeminineCare?nav=%2Fshop%2FCategories%2FIntimateCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Intimate Care', 'Pads & Panty Liners'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5veTXOuWsBv3gebz25AGF5',
                        id: '5veTXOuWsBv3gebz25AGF5',
                        l4Data: [],
                        callToAction: {
                          text: 'Pads & Panty Liners',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Intimate-Care/Pads-%2526-Panty-Liners/c/FS-PC-F100?nav=%252Fshop%252FCategories%252FFeminineCare%252FPadsAndPantyLiners&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Intimate Care', 'Intimate Cleaning & Deodorants'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4K5Kwy1hYme1ZOmcOZF379',
                        id: '4K5Kwy1hYme1ZOmcOZF379',
                        l4Data: [],
                        callToAction: {
                          text: 'Intimate Cleaning & Deodorants',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Intimate-Care/Intimate-Cleaning-%2526-Deodorants/c/FS-PC-F500?nav=%252Fshop%252FCategories%252FFeminineCare%252FIntimateCleaningAndDeodorants&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6ErN2LuS2ncJ8jhbDrQ9SL',
                    id: '6ErN2LuS2ncJ8jhbDrQ9SL',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Intimate Care', 'Tampons'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2z6lHLGq4XDPxSiqBOylrL',
                        id: '2z6lHLGq4XDPxSiqBOylrL',
                        l4Data: [],
                        callToAction: {
                          text: 'Tampons',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Intimate-Care/Tampons/c/FS-PC-F200?nav=%252Fshop%252FCategories%252FFeminineCare%252FTampons&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Intimate Care', 'Yeast Infection Treatments'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5zY7kTB1JSE7st4URssz1n',
                        id: '5zY7kTB1JSE7st4URssz1n',
                        l4Data: [],
                        callToAction: {
                          text: 'Yeast Infection Treatments',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Intimate-Care/Yeast-Infection-Treatments/c/FS-PC-F400?nav=%252Fshop%252FCategories%252FFeminineCare%252FYeastInfectionTreatments&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6HhH2yC1k2ZcOySp5JRhpY',
                    id: '6HhH2yC1k2ZcOySp5JRhpY',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Intimate Care', 'Cups'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6t5S4ZWcmVi1IdERlYtqTB',
                        id: '6t5S4ZWcmVi1IdERlYtqTB',
                        l4Data: [],
                        callToAction: {
                          text: 'Cups',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Intimate-Care/Cups/c/FS-PC-F300?nav=%252Fshop%252FCategories%252FFeminineCare%252FCups&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Bladder Control & Incontinence'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '6If0DWZSfZJrxOiUnXwx8r',
                id: '6If0DWZSfZJrxOiUnXwx8r',
                callToAction: {
                  text: 'Bladder Control & Incontinence',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bladder-Control-%2526-Incontinence/c/FS-PC-BladderControlAndIncontinence?nav=%252Fshop%252FCategories%252FBladderControlAndIncontinence&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '51kktKTmFH5PYOYsgzTLeQ',
                    id: '51kktKTmFH5PYOYsgzTLeQ',
                    l3Data: [
                      {
                        hierarchy: [
                          'Personal Care',
                          'Bladder Control & Incontinence',
                          'Shop All Bladder Control & Incontinence',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1thuO74e6syR3P3zDSKyQG',
                        id: '1thuO74e6syR3P3zDSKyQG',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Bladder Control & Incontinence',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/bladder-control-and-incontinence/c/FS-PC-BladderControlAndIncontinence?nav=%2Fshop%2FCategories%2FBladderControlAndIncontinence&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Bladder Control & Incontinence', 'Incontinence Pads & Liners'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '27EEYz5NGsbkFuBnh0Cm6K',
                        id: '27EEYz5NGsbkFuBnh0Cm6K',
                        l4Data: [],
                        callToAction: {
                          text: 'Incontinence Pads & Liners',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bladder-Control-%2526-Incontinence/Incontinence-Pads-%2526-Liners/c/FS-PC-I100?nav=%252Fshop%252FCategories%252FBladderControlAndIncontinence%252FIncontinencePadsAndLiners&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5LiZt2O2PUvCwGckHnpnV6',
                    id: '5LiZt2O2PUvCwGckHnpnV6',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Bladder Control & Incontinence', 'Protective Underwear & Briefs'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7Lr2NfhbNslqsrz2fGtZBB',
                        id: '7Lr2NfhbNslqsrz2fGtZBB',
                        l4Data: [],
                        callToAction: {
                          text: 'Protective Underwear & Briefs',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Bladder-Control-%2526-Incontinence/Protective-Underwear-%2526-Briefs/c/FS-PC-I200?nav=%252Fshop%252FCategories%252FBladderControlAndIncontinence%252FProtectiveUnderwearAndBriefs&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Oral Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '6sI4OfI48lGGYtKX57ZAg0',
                id: '6sI4OfI48lGGYtKX57ZAg0',
                callToAction: {
                  text: 'Oral Care',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/c/FS-PC-OralCare?nav=%252Fshop%252FCategories%252FOralCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'xl3jn5zJG2OCMyW8ztNAt',
                    id: 'xl3jn5zJG2OCMyW8ztNAt',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Oral Care', 'Shop All Oral Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4CrQdbJbywmvMkpX8LGuzS',
                        id: '4CrQdbJbywmvMkpX8LGuzS',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Oral Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/oral-care/c/FS-PC-OralCare?nav=%2Fshop%2FCategories%2FOralCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Oral Care', 'Toothbrushes'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3mqiLuJFhS59plbcmXiDVp',
                        id: '3mqiLuJFhS59plbcmXiDVp',
                        l4Data: [
                          {
                            text: 'Electric Toothbrushes',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Toothbrushes/Electric-Toothbrushes/c/FS-PC-O101?nav=%252Fshop%252FCategories%252FOralCare%252FElectricalToothbrushes&sort=trending&page=0',
                          },
                          {
                            text: 'Toothbrushes',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Toothbrushes/Toothbrushes/c/FS-PC-O102?nav=%252Fshop%252FCategories%252FOralCare%252FToothbrushes&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Toothbrushes',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Toothbrushes/Toothbrushes/c/FS-PC-O102?nav=%252Fshop%252FCategories%252FOralCare%252FToothbrushes&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3s2q6GwD9KXLARhXXDNVSk',
                    id: '3s2q6GwD9KXLARhXXDNVSk',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Oral Care', 'Oral Care Products'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3Q00rbwWsVG1T3JvH4dJb5',
                        id: '3Q00rbwWsVG1T3JvH4dJb5',
                        l4Data: [
                          {
                            text: 'Toothpaste',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Toothpaste/c/FS-PC-O201?nav=%252Fshop%252FCategories%252FOralCare%252FToothpaste&sort=trending&page=0',
                          },
                          {
                            text: 'Teeth Whitening',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Teeth-Whitening/c/FS-PC-O202?nav=%252Fshop%252FCategories%252FOralCare%252FTeethWhitening&sort=trending&page=0',
                          },
                          {
                            text: 'Mouthwash & Rinses',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Mouthwash-%2526-Rinses/c/FS-PC-O203?nav=%252Fshop%252FCategories%252FOralCare%252FMouthwashAndRinses&sort=trending&page=0',
                          },
                          {
                            text: 'Breath Fresheners',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Breath-Fresheners/c/FS-PC-O204?nav=%252Fshop%252FCategories%252FOralCare%252FBreathFresheners&sort=trending&page=0',
                          },
                          {
                            text: 'Dental Care',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Dental-Care/c/FS-PC-O206?nav=%252Fshop%252FCategories%252FOralCare%252FDentalCare&sort=trending&page=0',
                          },
                          {
                            text: 'Oral Hygiene Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Oral-Hygiene-Accessories/c/FS-PC-O207?nav=%252Fshop%252FCategories%252FOralCare%252FOralHygieneAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Dental Floss',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/Dental-Floss/c/FS-PC-O205?nav=%252Fshop%252FCategories%252FOralCare%252FDentalFloss&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Oral Care Products',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Oral-Care/Oral-Care-Products/c/FS-PC-O200?nav=%252Fshop%252FCategories%252FOralCare%252FOralCareProducts&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Shaving & Hair Removal'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '1SvfMZNZp65mecfa4zIsJV',
                id: '1SvfMZNZp65mecfa4zIsJV',
                callToAction: {
                  text: 'Shaving & Hair Removal',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Shaving-%2526-Hair-Removal/c/FS-PC-ShavingAndHairRemoval?nav=%252Fshop%252FCategories%252FShavingAndHairRemoval&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2Pq4SwR3YAjZhRZYW86lpg',
                    id: '2Pq4SwR3YAjZhRZYW86lpg',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Shop All Shaving & Hair Removal '],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '35yFT05ePxNOfifPktAE6Y',
                        id: '35yFT05ePxNOfifPktAE6Y',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Shaving & Hair Removal ',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/shaving-and-hair-removal/c/FS-PC-ShavingAndHairRemoval?nav=%2Fshop%2FCategories%2FShavingAndHairRemoval&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Shaving Creams & Gels'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2nsOsfLVE0yqdg55MDf1b3',
                        id: '2nsOsfLVE0yqdg55MDf1b3',
                        l4Data: [],
                        callToAction: {
                          text: 'Shaving Creams & Gels',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Men%2527s/Shaving-for-Him/Shaving-Creams-%2526-Gels/c/FS-PC-M101?nav=%252Fshop%252FCategories%252FMen%252FShavingCreamsAndGels&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Hair Waxes & Removal Creams'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'duxUBioijFZBcHHkxdETb',
                        id: 'duxUBioijFZBcHHkxdETb',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair Waxes & Removal Creams',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Shaving-%2526-Hair-Removal/Hair-Waxes-%2526-Removal-Creams/c/FS-PC-S300?nav=%252Fshop%252FCategories%252FShavingAndHairRemoval%252FHairWavesRemovalCreams&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3l8AKU3avs3igbo4q8S1ud',
                    id: '3l8AKU3avs3igbo4q8S1ud',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Electric Shavers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2a9puGEZR04BAUzATYMYOi',
                        id: '2a9puGEZR04BAUzATYMYOi',
                        l4Data: [],
                        callToAction: {
                          text: 'Electric Shavers',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Shaving-%2526-Grooming-Tools-%2526-Accessories/Electric-Shavers/c/FS-PC-T301?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FElectricShavers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Disposable Razors & Blades'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4QxOodrMESmYpwcJ7Ip817',
                        id: '4QxOodrMESmYpwcJ7Ip817',
                        l4Data: [],
                        callToAction: {
                          text: 'Disposable Razors & Blades',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Men%2527s/Shaving-for-Him/Disposable-Razors-%2526-Blades/c/FS-PC-M105?nav=%252Fshop%252FCategories%252FMen%252FElectricShavers&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6TkYqWpKJCJ3W0HXGi78g1',
                    id: '6TkYqWpKJCJ3W0HXGi78g1',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Hair Removal Devices'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6nx7A8vOeg4z9tSk5xiD01',
                        id: '6nx7A8vOeg4z9tSk5xiD01',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair Removal Devices',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Shaving-%2526-Grooming-Tools-%2526-Accessories/Grooming-Accessories/c/FS-PC-T304?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FGroomingAccessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7h3so0p2ZqNSZ1sf0cmo55',
                    id: '7h3so0p2ZqNSZ1sf0cmo55',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Shaving & Hair Removal', 'Grooming Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '231uHlfwoUECyq54y54ptA',
                        id: '231uHlfwoUECyq54y54ptA',
                        l4Data: [],
                        callToAction: {
                          text: 'Grooming Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Men%2527s/Shaving-for-Him/Grooming-Accessories/c/FS-PC-M106?nav=%252Fshop%252FCategories%252FMen%252FGroomingAccessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', "Men's"],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '8lBCgaXCJuyqND1G6FoYM',
                id: '8lBCgaXCJuyqND1G6FoYM',
                callToAction: {
                  text: "Men's",
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Men%2527s/c/FS-PC-Men?nav=%252Fshop%252FCategories%252FMen&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2MUHFrK96CQfd2ogqDLnJm',
                    id: '2MUHFrK96CQfd2ogqDLnJm',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', "Men's", "Shop All Men's"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1ajC9PV4rBHUndtwPu5VJm',
                        id: '1ajC9PV4rBHUndtwPu5VJm',
                        l4Data: [],
                        callToAction: {
                          text: "Shop All Men's",
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/mens/c/FS-Mens?nav=%2Fshop%2FCategories%2FMen&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', "Men's", 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'epUASxLKCebIyvu7SRe7S',
                        id: 'epUASxLKCebIyvu7SRe7S',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals?q=%3Atrending%3Acategory%3AFS-Mens&nav=%2Fshop%2FMens%2FNewArrivals&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', "Men's", 'Best Sellers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6QzWSIIgDPLEF5TxrkJmMW',
                        id: '6QzWSIIgDPLEF5TxrkJmMW',
                        l4Data: [],
                        callToAction: {
                          text: 'Best Sellers',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/best-sellers/mens-best-sellers/c/BeautyMensBestSellers?nav=%2Fshop%2FCategories%2FMens%2FBestSellers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', "Men's", 'Shaving for Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3dWf4gNtH92ufxaoVIxxoL',
                        id: '3dWf4gNtH92ufxaoVIxxoL',
                        l4Data: [],
                        callToAction: {
                          text: 'Shaving for Him',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/shaving-for-him/c/FS-M500?nav=%252Fshop%252FCategories%252FMen%252FShavingforHim&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '52ukLM7RQEc8ddDypd96VX',
                    id: '52ukLM7RQEc8ddDypd96VX',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', "Men's", 'Skin Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4sNrSzP5JOFpLGao7cTLiI',
                        id: '4sNrSzP5JOFpLGao7cTLiI',
                        l4Data: [
                          {
                            text: 'Cleansers & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/cleansers-and-accessories/c/FS-M102?nav=shop%2FCategories%2FSkinCare%2FFaceSunscreen&sort=trending&page=0',
                          },
                          {
                            text: 'Lip Balm',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/lip-balm/c/FS-M104?nav=%2Fshop%2FCategories%2FSkinCare%2FBodySunscreen&sort=trending&page=0&lang=en',
                          },
                          {
                            text: 'Moisturizers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/moisturizers/c/FS-M101?nav=shop%2FCategories%2FSkinCare%2FAfterSun&sort=trending&page=0',
                          },
                          {
                            text: 'Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/treatments/c/FS-M103?nav=shop%2FCategories%2FSkinCare%2FAfterSun&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Skin Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/c/FS-M100?nav=/shop/Categories/SkinCare/SunCare',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', "Men's", 'Fragrance For Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6WrelvBZuWTxXuaw7XZMJg',
                        id: '6WrelvBZuWTxXuaw7XZMJg',
                        l4Data: [
                          {
                            text: 'Cologne',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/Fragrance/Cologne/c/FS-F201?nav=/shop/Categories/Fragrance/Cologne',
                          },
                          {
                            text: 'Body Sprays & Deodorant',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Body-Sprays-%2526-Deodorant/c/FS-F202?nav=%252Fshop%252FCategories%252FMen%252FBodySpraysAndDeodorant&sort=trending&page=0',
                          },
                          {
                            text: 'Celebrity Fragrances',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Celebrity-Fragrances-for-him/c/FS-F203',
                          },
                          {
                            text: 'Fragrance Bath & Body For Him',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/Fragrance-Bath-%2526-Body/c/FS-F204?nav=beauty%252Fmens%252Ffragranceforhim%252Ffragrancebathandbody&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Fragrance For Him',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Fragrance/Fragrance-for-Him/c/FS-F200?nav=%252Fshop%252FCategories%252FMen%252FFragranceforHim&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '23N9PC3LmqQ1Bjd3Dt8JdX',
                    id: '23N9PC3LmqQ1Bjd3Dt8JdX',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', "Men's", 'Shaving For Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6QZ04gQqdI5U5SGuXe8QWK',
                        id: '6QZ04gQqdI5U5SGuXe8QWK',
                        l4Data: [
                          {
                            text: 'Shaving Creams & Gels',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Shaving-Creams-%2526-Gels/c/FS-M501?nav=%252Fshop%252FCategories%252FMen%252FShavingCreamsAndGels&sort=trending&page=0',
                          },
                          {
                            text: 'After Shave',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/After-Shave/c/FS-M502?nav=%252Fshop%252FCategories%252FMen%252FAfterShave&sort=trending&page=0',
                          },
                          {
                            text: 'Electric Shavers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Electric-Shavers/c/FS-M503?nav=%252Fshop%252FCategories%252FMen%252FElectricShavers&sort=trending&page=0',
                          },
                          {
                            text: 'Disposable Razors & Blades',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Disposable-Razors-%2526-Blades/c/FS-M504?nav=%252Fshop%252FCategories%252FMen%252FDisposableRazorAndBlades&sort=trending&page=0',
                          },
                          {
                            text: 'Grooming Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/Grooming-Accessories/c/FS-M505?nav=%252Fshop%252FCategories%252FMen%252FGroomingAccessories&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Shaving For Him',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Beauty/Men%2527s/Shaving-for-Him/c/FS-M500?nav=%252Fshop%252FCategories%252FMen%252FShavingforHim&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', "Men's", 'Hair For Him'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '59TxdG2fTnwPQUx6Kyd4D1',
                        id: '59TxdG2fTnwPQUx6Kyd4D1',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair For Him',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/beauty/men-s/hair-for-him/c/FS-M400?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Tools & Accessories'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2mMEKFoZBwJd7wLT4P3zJE',
                id: '2mMEKFoZBwJd7wLT4P3zJE',
                callToAction: {
                  text: 'Tools & Accessories',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/c/FS-PC-ToolsAccess?nav=%252Fshop%252FCategories%252FToolsAndAccessories&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '53kfHvgQh4IFaMpEQc1L91',
                    id: '53kfHvgQh4IFaMpEQc1L91',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Shop All Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '37DkLBO9zffnTQX2vel8mF',
                        id: '37DkLBO9zffnTQX2vel8mF',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Tools & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%26-Accessories/c/FS-PC-ToolsAccess?nav=%2Fshop%2FCategories%2FToolsAndAccessories&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Hair Styling Tools'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '456CD2rZElJXkfr28B6wsV',
                        id: '456CD2rZElJXkfr28B6wsV',
                        l4Data: [
                          {
                            text: 'Curling Irons',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Hair-Styling-Tools/Curling-Irons/c/FS-PC-T103?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FCurlingIrons&sort=trending&page=0',
                          },
                          {
                            text: 'Blow Dryers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Hair-Styling-Tools/Blow-Dryers/c/FS-PC-T101?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FBlowDryers&sort=trending&page=0',
                          },
                          {
                            text: 'Straighteners & Flat Irons',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Hair-Styling-Tools/Straighteners-%2526-Flat-Irons/c/FS-PC-T102?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FStraightenersAndFlatIrons&sort=trending&page=0',
                          },
                          {
                            text: 'Brushes & Combs',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Hair-Styling-Tools/Brushes-%2526-Combs/c/FS-PC-T104?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FBrushesAndCombs&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Styling Tools',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Hair-Styling-Tools/c/FS-PC-T100?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FHairStylingTools&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4JP3LlSTAu0nVzzx6Ooh7x',
                    id: '4JP3LlSTAu0nVzzx6Ooh7x',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Hair Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4gL2nr0AKCWecPjl2gybAl',
                        id: '4gL2nr0AKCWecPjl2gybAl',
                        l4Data: [
                          {
                            text: 'Clips & Pins',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/ClipsAndPins/c/FS-T501?nav=/shop/Categories/HairCare/HairAccessories/ClipsAndPins',
                          },
                          {
                            text: 'Headbands & Head Wraps',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HeadbandsAndHeadWraps/c/FS-T502?nav=/shop/Categories/HairCare/HeadbandsAndHeadWraps',
                          },
                          {
                            text: 'Elastics',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/ToolsAndAccessories/Elastics/c/FS-T503?nav=/shop/Categories/ToolsAndAccessories/Elastics',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/Categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/Categories/HairCare/HairAccessories',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1wY5P26iMQGNEN28dBX1rX',
                    id: '1wY5P26iMQGNEN28dBX1rX',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Shaving & Grooming Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '33pFbh7fQhKlCEejuVrHRY',
                        id: '33pFbh7fQhKlCEejuVrHRY',
                        l4Data: [
                          {
                            text: 'Electric Shavers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Shaving-%2526-Grooming-Tools-%2526-Accessories/Electric-Shavers/c/FS-PC-T301?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FElectricShavers&sort=trending&page=0',
                          },
                          {
                            text: 'Disposable Razor & Blades',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Shaving-%2526-Grooming-Tools-%2526-Accessories/Disposable-Razors-%2526-Blades/c/FS-PC-T302?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FDisposableRazorAndBlades&sort=trending&page=0',
                          },
                          {
                            text: 'Hair Removal Devices',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Shaving-%2526-Grooming-Tools-%2526-Accessories/Grooming-Accessories/c/FS-PC-T304?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FGroomingAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Grooming Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Men%2527s/Shaving-for-Him/Grooming-Accessories/c/FS-PC-M106?nav=%252Fshop%252FCategories%252FMen%252FGroomingAccessories&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Shaving & Grooming Tools & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Shaving-%2526-Grooming-Tools-%2526-Accessories/c/FS-PC-T300?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FShavingAndGroomingToolsAndAcessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3VNCRXT3eIkbyz4Q1NVtOH',
                    id: '3VNCRXT3eIkbyz4Q1NVtOH',
                    l3Data: [
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Wardrobe Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'iqscphInc6QJ63jIUi6uR',
                        id: 'iqscphInc6QJ63jIUi6uR',
                        l4Data: [
                          {
                            text: 'Jewellery',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Jewellery/c/FS-PC-T401?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FJewellery&sort=trending&page=0',
                          },
                          {
                            text: 'Bra Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Bra-Accessories/c/FS-PC-T402?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FBraAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Hosiery & Tights',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Hosiery-%2526-Tights/c/FS-PC-T403?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FHosieryAndTights&sort=trending&page=0',
                          },
                          {
                            text: 'Socks',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Socks/c/FS-PC-T404?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FSocks&sort=trending&page=0',
                          },
                          {
                            text: 'Winter Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/Winter-Accessories/c/FS-PC-T405?nav=shop%252FPersonalCare%252FToolsAndAccessories%252FWardrobeAccessories%252FWinterAccessories&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Wardrobe Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Wardrobe-Accessories/c/FS-PC-T400?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FWardrobeAccessories&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Travel Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1ImaqEY0EpyrB7M3qNK4UH',
                        id: '1ImaqEY0EpyrB7M3qNK4UH',
                        l4Data: [],
                        callToAction: {
                          text: 'Travel Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Travel-Accessories/c/FS-PC-T500?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FTravelAccessories&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Personal Care', 'Tools & Accessories', 'Weather Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '44zM6rSucNrKQ3tl9yUFnM',
                        id: '44zM6rSucNrKQ3tl9yUFnM',
                        l4Data: [],
                        callToAction: {
                          text: 'Weather Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Tools-%2526-Accessories/Weather-Accessories/c/FS-PC-T600?nav=%252Fshop%252FCategories%252FToolsAndAccessories%252FWeatherAccessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Travel Sized Items'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2e79pNKEu199Y0jiJLIhA6',
                id: '2e79pNKEu199Y0jiJLIhA6',
                callToAction: {
                  text: 'Travel Sized Items',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Travel-Sized-Items/c/FS-PC-TravelSizedItems?nav=%252Fshop%252FCategories%252FTravelSizedItems&sort=trending&page=0',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Personal Care', 'Insect & Pest Control'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7zl0xPTtrOfScM9GZXZnEF',
                id: '7zl0xPTtrOfScM9GZXZnEF',
                callToAction: {
                  text: 'Insect & Pest Control',
                  url: 'https://shop.shoppersdrugmart.ca/shop/categories/personal-care/insect-and-pest-control/c/FS-PC-InsectPestControl?nav=%2Fshop%2FCategories%2FInsectsPestControl&sort=trending&page=0',
                },
                columns: [],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '6wdXpQfeljKyz6n4gbxkJK',
          id: '6wdXpQfeljKyz6n4gbxkJK',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '6wdXpQfeljKyz6n4gbxkJK',
            id: '6wdXpQfeljKyz6n4gbxkJK',
            callToAction: {
              text: 'Health',
              url: 'https://shop.shoppersdrugmart.ca/Health?nav=/shop/health',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Health', 'Shop All Health'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3PHzvQmN6LrFXt2PrvSzVS',
                id: '3PHzvQmN6LrFXt2PrvSzVS',
                callToAction: {
                  text: 'Shop All Health',
                  url: 'https://shop.shoppersdrugmart.ca/Health?nav=/shop/health',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health', 'Shop The Wellness Shop'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3tTZcMFyxAkNKa71cLFbKm',
                id: '3tTZcMFyxAkNKa71cLFbKm',
                callToAction: {
                  text: 'Shop The Wellness Shop',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health', 'Travel Sized Items'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5ytreq36xg6BDFkfmT4FMm',
                id: '5ytreq36xg6BDFkfmT4FMm',
                callToAction: {
                  text: 'Travel Sized Items',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Personal-Care/Travel-Sized-Items/c/FS-PC-TravelSizedItems',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health', 'Medicine & Treatments'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: 'yPzFNvOQMRbSxTbw28kq5',
                id: 'yPzFNvOQMRbSxTbw28kq5',
                callToAction: {
                  text: 'Medicine & Treatments',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/c/FS-HT-MedicineAndTreatments?nav=%252Fshop%252FCategories%252FMedicineAndTreatments&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6g0XfxRH8obhigGheR7nyL',
                    id: '6g0XfxRH8obhigGheR7nyL',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Shop All Medicine & Treatments'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2ii3GFOFl5sS6vB9CEP7Pu',
                        id: '2ii3GFOFl5sS6vB9CEP7Pu',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Medicine & Treatments',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/health/medicine-and-treatments/c/FS-HT-MedicineAndTreatments?nav=%2Fshop%2FCategories%2FMedicineAndTreatments&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Cough, Cold & Flu'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6wNifE3Gf6BWiJcn7unfpq',
                        id: '6wNifE3Gf6BWiJcn7unfpq',
                        l4Data: [
                          {
                            text: 'Cough, Cold & Flu Medication',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/CoughColdAndFluMedication/c/FS-HT-M101?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FCoughColdAndFluMedication&sort=trending&page=0',
                          },
                          {
                            text: 'Nasal Care & Sleep Aids',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/NasalCare/c/FS-HT-M104?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FNasalCare&sort=trending&page=0',
                          },
                          {
                            text: 'Humidifiers, Purifiers & Vaporizers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/HumidifiersPurifiersAndVaporizers/c/FS-HT-M105?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FHumidifiersPurifiersAndVaporizers&sort=trending&page=0',
                          },
                          {
                            text: 'Topical Rub',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/TopicalRub/c/FS-MT-107?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FTopicalRub&sort=trending&page=0',
                          },
                          {
                            text: 'Thermometers',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/Thermometers/c/FS-HT-M106?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FThermometers&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Cough, Cold & Flu',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Cough%252C-Cold-%2526-Flu/c/FS-HT-M100?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FCoughColdAndFlu&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Quit Smoking'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '44EQVLQxZbIV5lYJajhT9n',
                        id: '44EQVLQxZbIV5lYJajhT9n',
                        l4Data: [],
                        callToAction: {
                          text: 'Quit Smoking',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Quit-Smoking/c/FS-HT-M700?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FQuitSmoking&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Sleep Aids'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2OceOiIJwbGILJTblQHrHW',
                        id: '2OceOiIJwbGILJTblQHrHW',
                        l4Data: [],
                        callToAction: {
                          text: 'Sleep Aids',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Sleep-Aids/c/FS-HT-M800?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FSleepAids&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Pill Organization'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '39z7uGUmutyzKqyxBw3P36',
                        id: '39z7uGUmutyzKqyxBw3P36',
                        l4Data: [],
                        callToAction: {
                          text: 'Pill Organization',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pill-Organization/c/FS-HT-M900?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FPillOrganization&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4OHqkSvlqAYeuGNXci26N9',
                    id: '4OHqkSvlqAYeuGNXci26N9',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Pain Relief'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4CclUaMzpAyirf4eD14MSE',
                        id: '4CclUaMzpAyirf4eD14MSE',
                        l4Data: [
                          {
                            text: 'Muscle, Back & Joint Pain Relief',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Muscle%252C-Back-%2526-Joint-Pain-Relief/c/FS-HT-M202?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FMuscleBackAndJointPainRelief&sort=trending&page=0',
                          },
                          {
                            text: 'Topical Pain Relief',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Topical-Pain-Relief/c/FS-HT-M203?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FTopicPainRelief&sort=trending&page=0',
                          },
                          {
                            text: 'Hemorrhoid Relief',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Hemorrhoid-Relief/c/FS-HT-M206?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FHemorrhoidRelief&sort=trending&page=0',
                          },
                          {
                            text: 'Oral Pain Relief',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Oral-Pain-Relief/c/FS-HT-M209?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FOralPainRelief&sort=trending&page=0',
                          },
                          {
                            text: 'Hot & Cold Treatment',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Hot-%2526-Cold-Treatment/c/FS-HT-M210?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FHotAndColdTreatment&sort=trending&page=0',
                          },
                          {
                            text: "Children's Pain & Fever Relief",
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/ChildrensPainAndFeverRelief/c/FS-HT-M204?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FChildrensPainAndFeverRelief&sort=trending&page=0',
                          },
                          {
                            text: 'Headache & Fever Relief',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Headache-%2526-Fever-Relief/c/FS-HT-M201?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FHeadacheAndFeverRelief&sort=trending&page=0',
                          },
                          {
                            text: 'Heart Health Treatment',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Heart-Health-Treatment/c/FS-HT-M205?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FHeartHealthAndLowDose&sort=trending&page=0',
                          },
                          {
                            text: 'Menstrual Pain Relief',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/Menstrual-Pain-Relief/c/FS-HT-M208?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FMenstrualPainRelief&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Pain Relief',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Pain-Relief/c/FS-HT-M200?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FPainRelief&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '33p5ph9BcYSkOETViyhSc1',
                    id: '33p5ph9BcYSkOETViyhSc1',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'First Aid'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7zABPsMd1GCMwtXPlagIfZ',
                        id: '7zABPsMd1GCMwtXPlagIfZ',
                        l4Data: [
                          {
                            text: 'Bandaging Supplies & Tape',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/BandagingSuppliesAndTape/c/FS-HT-M401?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FBandagingSuppliesAndTape&sort=trending&page=0',
                          },
                          {
                            text: 'Antiseptics',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/Antiseptics/c/FS-HT-M402?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FAntiseptics&sort=trending&page=0',
                          },
                          {
                            text: 'Skin Relief',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/SkinRelief/c/FS-HT-M403?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FSkinRelief&sort=trending&page=0',
                          },
                          {
                            text: 'First Aid Supplies',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/FirstAidSupplies/c/FS-HT-M405?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FFirstAidSupplies&sort=trending&page=0',
                          },
                          {
                            text: 'Lice Care',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/LiceCare/c/FS-HT-M406?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FLiceCare&sort=trending&page=0',
                          },
                          {
                            text: 'Medical Supports & Garments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/MedicalSupportsAndGarments/c/FS-HT-M407?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FMedicalSupportsAndGarments&sort=trending&page=0',
                          },
                          {
                            text: 'Burn & Wound Care',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/BurnAndWoundCare/c/FS-HT-M404?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FBurnAndWoundCare&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'First Aid',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/First-Aid/c/FS-HT-M400?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FFirstAid&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Allergy & Sinus'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '30FEwC2MA7hzK4edxQzLr5',
                        id: '30FEwC2MA7hzK4edxQzLr5',
                        l4Data: [
                          {
                            text: 'Antihistamines',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/Antihistamines/c/FS-HT-M501?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FAntihistamines&sort=trending&page=0',
                          },
                          {
                            text: "Children's Antihistamines",
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/ChildrensAntihistamines/c/FS-HT-M502?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FChildrensAntihistamines&sort=trending&page=0',
                          },
                          {
                            text: 'Nasal Care',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/NasalCare/c/FS-HT-M503?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FNasalCare&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Allergy & Sinus',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Allergy-%2526-Sinus/c/FS-HT-M500?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FAllergyAndSinus&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'W4WzJlnTojPJz8zRsQ0mW',
                    id: 'W4WzJlnTojPJz8zRsQ0mW',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Digestive Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3CYIQfkRZhT9S5daK5SBVM',
                        id: '3CYIQfkRZhT9S5daK5SBVM',
                        l4Data: [
                          {
                            text: 'Acid Reflux',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/AcidReflux/c/FS-HT-M301?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FAcidReflux&sort=trending&page=0',
                          },
                          {
                            text: 'Probiotics',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/Probiotics/c/FS-HT-M302?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FProbiotics&sort=trending&page=0',
                          },
                          {
                            text: 'Fibres & Laxatives',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/FibresAndLaxatives/c/FS-HT-M303?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FFibresAndLaxatives&sort=trending&page=0',
                          },
                          {
                            text: 'Motion Sickness & Nausea',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/MotionSicknessAndNausea/c/FS-HT-M305?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FMotionSicknessAndNausea&sort=trending&page=0',
                          },
                          {
                            text: 'Digestive Intolerance',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/DigestiveIntolerance/c/FS-HT-M306?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDigestiveIntolerance&sort=trending&page=0',
                          },
                          {
                            text: 'Enemas',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/Enemas/c/FS-HT-M307?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FEnemas&sort=trending&page=0',
                          },
                          {
                            text: 'Diarrhea Relief',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/DiarrheaRelief/c/FS-HT-M304?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDiarrheaRelief&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Digestive Health',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Digestive-Health/c/FS-HT-M300?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDigestiveHealth&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Medicine & Treatments', 'Diabetes Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5Xm6y8JxHkkcz0ecgNq7Ke',
                        id: '5Xm6y8JxHkkcz0ecgNq7Ke',
                        l4Data: [
                          {
                            text: 'Glucose Testing',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/GlucoseTesting/c/FS-HT-M601?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FGlucoseTesting&sort=trending&page=0',
                          },
                          {
                            text: 'Insulin Adminstration',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/InsulinAdminstration/c/FS-HT-M602?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FInsulinAdminstration&sort=trending&page=0',
                          },
                          {
                            text: 'Diabetes Supplements',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/DiabeticSupplements/c/FS-HT-M603?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDiabeticSupplements&sort=trending&page=0',
                          },
                          {
                            text: 'Diabetes Skin Care ',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/DiabeticSkincare/c/FS-HT-M605?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDiabeticSkincare&sort=trending&page=0',
                          },
                          {
                            text: 'Sweeteners',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/Sweeteners/c/FS-HT-V402?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FSweeteners&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Diabetes Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Diabetes-Care/c/FS-HT-M600?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDiabeticCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '4IgzWn2w2SdiSWeH8h2JGq',
                    id: '4IgzWn2w2SdiSWeH8h2JGq',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/health/wk25_featurecollecctiontile_tylenol_enfr.png',
                      },
                      callToAction: {
                        text: 'Tylenol Pain Relief',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-HT-M200?page=0&q=%3Atrending%3AshowInStock%3Atrue%3AbrandName%3ATylenol&sort=trending',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Health', 'Vitamins & Supplements'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7CPXFZ7tdSHQ8A3TNkDWjR',
                id: '7CPXFZ7tdSHQ8A3TNkDWjR',
                callToAction: {
                  text: 'Vitamins & Supplements',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/c/FS-HT-VitaminsAndSupplements?nav=%252Fshop%252FCategories%252FMedicineAndTreatments&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3HLYBr1eeSRD4JoZQA42m2',
                    id: '3HLYBr1eeSRD4JoZQA42m2',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Vitamins & Supplements', 'Shop All Vitamins & Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4E0u3N620bjHkjl3A2zo0J',
                        id: '4E0u3N620bjHkjl3A2zo0J',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Vitamins & Supplements',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/health/vitamins-and-supplements/c/FS-HT-VitaminsAndSupplements?nav=%2Fshop%2FCategories%2FMedicineAndTreatments&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Vitamins & Supplements', 'Vitamins'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '255iCOVoYDruqwHjMfgGvG',
                        id: '255iCOVoYDruqwHjMfgGvG',
                        l4Data: [
                          {
                            text: 'Adults Multivitamins',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Adults-Multivitamins/c/FS-HT-V101?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FAdultsMultivitamins&sort=trending&page=0',
                          },
                          {
                            text: "Children's Multivitamins & Vitamins",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Children%2527s-Multivitamins-%2526-Vitamins/c/FS-HT-V102?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FChildrensMultivitaminsAndVitamins&sort=trending&page=0',
                          },
                          {
                            text: 'Vitamin A',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Vitamin-A/c/FS-HT-V104?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FVitaminA&sort=trending&page=0',
                          },
                          {
                            text: 'Vitamin B',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Vitamin-B/c/FS-HT-V105?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FVitaminB&sort=trending&page=0',
                          },
                          {
                            text: 'Vitamin C',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Vitamin-C/c/FS-HT-V106?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FVitaminC&sort=trending&page=0',
                          },
                          {
                            text: 'Vitamin D',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Vitamin-D/c/FS-HT-V107?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FVitaminD&sort=trending&page=0',
                          },
                          {
                            text: 'Vitamin E',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Vitamin-E/c/FS-HT-V108?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FVitaminE&sort=trending&page=0',
                          },
                          {
                            text: 'Prenatal Vitamins',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/Prenatal-Vitamins/c/FS-HT-V103?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FPrenatalVitamins&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Vitamins',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Vitamins/c/FS-HT-V100?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5Sd6btOSPrfWSrY44ZL1Cc',
                    id: '5Sd6btOSPrfWSrY44ZL1Cc',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Vitamins & Supplements', 'Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3cyPIXg9lwWP0pRJ5htqsN',
                        id: '3cyPIXg9lwWP0pRJ5htqsN',
                        l4Data: [
                          {
                            text: 'Omega Fish Oils & Essential Fatty Acids',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Omega-Fish-Oils-%2526-Essential-Fatty-Acids/c/FS-HT-V201?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FOmegaFishOilsAndEssentialFattyAcids&sort=trending&page=0',
                          },
                          {
                            text: 'Collagen',
                            url: 'https://shop.shoppersdrugmart.ca/shop/categories/health/vitamins-and-supplements/supplements/collagen/c/FS-HT-V202?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FCollagen&sort=trending&page=0',
                          },
                          {
                            text: 'Probiotics',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/MedicineAndTreatments/Probiotics/c/FS-HT-M302?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FProbiotics&sort=trending&page=0',
                          },
                          {
                            text: 'Joint Health',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Joint-Health/c/FS-HT-V204?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FJointHealth&sort=trending&page=0',
                          },
                          {
                            text: 'Eye Health',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Eye-Health/c/FS-HT-V205?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FEyeHealth&sort=trending&page=0',
                          },
                          {
                            text: 'Hair, Nails & Skin Health',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Hair%252C-Nails-%2526-Skin-Health/c/FS-HT-V207?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FHairNailsAndSkinHealth&sort=trending&page=0',
                          },
                          {
                            text: 'Melatonin',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Melatonin/c/FS-HT-V209?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMelatonin&sort=trending&page=0',
                          },
                          {
                            text: 'Health Food Supplements',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Health-Food-Supplements/c/FS-HT-V210?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FHealthFoodSupplements&sort=trending&page=0',
                          },
                          {
                            text: 'Homeopathy',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Homeopathy/c/FS-HT-V211?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FHomeopathy&sort=trending&page=0',
                          },
                          {
                            text: 'Caffeine',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Caffeine/c/FS-HT-V212?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FCaffeine&sort=trending&page=0',
                          },
                          {
                            text: 'Heart Health',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Heart-Health/c/FS-HT-V206?nav=&sort=trending&page=0',
                          },
                          {
                            text: "Women's Health",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Women%2527s-Health/c/FS-HT-V214?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FWomensHealth&sort=trending&page=0',
                          },
                          {
                            text: "Men's Health",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/Men%2527s-Health/c/FS-HT-V213?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMensHealth&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Supplements',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Supplements/c/FS-HT-V200?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FSupplements&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '31Dq4KhuYxltCVKRJqzYCH',
                    id: '31Dq4KhuYxltCVKRJqzYCH',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Vitamins & Supplements', 'Mineral Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6gmSU6dzUSb4h6i7RNKGEt',
                        id: '6gmSU6dzUSb4h6i7RNKGEt',
                        l4Data: [
                          {
                            text: 'Calcium',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/Calcium/c/FS-HT-V301?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FCalcium&sort=trending&page=0',
                          },
                          {
                            text: 'Iron',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/Iron/c/FS-HT-V302?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FIron&sort=trending&page=0',
                          },
                          {
                            text: 'Magnesium',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/Magnesium/c/FS-HT-V303?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMagnesium&sort=trending&page=0',
                          },
                          {
                            text: 'Potassium',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/Potassium/c/FS-HT-V304?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FPotassium&sort=trending&page=0',
                          },
                          {
                            text: 'Zinc',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/Zinc/c/FS-HT-V305?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FZinc&sort=trending&page=0',
                          },
                          {
                            text: 'Other Minerals',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/Other-Minerals/c/FS-HT-V306?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FOtherMinerals&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Mineral Supplements',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/c/FS-HT-V300?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMineralSupplements&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6Eiy54JZrKeBtiHFH9weji',
                    id: '6Eiy54JZrKeBtiHFH9weji',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Vitamins & Supplements', 'Nutrition & Weight Management'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6hvFvr09w5gR6Yv8ckvlLS',
                        id: '6hvFvr09w5gR6Yv8ckvlLS',
                        l4Data: [
                          {
                            text: 'Protein Bars & Snacks',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/Protein-Bars-%2526-Snacks/c/FS-HT-V401?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FProteinBarsAndSnacks&sort=trending&page=0',
                          },
                          {
                            text: 'Sweeteners',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/Sweeteners/c/FS-HT-V402?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FSweeteners&sort=trending&page=0',
                          },
                          {
                            text: 'Protein Liquid & Powder',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/Protein-Liquid-%2526-Powder/c/FS-HT-V403?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FProteinLiquidAndPowder&sort=trending&page=0',
                          },
                          {
                            text: 'Meal Replacements',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/Meal-Replacements/c/FS-HT-V404?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMealReplacements&sort=trending&page=0',
                          },
                          {
                            text: 'Appetite Control',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/Appetite-Control/c/FS-HT-V405?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FAppetiteControl&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Nutrition & Weight Management',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/c/FS-HT-V400?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMineralSupplements&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7G3yWqiEF96kr06nbAv6IS',
                    id: '7G3yWqiEF96kr06nbAv6IS',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/the-wellness-shop/misc-site-assets/46_navigation-feature-tile---for-masthead-menu/wkxx_featurecollecctiontile_thewellnessshop_sdm_en.png',
                      },
                      callToAction: {
                        text: 'Shop The Wellness Shop',
                        url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Health', 'Sexual Wellness & Family Planning'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3M0szZJ5DonKeWfATcWmLk',
                id: '3M0szZJ5DonKeWfATcWmLk',
                callToAction: {
                  text: 'Sexual Wellness & Family Planning',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/c/FS-HT-SexualWelnessAndFamilyPlanning?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3usnIhLAVgagCMpdWM8Mtb',
                    id: '3usnIhLAVgagCMpdWM8Mtb',
                    l3Data: [
                      {
                        hierarchy: [
                          'Health',
                          'Sexual Wellness & Family Planning',
                          'Shop All Sexual Wellness & Family Planning',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '25MJ56rA2CbYg7OaIuuffE',
                        id: '25MJ56rA2CbYg7OaIuuffE',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Sexual Wellness & Family Planning',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/health/sexual-wellness-and-family-planning/c/FS-HT-SexualWelnessAndFamilyPlanning?nav=%2Fshop%2FCategories%2FSexualWelnessAndFamilyPlanning&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Sexual Wellness & Family Planning', 'Sexual Wellness'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7LyJknFgwxdmD2SL3iRoIg',
                        id: '7LyJknFgwxdmD2SL3iRoIg',
                        l4Data: [
                          {
                            text: 'Lubricants & Intimate Massage Oils',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Sexual-Wellness/Lubricants-%2526-Intimate-Massage-Oils/c/FS-HT-S103?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FLubricantsAndIntimateMassageOils&sort=trending&page=0',
                          },
                          {
                            text: 'Sex Toys',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Sexual-Wellness/Sex-Toys/c/FS-HT-S104?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FLubricantsAndIntimateMassageOils&sort=trending&page=0',
                          },
                          {
                            text: 'Condoms',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Sexual-Wellness/Condoms/c/FS-HT-S101?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FCondoms&sort=trending&page=0',
                          },
                          {
                            text: 'Contraceptives',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Sexual-Wellness/Contraceptives/c/FS-HT-S102?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FContraceptives&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Sexual Wellness',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Sexual-Wellness/c/FS-HT-S100?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FSexualWellness&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3mws7xqOuCWFQktF3Dt81T',
                    id: '3mws7xqOuCWFQktF3Dt81T',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Sexual Wellness & Family Planning', 'Family Planning'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5c356zKnx2sTEclJl5pcqk',
                        id: '5c356zKnx2sTEclJl5pcqk',
                        l4Data: [
                          {
                            text: 'Ovulation Kits & Tests',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Family-Planning/Ovulation-Kits-%2526-Tests/c/FS-HT-S202?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FOvulationKitsAndTests&sort=trending&page=0',
                          },
                          {
                            text: 'Pregnancy Tests',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Family-Planning/Pregnancy-Tests/c/FS-HT-S201?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FPregnancyTests&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Family Planning',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Sexual-Wellness-%2526-Family-Planning/Family-Planning/c/FS-HT-S200?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FFamilyPlanning&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7G3yWqiEF96kr06nbAv6IS',
                    id: '7G3yWqiEF96kr06nbAv6IS',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/the-wellness-shop/misc-site-assets/46_navigation-feature-tile---for-masthead-menu/wkxx_featurecollecctiontile_thewellnessshop_sdm_en.png',
                      },
                      callToAction: {
                        text: 'Shop The Wellness Shop',
                        url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Health', 'Eye, Ear & Foot Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5h90rBKUrfgFHkrlT4YXGb',
                id: '5h90rBKUrfgFHkrlT4YXGb',
                callToAction: {
                  text: 'Eye, Ear & Foot Care',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Eye%252C-Ear-%2526-Foot-Care/c/FS-HT-EyeEarAndFootCare?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3Ax2lHLtX7TO9ukJoYeiTr',
                    id: '3Ax2lHLtX7TO9ukJoYeiTr',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Eye, Ear & Foot Care', 'Shop All Eye, Ear & Foot Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3ihzTSyxO0AxCNvJ40nsBh',
                        id: '3ihzTSyxO0AxCNvJ40nsBh',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Eye, Ear & Foot Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/health/eye-ear-and-foot-care/c/FS-HT-EyeEarAndFootCare?nav=%2Fshop%2FCategories%2FEyeEarAndFootCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Eye, Ear & Foot Care', 'Eye Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '9qI9CgKYcjEwtH9UgLsiA',
                        id: '9qI9CgKYcjEwtH9UgLsiA',
                        l4Data: [
                          {
                            text: 'Eye Drops & Lubricants',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/EyeDropsAndLubricants/c/FS-HT-E102?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEyeDropsAndLubricants&sort=trending&page=0',
                          },
                          {
                            text: 'Eye Care Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/EyeCareAccessories/c/FS-HT-E104?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEyeCareAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Eye Health Supplements',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/EyeHealthSupplements/c/FS-HT-E105?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEyeHealthSupplements&sort=trending&page=0',
                          },
                          {
                            text: 'Contact Lens Solution',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/ContactLensSolution/c/FS-HT-E101?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FContactLensSolution&sort=trending&page=0',
                          },
                          {
                            text: 'Eyeglasses',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/Eyeglasses/c/FS-HT-E103?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEarDrops&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Eye Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Eye%252C-Ear-%2526-Foot-Care/Eye-Care/c/FS-HT-E100?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEyeCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6f0RdZ63mJ5kJp50WQMkNT',
                    id: '6f0RdZ63mJ5kJp50WQMkNT',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Eye, Ear & Foot Care', 'Ear Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '179dSm2p1a72cvCzkW5VGW',
                        id: '179dSm2p1a72cvCzkW5VGW',
                        l4Data: [
                          {
                            text: 'Ear Drops',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/EarDrops/c/FS-HT-E201?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEarDrops&sort=trending&page=0',
                          },
                          {
                            text: 'Ear Plugs',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/EarPlugs/c/FS-HT-E202?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEarPlugs&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Ear Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Eye%252C-Ear-%2526-Foot-Care/Ear-Care/c/FS-HT-E200?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEarCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2KoExuOTxkMEavPB14WfPu',
                    id: '2KoExuOTxkMEavPB14WfPu',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Eye, Ear & Foot Care', 'Foot Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'u5eRah0pXynwPdAr0WlTT',
                        id: 'u5eRah0pXynwPdAr0WlTT',
                        l4Data: [
                          {
                            text: 'Compression Socks',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/CompressionSocks/c/FS-HT-E301?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FCompressionSocks&sort=trending&page=0',
                          },
                          {
                            text: 'Inserts, Insoles & Cushions',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/InsertsInsolesAndCushions/c/FS-HT-E302?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FInsertsInsolesAndCushions&sort=trending&page=0',
                          },
                          {
                            text: "Athlete's Foot & Antifungal",
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/AthletesFootAndAntifungal/c/FS-HT-E303?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FAthletesFootAndAntifungal&sort=trending&page=0',
                          },
                          {
                            text: 'Wart Removal',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/WartRemoval/c/FS-HT-E304?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FWartRemoval&sort=trending&page=0',
                          },
                          {
                            text: 'Foot Creams & Treatments',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/FootCreamsAndTreatments/c/FS-HT-E305?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FFootCreamsAndTreatments&sort=trending&page=0',
                          },
                          {
                            text: 'Odor & Wetness Control',
                            url: 'https://shop.shoppersdrugmart.ca/shop/Categories/EyeEarAndFootCare/OdorAndWetnessControl/c/FS-HT-E307?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FOdorAndWetnessControl&sort=trending&page=0',
                          },
                          {
                            text: 'Diabetes Care',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Medicine-%2526-Treatments/Diabetes-Care/c/FS-HT-M600?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDiabeticCare&sort=trending&page=0',
                          },
                          {
                            text: 'Foot Care Tools',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/EyeEarAndFootCare/FootCareTools/c/FS-HT-E309?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FFootCareTools&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Foot Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Eye%252C-Ear-%2526-Foot-Care/Foot-Care/c/FS-HT-E300?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FFootCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6HUf8LrtUN6SwAPJ8Ke12R',
                    id: '6HUf8LrtUN6SwAPJ8Ke12R',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Eye, Ear & Foot Care', 'Swim Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'zwhDMubuxAhLR0pP0a3tf',
                        id: 'zwhDMubuxAhLR0pP0a3tf',
                        l4Data: [],
                        callToAction: {
                          text: 'Swim Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Eye%252C-Ear-%2526-Foot-Care/Swim-Accessories/c/FS-HT-E500?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FSwimAccessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7G3yWqiEF96kr06nbAv6IS',
                    id: '7G3yWqiEF96kr06nbAv6IS',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/the-wellness-shop/misc-site-assets/46_navigation-feature-tile---for-masthead-menu/wkxx_featurecollecctiontile_thewellnessshop_sdm_en.png',
                      },
                      callToAction: {
                        text: 'Shop The Wellness Shop',
                        url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Health', 'Home Health Care & Fitness'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: 'bMGmoyK4j5mfu63SK602w',
                id: 'bMGmoyK4j5mfu63SK602w',
                callToAction: {
                  text: 'Home Health Care & Fitness',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/c/FS-HT-HomeHealthCare?nav=%252Fshop%252FCategories%252FHomeHealthCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3Bwdb83osUtjMAx1DkXa0F',
                    id: '3Bwdb83osUtjMAx1DkXa0F',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Shop All Home Health Care & Fitness'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2iTdzNOKFy4vkW0ShclnTK',
                        id: '2iTdzNOKFy4vkW0ShclnTK',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Home Health Care & Fitness',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/health/home-health-care-and-fitness/c/FS-HT-HomeHealthCare?nav=%2Fshop%2FCategories%2FHomeHealthCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Compression Hosiery & Stockings'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '61cp8GotkLEjNa7UaDrtYy',
                        id: '61cp8GotkLEjNa7UaDrtYy',
                        l4Data: [],
                        callToAction: {
                          text: 'Compression Hosiery & Stockings',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Compression-Hosiery-%2526-Stockings/c/FS-HT-H100?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FCompressionHosieryAndStockings&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Scales, Pulse Oximeter & Pedometers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6HMBAypiv22MCRM6v96hOe',
                        id: '6HMBAypiv22MCRM6v96hOe',
                        l4Data: [],
                        callToAction: {
                          text: 'Scales, Pulse Oximeter & Pedometers',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Scales%252C-Pulse-Oximeter-%2526-Pedometers/c/FS-HT-H300?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FScalesPulseOximeterAndPedometers&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '32lDLYQjyDdC6jl5akXb3R',
                    id: '32lDLYQjyDdC6jl5akXb3R',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Body Wellness & Massagers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'F2sJ3eN9AP4E7sVQvKkrK',
                        id: 'F2sJ3eN9AP4E7sVQvKkrK',
                        l4Data: [],
                        callToAction: {
                          text: 'Body Wellness & Massagers',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Body-Wellness-%2526-Massagers/c/FS-HT-H500?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FBodyWellnessAndMassagers&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Bathroom Safety'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6JpMhKbdKe8FsvQMS2nJQd',
                        id: '6JpMhKbdKe8FsvQMS2nJQd',
                        l4Data: [],
                        callToAction: {
                          text: 'Bathroom Safety',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Bathroom-Safety/c/FS-HT-H600?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FBathroomSafety&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '30J7oqdYqqZK8cli9wD65h',
                    id: '30J7oqdYqqZK8cli9wD65h',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Blood Pressure Monitors'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'yqeVv4d68l9bahkzls7it',
                        id: 'yqeVv4d68l9bahkzls7it',
                        l4Data: [],
                        callToAction: {
                          text: 'Blood Pressure Monitors',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Blood-Pressure-Monitors/c/FS-HT-H400?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FBloodPressureMonitors&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Cane & Crutches'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7mhCreJyl44L09nEtIAZ8y',
                        id: '7mhCreJyl44L09nEtIAZ8y',
                        l4Data: [],
                        callToAction: {
                          text: 'Cane & Crutches',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Cane-%2526-Crutches/c/FS-HT-H200?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FCaneAndCrutches&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7Mu5wetNaFsPGxmh9Eqiw5',
                    id: '7Mu5wetNaFsPGxmh9Eqiw5',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Home Health Care & Fitness', 'Fitness Equipment & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'ddk64RfZjj3AZvu7oeQTM',
                        id: 'ddk64RfZjj3AZvu7oeQTM',
                        l4Data: [],
                        callToAction: {
                          text: 'Fitness Equipment & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Health/Home-Health-Care-%2526-Fitness/Fitness-Equipment-%2526-Accessories/c/FS-HT-H700?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FFitnessEquipmentAndAccessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7G3yWqiEF96kr06nbAv6IS',
                    id: '7G3yWqiEF96kr06nbAv6IS',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/the-wellness-shop/misc-site-assets/46_navigation-feature-tile---for-masthead-menu/wkxx_featurecollecctiontile_thewellnessshop_sdm_en.png',
                      },
                      callToAction: {
                        text: 'Shop The Wellness Shop',
                        url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Health', 'Shop The Wellness Shop'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7t60FULzFPCm1QMZ4oQjrj',
                id: '7t60FULzFPCm1QMZ4oQjrj',
                callToAction: {
                  text: 'Shop The Wellness Shop',
                  url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3DaIsAnYUY9D2jxlTg0V52',
                    id: '3DaIsAnYUY9D2jxlTg0V52',
                    l3Data: [
                      {
                        hierarchy: ['Health', 'Shop The Wellness Shop', 'The Wellness Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7zt9WspnfFdBLtPoXd5DMd',
                        id: '7zt9WspnfFdBLtPoXd5DMd',
                        l4Data: [],
                        callToAction: {
                          text: 'The Wellness Shop',
                          url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Shop The Wellness Shop', 'Vitamins & Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '75BXLDW11oBLbGjp7SF8Lh',
                        id: '75BXLDW11oBLbGjp7SF8Lh',
                        l4Data: [],
                        callToAction: {
                          text: 'Vitamins & Supplements',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/vitamins-&-supplements/c/57140?nav=/shop/vitaminssupplements',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Shop The Wellness Shop', 'Fitness & Exercise'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2oHx5s7dyohAnqVYYlZa8L',
                        id: '2oHx5s7dyohAnqVYYlZa8L',
                        l4Data: [],
                        callToAction: {
                          text: 'Fitness & Exercise',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSFitnessExercise?nav=/shop/fitnessexercise',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Shop The Wellness Shop', 'Sexual Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'BOxR39xvthelu7JN2OZaj',
                        id: 'BOxR39xvthelu7JN2OZaj',
                        l4Data: [],
                        callToAction: {
                          text: 'Sexual Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSexualHealth?nav=/shop/sexualhealth',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Shop The Wellness Shop', 'Sleep Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6yeDjwOjQMM17gIGRqfGGS',
                        id: '6yeDjwOjQMM17gIGRqfGGS',
                        l4Data: [],
                        callToAction: {
                          text: 'Sleep Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSleepHealth?nav=/shop/sleephealth',
                        },
                      },
                      {
                        hierarchy: ['Health', 'Shop The Wellness Shop', 'Relaxation & Stress Relief'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2KbbUZLAQyaOxmPcedYCcd',
                        id: '2KbbUZLAQyaOxmPcedYCcd',
                        l4Data: [],
                        callToAction: {
                          text: 'Relaxation & Stress Relief',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSRelaxationStress?nav=/shop/relaxationstress',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: 'hxOl6SC0mTzjwYJzOIg2w',
                    id: 'hxOl6SC0mTzjwYJzOIg2w',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk25/health/wk25_featurecollecctiontile_webber_enfr.png',
                      },
                      callToAction: {
                        text: 'Vitamins & Supplements by Webber',
                        url: 'https://shop.shoppersdrugmart.ca/c/FS-HT-VitaminsAndSupplements?page=0&q=%3Atrending%3AshowInStock%3Atrue%3AbrandName%3AWebber&sort=trending',
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '1Tko5yaQn7wqjXHhHSHDvQ',
          id: '1Tko5yaQn7wqjXHhHSHDvQ',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '1Tko5yaQn7wqjXHhHSHDvQ',
            id: '1Tko5yaQn7wqjXHhHSHDvQ',
            callToAction: {
              text: 'Baby & Child',
              url: 'https://shop.shoppersdrugmart.ca/Baby-and-child?nav=/shop/babyandchild',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Baby & Child', 'Shop All Baby & Child'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3XbU8uEa1EeYV8aYwuoAwd',
                id: '3XbU8uEa1EeYV8aYwuoAwd',
                callToAction: {
                  text: 'Shop All Baby & Child',
                  url: 'https://shop.shoppersdrugmart.ca/Baby-and-child?nav=/shop/babyandchild',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Baby & Child', 'Baby & Child Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '26W2dPO7RLT4NqjuuVdEar',
                id: '26W2dPO7RLT4NqjuuVdEar',
                callToAction: {
                  text: 'Baby & Child Care',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Bath-%2526-Body-Care/c/FS-BC-B200?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBathAndBodyCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3jfLHfdDvB4z7LYYzwTixV',
                    id: '3jfLHfdDvB4z7LYYzwTixV',
                    l3Data: [
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Shop All Baby & Child Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4tsqcbvylTvhM3vRNeXw6G',
                        id: '4tsqcbvylTvhM3vRNeXw6G',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Baby & Child Care',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/baby-and-child/baby-and-child-care/bath-and-body-care/c/FS-BC-B200?nav=%2Fshop%2FCategories%2FBabyAndChildCare%2FBathAndBodyCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Diapers & Wipes'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5hj31jy12TkfT55NIr0Hnq',
                        id: '5hj31jy12TkfT55NIr0Hnq',
                        l4Data: [
                          {
                            text: 'Diapers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/Diapers/c/FS-BC-B105?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FDiapers&sort=trending&page=0',
                          },
                          {
                            text: 'Training Pants',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/Training-Pants/c/FS-BC-B101?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FTrainingPants&sort=trending&page=0',
                          },
                          {
                            text: 'Diaper Rash Treatment',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/Diaper-Rash-Treatment/c/FS-BC-B102?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FDiaperRashTreatment&sort=trending&page=0',
                          },
                          {
                            text: 'Diaper Pails Refills',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/Diaper-Pails-Refills-/c/FS-BC-B104?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChangingAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Wipes',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/Baby-Wipes/c/FS-BC-B103?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyWipes&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Diapers & Wipes',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/c/FS-BC-B100?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FDiapersAndWipes&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '36pLnqWyHTmhNAZP2Jjgzv',
                    id: '36pLnqWyHTmhNAZP2Jjgzv',
                    l3Data: [
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Bath & Body Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5G7NZ0vRsgjDfS57Zm4qjr',
                        id: '5G7NZ0vRsgjDfS57Zm4qjr',
                        l4Data: [
                          {
                            text: 'Body Creams & Lotions',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Bath-%2526-Body-Care/Body-Creams-%2526-Lotions/c/FS-BC-B206?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBodyCreamsAndLotions&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Bath & Shampoos',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Bath-%2526-Body-Care/Baby-Bath-%2526-Shampoos/c/FS-BC-B201?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyBathAndShampoos&sort=trending&page=0',
                          },
                          {
                            text: 'Cotton Swabs',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Bath-%2526-Body-Care/Cotton-Swabs/c/FS-BC-B202?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FCottonSwabs&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Oil',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Bath-%2526-Body-Care/Baby-Oil/c/FS-BC-B203?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyOil&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Powders',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Bath-%2526-Body-Care/Baby-Powders/c/FS-BC-B204?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyPowders&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Bath & Body Care',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/c/FS-BC-BabyCare?nav=%252Fshop%252FCategories%252FBabyAndChildCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '31R8WwoOdECMYrkrAoEM1t',
                    id: '31R8WwoOdECMYrkrAoEM1t',
                    l3Data: [
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', "Children's Health Care"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7LWfr1QIBHB97ntYXP8dyJ',
                        id: '7LWfr1QIBHB97ntYXP8dyJ',
                        l4Data: [
                          {
                            text: "Children's Cough Cold & Flu",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Children%2527s-Health-Care/Children%2527s-Cough-Cold-%2526-Flu/c/FS-BC-B302?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildrensCoughColdAndFlu&sort=trending&page=0',
                          },
                          {
                            text: "Children's Antihistamines",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Children%2527s-Health-Care/Children%2527s-Antihistamines/c/FS-BC-B303?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildrensAntihistamines&sort=trending&page=0',
                          },
                          {
                            text: "Children's Pain & Fever Relief",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Children%2527s-Health-Care/Children%2527s-Pain-%2526-Fever-Relief/c/FS-BC-B301?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildrensPainAndFeverRelief&sort=trending&page=0',
                          },
                          {
                            text: "Children's Multivitamins & Vitamins",
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Children%2527s-Health-Care/Children%2527s-Multivitamins-%2526-Vitamins/c/FS-BC-B304?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyAndChildrensSupplements&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: "Children's Health Care",
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Children%2527s-Health-Care/c/FS-BC-B300?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildrensHealthCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4JunWbQYDk4cQZVJWKF03U',
                    id: '4JunWbQYDk4cQZVJWKF03U',
                    l3Data: [
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Childproofing & Safety'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '20y8A0N0OzTdB73osDEjlY',
                        id: '20y8A0N0OzTdB73osDEjlY',
                        l4Data: [],
                        callToAction: {
                          text: 'Childproofing & Safety',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Childproofing-%2526-Safety/c/FS-BC-B400?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildproofingAndSafety&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Toys'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '31ZOEYkGuS3Q6XAue0WxlR',
                        id: '31ZOEYkGuS3Q6XAue0WxlR',
                        l4Data: [],
                        callToAction: {
                          text: 'Toys',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Toys/c/FS-BC-B500?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FToys&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Clothing & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4lrMnLSJJgBpT59xK6KqK2',
                        id: '4lrMnLSJJgBpT59xK6KqK2',
                        l4Data: [],
                        callToAction: {
                          text: 'Clothing & Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Clothing-%2526-Accessories/c/FS-BC-B600?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FClothingAndAccessories&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Baby & Child', 'Baby & Child Care', 'Baby Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '11q6pMD4ncbkEp9hhVJNoL',
                        id: '11q6pMD4ncbkEp9hhVJNoL',
                        l4Data: [],
                        callToAction: {
                          text: 'Baby Gifts & Sets',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Baby-Gifts-%2526-Sets/c/FS-BC-B700?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyGiftsAndSets&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Baby & Child', 'Feeding & Formula'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4thHVqdjvQNsINUBCM4WfG',
                id: '4thHVqdjvQNsINUBCM4WfG',
                callToAction: {
                  text: 'Feeding & Formula',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/c/FS-BC-BabyFeeding?nav=%252Fshop%252FCategories%252FBabyAndChildCare&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6C4Zz8t2DJbacD2PCzt4xj',
                    id: '6C4Zz8t2DJbacD2PCzt4xj',
                    l3Data: [
                      {
                        hierarchy: ['Baby & Child', 'Feeding & Formula', 'Shop All Feeding & Formula'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6fGHBUKGE141eb991nYs6r',
                        id: '6fGHBUKGE141eb991nYs6r',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Feeding & Formula',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/baby-and-child/feeding-and-formula/c/FS-BC-BabyFeeding?nav=%2Fshop%2FCategories%2FBabyAndChildCare&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Baby & Child', 'Feeding & Formula', 'Baby Food & Formula'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3ANOGNXpIjiv6uDlOL9DO2',
                        id: '3ANOGNXpIjiv6uDlOL9DO2',
                        l4Data: [
                          {
                            text: 'Formula',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Baby-Food-%2526-Formula/Formula/c/FS-BC-F101?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FFormula&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Food & Toddler Snacks',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Baby-Food-%2526-Formula/Baby-Food-%2526-Toddler-Snacks/c/FS-BC-F102?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FBabyFood&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Cereal',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Baby-Food-%2526-Formula/Baby-Cereal/c/FS-BC-F105?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FBabyCereal&sort=trending&page=0',
                          },
                          {
                            text: 'Pediatric Nutrition',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Baby-Food-%2526-Formula/Pediatric-Nutrition/c/FS-BC-F103?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FPediatricNutrition&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Baby Food & Formula',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Baby-Food-%2526-Formula/c/FS-BC-F100?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FBabyFoodAndFormula&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4aIba0yPj7EMrx4IT5D1Iq',
                    id: '4aIba0yPj7EMrx4IT5D1Iq',
                    l3Data: [
                      {
                        hierarchy: ['Baby & Child', 'Feeding & Formula', 'Nursing & Mealtime'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6gmd9YvcgrZYnYW3X3Q6Fz',
                        id: '6gmd9YvcgrZYnYW3X3Q6Fz',
                        l4Data: [
                          {
                            text: 'Breastfeeding Essentials',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Nursing-%2526-Mealtime/Breastfeeding-Essentials/c/FS-BC-F201?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FBreastfeedingEssentials&sort=trending&page=0',
                          },
                          {
                            text: 'Toddler Feeding Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Nursing-%2526-Mealtime/Toddler-Feeding-Accessories/c/FS-BC-F202?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FToddlerFeedingAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Baby Bottles & Nipples',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Nursing-%2526-Mealtime/Baby-Bottles-%2526-Nipples/c/FS-BC-F203?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FBabyBottlesAndNipples&sort=trending&page=0',
                          },
                          {
                            text: 'Pacifier & Teething',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Nursing-%2526-Mealtime/Pacifier-%2526-Teething-/c/FS-BC-F204?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FPacifier&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Nursing & Mealtime',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Feeding-%2526-Formula/Nursing-%2526-Mealtime/c/FS-BC-F200?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FNurisngAndMealtime&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Baby & Child', 'Feeding & Formula', 'New Infant Formula'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3R1wfXlUHpBYrnmKZgQQ2i',
                        id: '3R1wfXlUHpBYrnmKZgQQ2i',
                        l4Data: [],
                        callToAction: {
                          text: 'New Infant Formula',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Cat%C3%A9gories/B%C3%A9b%C3%A9-et-enfant/Alimentation-et-pr%C3%A9parations/Allaitement-et-heure-du-repas/Suces-et-jouets-de-dentition/c/FS-BC-F204?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FPacifier&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Baby & Child', 'Toys'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7JOnv3KNThGoA9j3NMELWj',
                id: '7JOnv3KNThGoA9j3NMELWj',
                callToAction: {
                  text: 'Toys',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Baby-%2526-Child/Baby-%2526-Child-Care/Toys/c/FS-BC-B500?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FToys&sort=trending&page=0',
                },
                columns: [],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '2sfjpIXCInqheZCrpdhsjk',
          id: '2sfjpIXCInqheZCrpdhsjk',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '2sfjpIXCInqheZCrpdhsjk',
            id: '2sfjpIXCInqheZCrpdhsjk',
            callToAction: {
              text: 'Home & Electronics',
              url: 'https://shop.shoppersdrugmart.ca/Electronics',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Home & Electronics', 'Shop All Home & Electronics'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7GOjmSCWzCItzJxdn0629t',
                id: '7GOjmSCWzCItzJxdn0629t',
                callToAction: {
                  text: 'Shop All Home & Electronics',
                  url: 'https://shop.shoppersdrugmart.ca/Electronics?nav=/shop/homeandelectronics',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Home & Electronics', 'Electronics'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '38Dqm5ZjSCEBSIw0zHoh1f',
                id: '38Dqm5ZjSCEBSIw0zHoh1f',
                callToAction: {
                  text: 'Electronics',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/c/FS-EL-Electronics?nav=%252Fshop%252FCategories%252FElectronics&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5SqFypgRGJYUMDLO9MBwUA',
                    id: '5SqFypgRGJYUMDLO9MBwUA',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Electronics', 'Shop All Electronics'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3bDDLGD4YPbgtSFUfv1dz4',
                        id: '3bDDLGD4YPbgtSFUfv1dz4',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Electronics',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/home-and-electronics/electronics/c/FS-EL-Electronics?nav=%2Fshop%2FCategories%2FElectronics&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Home & Electronics', 'Electronics', 'Audio'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4OMAqi5fhq0aLNJDheiWrv',
                        id: '4OMAqi5fhq0aLNJDheiWrv',
                        l4Data: [
                          {
                            text: 'Headphones & Earbuds',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Other-Electronics/Audio/Headphones-%2526-Earbuds/c/FS-EL-403?nav=%252Fshop%252FCategories%252FElectronics%252FAudio%252FHeadphonesAndEarbuds&sort=trending&page=0',
                          },
                          {
                            text: 'Speakers',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Other-Electronics/Audio/Speakers/c/FS-EL-306?nav=%252Fshop%252FCategories%252FElectronics%252FAudio%252FSpeakers&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Audio',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Other-Electronics/Audio/c/FS-EL-301?nav=%252Fshop%252FCategories%252FElectronics%252FAudio&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Home & Electronics', 'Electronics', 'Batteries'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4pYxDsF9BVS6eZu6gXaYpJ',
                        id: '4pYxDsF9BVS6eZu6gXaYpJ',
                        l4Data: [
                          {
                            text: 'Specialty Batteries',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Batteries/Specialty-Batteries/c/FS-EL-103?nav=%252Fshop%252FCategories%252FElectronics%252FBatteries%252FSpecialtyBatteries&sort=trending&page=0',
                          },
                          {
                            text: 'Alkaline Batteries',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Batteries/Alkaline-Batteries/c/FS-EL-101?nav=%252Fshop%252FCategories%252FElectronics%252FBatteries%252FAlkalineBatteries&sort=trending&page=0',
                          },
                          {
                            text: 'Hearing Aid Batteries',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Batteries/Hearing-Aids-Batteries/c/FS-EL-102?nav=%252Fshop%252FCategories%252FElectronics%252FBatteriesHearingAidBatteries&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Batteries',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Batteries/c/FS-EL-100?nav=%252Fshop%252FCategories%252FElectronics%252FBatteries&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4eSavSBEmLw008bQqtY1kb',
                    id: '4eSavSBEmLw008bQqtY1kb',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Electronics', 'Cameras & Phone Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4uhKJ6F77FpyKrOiaVOvkt',
                        id: '4uhKJ6F77FpyKrOiaVOvkt',
                        l4Data: [
                          {
                            text: 'Cameras & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Cameras%252C-Phones-%2526-Accessories/Cameras-%2526-Accessories/c/FS-EL-401?nav=%252Fshop%252FCategories%252FElectronics%252FCamerasAndPhoneAccessories%252FCamerasAndAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Phones & Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Cameras%252C-Phones-%2526-Accessories/Phones-%2526-Accessories/c/FS-EL-404?nav=%252Fshop%252FCategories%252FElectronics%252FCamerasAndPhoneAccessories%252FPhoneAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Memory Cards',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Cameras%252C-Phones-%2526-Accessories/Memory-Cards/c/FS-EL-402?nav=%252Fshop%252FCategories%252FElectronics%252FCamerasAndPhoneAccessories%252FMemorycards&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Cameras & Phone Accessories',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Cameras%252C-Phones-%2526-Accessories/c/FS-EL-400?nav=%252Fshop%252FCategories%252FElectronics%252FCamerasAndPhoneAccessories&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '49gQFgAI1JhKqwsIkVPvTB',
                    id: '49gQFgAI1JhKqwsIkVPvTB',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Electronics', 'Computers & Smart Tech'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2Y5av0kUn8ZQUZJ6CNBsyN',
                        id: '2Y5av0kUn8ZQUZJ6CNBsyN',
                        l4Data: [
                          {
                            text: 'Connected Home',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/Connected-Home/c/FS-EL-501?nav=%252Fshop%252FCategories%252FElectronics%252FComputersAndSmartTech%252FConnectedhome&sort=trending&page=0',
                          },
                          {
                            text: 'Notebooks',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/Notebooks/c/FS-EL-502?nav=%252Fshop%252FCategories%252FElectronics%252FComputersAndSmartTech%252FNotebooks&sort=trending&page=0',
                          },
                          {
                            text: 'Tablets & EReaders',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/Tablets-%2526-eReaders/c/FS-EL-503?sort=trending&page=0',
                          },
                          {
                            text: 'Computer Accessories & Software',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/Computer-Accessories-%2526-Software/c/FS-EL-304?nav=%252Fshop%252FCategories%252FElectronics%252FComputerAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Smart Watches & GPS',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/Smart-Watches-%2526-GPS/c/FS-EL-505?nav=%252Fshop%252FCategories%252FElectronics%252FSmartWatchesAndGPS&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Computers & Smart Tech',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/c/FS-EL-500?nav=%252Fshop%252FCategories%252FElectronics%252FComputersAndSmartTech&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5kyZMjRqjmGu54LIaN0ql8',
                    id: '5kyZMjRqjmGu54LIaN0ql8',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Electronics', 'Video Games'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6014zjHS662UzDoT0emvkU',
                        id: '6014zjHS662UzDoT0emvkU',
                        l4Data: [
                          {
                            text: 'Consoles & Gaming Accessories',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Video-Games/Consoles-%2526-Gaming-Accessories/c/FS-EL-201?nav=%252Fshop%252FCategories%252FElectronics%252FVideoGames%252FConsolesAndGamingAccessories&sort=trending&page=0',
                          },
                          {
                            text: 'Games',
                            url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Video-Games/Games/c/FS-EL-202?nav=%252Fshop%252FCategories%252FElectronics%252FVideoGames%252FGames&sort=trending&page=0',
                          },
                        ],
                        callToAction: {
                          text: 'Video Games',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Electronics/Video-Games/c/FS-EL-200?nav=%252Fshop%252FCategories%252FElectronics%252FVideoGames&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Home & Electronics', 'Home & Lifestyle'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7IQKoxIyxtEC4WeufFBJZU',
                id: '7IQKoxIyxtEC4WeufFBJZU',
                callToAction: {
                  text: 'Home & Lifestyle',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Home-%2526-Lifestyle/c/FS-EL-Homeware?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '58IkUFWCKln5OcSzTTNooE',
                    id: '58IkUFWCKln5OcSzTTNooE',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Home & Lifestyle', 'Shop All Home & Lifestyle'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '19NL6InEo4U7wVeVjwLBKQ',
                        id: '19NL6InEo4U7wVeVjwLBKQ',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Home & Lifestyle',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/home-and-electronics/home-and-lifestyle/c/FS-EL-Homeware?nav=%2Fshop%2FCategories%2FHomeAndElectronics%2FHomeware&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Home & Electronics', 'Home & Lifestyle', 'Candles'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4ZOeSOc65jB5fUGRSDXtkc',
                        id: '4ZOeSOc65jB5fUGRSDXtkc',
                        l4Data: [],
                        callToAction: {
                          text: 'Candles',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Candles/c/FS-EL-H200?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FCandles&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2Cwbc9S1HCqoMyW5TVNbvH',
                    id: '2Cwbc9S1HCqoMyW5TVNbvH',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Home & Lifestyle', 'Diffusers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6F6dvuhDwRIiPCzp8D3jQg',
                        id: '6F6dvuhDwRIiPCzp8D3jQg',
                        l4Data: [],
                        callToAction: {
                          text: 'Diffusers',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Diffusers/c/FS-EL-H300?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FDiffusers&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7i3qQCJozVVvizaUXLvXTY',
                    id: '7i3qQCJozVVvizaUXLvXTY',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Home & Lifestyle', 'Greeting Cards'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '53kDB0E6bFeWADoRWuJk6F',
                        id: '53kDB0E6bFeWADoRWuJk6F',
                        l4Data: [],
                        callToAction: {
                          text: 'Greeting Cards',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Greeting-Cards/c/FS-EL-H500?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FGreetingsCards&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6yb9he3fvhIekPNnpnQjv5',
                    id: '6yb9he3fvhIekPNnpnQjv5',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Home & Lifestyle', 'Seasonal Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6DfdkkXlHxGPdnxoTS4eVW',
                        id: '6DfdkkXlHxGPdnxoTS4eVW',
                        l4Data: [],
                        callToAction: {
                          text: 'Seasonal Shop',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Seasonal-Shop/c/FS-EL-H100?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FSeasonalDecor&sort=newest&page=0&lang=en',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Home & Electronics', 'Bedding & Mattresses'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4euI3oYppRsEUAibj4cPtN',
                id: '4euI3oYppRsEUAibj4cPtN',
                callToAction: {
                  text: 'Bedding & Mattresses',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Bedding-%2526-Mattresses/c/FS-EL-Bedding?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FBedding&sort=trending&page=0',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5uLdP2BFlcGyWHjSMvFdwy',
                    id: '5uLdP2BFlcGyWHjSMvFdwy',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Bedding & Mattresses', 'Shop All Bedding & Mattresses'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '59sioQudKEZISiiBaQ9a2g',
                        id: '59sioQudKEZISiiBaQ9a2g',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Bedding & Mattresses',
                          url: 'https://shop.shoppersdrugmart.ca/shop/categories/home-and-electronics/bedding-and-mattresses/c/FS-EL-Bedding?nav=%2Fshop%2FCategories%2FHomeAndElectronics%2FBedding&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Home & Electronics', 'Bedding & Mattresses', 'Mattresses'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7B7mAae87leZhjF97hggBY',
                        id: '7B7mAae87leZhjF97hggBY',
                        l4Data: [],
                        callToAction: {
                          text: 'Mattresses',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Bedding-%2526-Mattresses/Mattresses/c/FS-EL-B100?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FMattresses&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6wTFuGwoBkRArDOLubu75a',
                    id: '6wTFuGwoBkRArDOLubu75a',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Bedding & Mattresses', 'Pillows'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3OgIqQMZbs8d0SJNKzi8Ce',
                        id: '3OgIqQMZbs8d0SJNKzi8Ce',
                        l4Data: [],
                        callToAction: {
                          text: 'Pillows',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Bedding-%2526-Mattresses/Pillows/c/FS-EL-B200?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FPillows&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6uV38AfeSu7pKpULX8E8pp',
                    id: '6uV38AfeSu7pKpULX8E8pp',
                    l3Data: [
                      {
                        hierarchy: ['Home & Electronics', 'Bedding & Mattresses', 'Weighted Blankets & Covers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1hF2wsCtyMz4Zrq7uLLrfy',
                        id: '1hF2wsCtyMz4Zrq7uLLrfy',
                        l4Data: [],
                        callToAction: {
                          text: 'Weighted Blankets & Covers',
                          url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Bedding-%2526-Mattresses/Weighted-Blankets-%2526-Covers/c/FS-EL-B300?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FWeightedBlankets&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Home & Electronics', 'Appliances'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '76m1u6JAiAPLIEGpWFr5t1',
                id: '76m1u6JAiAPLIEGpWFr5t1',
                callToAction: {
                  text: 'Appliances',
                  url: 'https://shop.shoppersdrugmart.ca/Shop/Categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Appliances/c/FS-EL-H600?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FAppliances&sort=trending&page=0',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Home & Electronics', 'Digital Gift Cards'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2aLFKq0wjlrRf2f3gHlkWd',
                id: '2aLFKq0wjlrRf2f3gHlkWd',
                callToAction: {
                  text: 'Digital Gift Cards',
                  url: 'https://shop.shoppersdrugmart.ca/shop/digital-gift-cards/c/DigitalGiftCards?sort=trending&page=0',
                },
                columns: [],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '6V1Rqe9PzWJNq7S81x9Vfj',
          id: '6V1Rqe9PzWJNq7S81x9Vfj',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '6V1Rqe9PzWJNq7S81x9Vfj',
            id: '6V1Rqe9PzWJNq7S81x9Vfj',
            callToAction: {
              text: 'Pharmacy Services',
              url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Pharmacy Services', 'See all Pharmacy Services'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3uSXPkBVYKO7d1h5CKDPnh',
                id: '3uSXPkBVYKO7d1h5CKDPnh',
                callToAction: {
                  text: 'See all Pharmacy Services',
                  url: 'https://shop.shoppersdrugmart.ca/pharmacy-services?lang=en',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Pharmacy Services', 'Vaccinations & Injections '],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '75FcSvTKZb8YsI4F04k2Lk',
                id: '75FcSvTKZb8YsI4F04k2Lk',
                callToAction: {
                  text: 'Vaccinations & Injections ',
                  url: '/',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5fQ04yYhUgua0w9gIVHtYH',
                    id: '5fQ04yYhUgua0w9gIVHtYH',
                    l3Data: [
                      {
                        hierarchy: ['Pharmacy Services', 'Vaccinations & Injections ', 'Adult Vaccinations'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5oHNJ7pr1j9cIE2zZXGBOb',
                        id: '5oHNJ7pr1j9cIE2zZXGBOb',
                        l4Data: [],
                        callToAction: {
                          text: 'Adult Vaccinations',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/adult-vaccinations',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Vaccinations & Injections ', 'Flu Shot'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6bP5c38WFNS9aBcJH4JlyT',
                        id: '6bP5c38WFNS9aBcJH4JlyT',
                        l4Data: [],
                        callToAction: {
                          text: 'Flu Shot',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/flu-shot',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Vaccinations & Injections ', 'COVID-19 Vaccine'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6jrqBE7Lczc0Iz2hnEBhEq',
                        id: '6jrqBE7Lczc0Iz2hnEBhEq',
                        l4Data: [],
                        callToAction: {
                          text: 'COVID-19 Vaccine',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/covid-19-vaccine',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Pharmacy Services', 'Onsite Testing'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7LaVz8Xs02k7b1B0OC75wv',
                id: '7LaVz8Xs02k7b1B0OC75wv',
                callToAction: {
                  text: 'Onsite Testing',
                  url: '/',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2UUjh9VAAsEAycxcDQqMF3',
                    id: '2UUjh9VAAsEAycxcDQqMF3',
                    l3Data: [
                      {
                        hierarchy: ['Pharmacy Services', 'Onsite Testing', 'See all on-site tests'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7GbyQLnzMeaqgCbjCNN6ll',
                        id: '7GbyQLnzMeaqgCbjCNN6ll',
                        l4Data: [],
                        callToAction: {
                          text: 'See all on-site tests',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/on-site-testing',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Onsite Testing', 'COVID-19 Test'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2vUdyxYTzG29gCi56mCuGm',
                        id: '2vUdyxYTzG29gCi56mCuGm',
                        l4Data: [],
                        callToAction: {
                          text: 'COVID-19 Test',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/covid-19-testing',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Pharmacy Services', 'Pharmacist Consultations'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: 'BDJUnhuK0HpZAYdeJBolx',
                id: 'BDJUnhuK0HpZAYdeJBolx',
                callToAction: {
                  text: 'Pharmacist Consultations',
                  url: '/',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'isE7epe5lJ4v6KOoNPexf',
                    id: 'isE7epe5lJ4v6KOoNPexf',
                    l3Data: [
                      {
                        hierarchy: ['Pharmacy Services', 'Pharmacist Consultations', 'Diabetes Management'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2I8gSFDB0esBE9Hzcg3tUk',
                        id: '2I8gSFDB0esBE9Hzcg3tUk',
                        l4Data: [],
                        callToAction: {
                          text: 'Diabetes Management',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/diabetes-medication-reviews',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Pharmacist Consultations', 'Minor Illness Assessment'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1m8Yb2eQuOgzMwOtrP6ROz',
                        id: '1m8Yb2eQuOgzMwOtrP6ROz',
                        l4Data: [],
                        callToAction: {
                          text: 'Minor Illness Assessment',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/minor-illness',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Pharmacist Consultations', 'Travel Health Clinic'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5Nnk9RwNv5BO16GBKuANj7',
                        id: '5Nnk9RwNv5BO16GBKuANj7',
                        l4Data: [],
                        callToAction: {
                          text: 'Travel Health Clinic',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/travel-health?province=ON',
                        },
                      },
                      {
                        hierarchy: [
                          'Pharmacy Services',
                          'Pharmacist Consultations',
                          'Opioid Harm Reduction & Naloxone',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1dtOrvriacNdPHadRqFwER',
                        id: '1dtOrvriacNdPHadRqFwER',
                        l4Data: [],
                        callToAction: {
                          text: 'Opioid Harm Reduction & Naloxone',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/naloxone',
                        },
                      },
                      {
                        hierarchy: [
                          'Pharmacy Services',
                          'Pharmacist Consultations',
                          'Consultation to help quit smoking',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '60AZ8eyGLO9W21Te4bSVnM',
                        id: '60AZ8eyGLO9W21Te4bSVnM',
                        l4Data: [],
                        callToAction: {
                          text: 'Consultation to help quit smoking',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/quit-smoking?province=ON',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Pharmacist Consultations', 'Pharmacist-Led Clinics'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2u2Vg4VQJf8QWgJptl1ooj',
                        id: '2u2Vg4VQJf8QWgJptl1ooj',
                        l4Data: [],
                        callToAction: {
                          text: 'Pharmacist-Led Clinics',
                          url: 'https://www.shoppersdrugmart.ca/en/pharmacist-clinic',
                        },
                      },
                      {
                        hierarchy: [
                          'Pharmacy Services',
                          'Pharmacist Consultations',
                          'Switching to biosimilar medications',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5RaSV2jrch7p3D6pKWsRvt',
                        id: '5RaSV2jrch7p3D6pKWsRvt',
                        l4Data: [],
                        callToAction: {
                          text: 'Switching to biosimilar medications',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/biosimilars',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Pharmacist Consultations', 'UTI Treatment'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2HHKupj1aLT18t18VO8w0e',
                        id: '2HHKupj1aLT18t18VO8w0e',
                        l4Data: [
                          {
                            text: 'UTI Treatment',
                            url: '/pharmacy/pharmacist-consultations/urinary-tract-infection-treatment',
                          },
                        ],
                        callToAction: {
                          text: 'UTI Treatment',
                          url: '/pharmacy/pharmacist-consultations/urinary-tract-infection-treatment',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Pharmacy Services', 'Online Prescription Management'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3qNH4NlaisS4SA64Xo3g9I',
                id: '3qNH4NlaisS4SA64Xo3g9I',
                callToAction: {
                  text: 'Online Prescription Management',
                  url: '/',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5os1P729N7ARgmyLYvg6gK',
                    id: '5os1P729N7ARgmyLYvg6gK',
                    l3Data: [
                      {
                        hierarchy: ['Pharmacy Services', 'Online Prescription Management', 'Refill your prescription'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '55HPBNHE1KXrF0s6GMIGAf',
                        id: '55HPBNHE1KXrF0s6GMIGAf',
                        l4Data: [],
                        callToAction: {
                          text: 'Refill your prescription',
                          url: 'https://mypharmacy.shoppersdrugmart.ca/en',
                        },
                      },
                      {
                        hierarchy: [
                          'Pharmacy Services',
                          'Online Prescription Management',
                          'Transfer your prescription',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6NcvvYoN4CtzWfKUgC4plP',
                        id: '6NcvvYoN4CtzWfKUgC4plP',
                        l4Data: [],
                        callToAction: {
                          text: 'Transfer your prescription',
                          url: 'https://mypharmacy.shoppersdrugmart.ca/en/transfer-prescription',
                        },
                      },
                      {
                        hierarchy: [
                          'Pharmacy Services',
                          'Online Prescription Management',
                          'Submit prescription by photo',
                        ],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2ghkzqbEsJ4lJFsL0jCzhh',
                        id: '2ghkzqbEsJ4lJFsL0jCzhh',
                        l4Data: [],
                        callToAction: {
                          text: 'Submit prescription by photo',
                          url: 'https://access.eforms.shoppersdrugmart.ca/prescriptionphoto',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Online Prescription Management', 'Drug Lookup'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2z7ouWQxN6U9lQTC1mPdgJ',
                        id: '2z7ouWQxN6U9lQTC1mPdgJ',
                        l4Data: [],
                        callToAction: {
                          text: 'Drug Lookup',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/resources/drug-database',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Online Prescription Management', 'Medication Locator'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6XrHk0NitxY5xiheLi2fm2',
                        id: '6XrHk0NitxY5xiheLi2fm2',
                        l4Data: [],
                        callToAction: {
                          text: 'Medication Locator',
                          url: 'https://www.shoppersdrugmart.ca/en/rx-inventory',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Pharmacy Services', 'Health Conditions'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '1IAXKxsmoaZU03KbGWdyDL',
                id: '1IAXKxsmoaZU03KbGWdyDL',
                callToAction: {
                  text: 'Health Conditions',
                  url: '/',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '3OqPHak11n8DTGI6x5CESN',
                    id: '3OqPHak11n8DTGI6x5CESN',
                    l3Data: [
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Cough & Cold'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '61jhEexRBweQhPFrR4mVjU',
                        id: '61jhEexRBweQhPFrR4mVjU',
                        l4Data: [],
                        callToAction: {
                          text: 'Cough & Cold',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions/cough-cold',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Allergies'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5dGJxKu5MYwoP64OVskh8M',
                        id: '5dGJxKu5MYwoP64OVskh8M',
                        l4Data: [],
                        callToAction: {
                          text: 'Allergies',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions/allergy',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Diabetes Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6qXS4uHd8P5LYQ0Ad2J8VN',
                        id: '6qXS4uHd8P5LYQ0Ad2J8VN',
                        l4Data: [],
                        callToAction: {
                          text: 'Diabetes Care',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions/diabetes',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Mental Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '654RnBGJfkVqHAZDzWc2FB',
                        id: '654RnBGJfkVqHAZDzWc2FB',
                        l4Data: [],
                        callToAction: {
                          text: 'Mental Health',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/mental-health',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Pain Management'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3ruph5HyVWfc9r3Zivyjqj',
                        id: '3ruph5HyVWfc9r3Zivyjqj',
                        l4Data: [],
                        callToAction: {
                          text: 'Pain Management',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions/pain',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Heart Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3iEcsMsQ6taugCITE5hDM',
                        id: '3iEcsMsQ6taugCITE5hDM',
                        l4Data: [],
                        callToAction: {
                          text: 'Heart Health',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions/heart-health',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Digestive Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3pUBiHJVKOwhzumLCB0v1v',
                        id: '3pUBiHJVKOwhzumLCB0v1v',
                        l4Data: [],
                        callToAction: {
                          text: 'Digestive Health',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions/digestive-health',
                        },
                      },
                      {
                        hierarchy: ['Pharmacy Services', 'Health Conditions', 'Condition Lookup'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '273uRFR9xSJm9bpmXmMKat',
                        id: '273uRFR9xSJm9bpmXmMKat',
                        l4Data: [],
                        callToAction: {
                          text: 'Condition Lookup',
                          url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/conditions',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Pharmacy Services', 'PC Health'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: 'SHdy6UMLDRz3JK0hVa447',
                id: 'SHdy6UMLDRz3JK0hVa447',
                callToAction: {
                  text: 'PC Health',
                  url: 'https://www.pchealth.ca/en/',
                },
                columns: [],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '3NsalZqNEwBFG6yzLQJlPK',
          id: '3NsalZqNEwBFG6yzLQJlPK',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '3NsalZqNEwBFG6yzLQJlPK',
            id: '3NsalZqNEwBFG6yzLQJlPK',
            callToAction: {
              text: 'All Brands',
              url: 'https://shop.shoppersdrugmart.ca/all-brands?lang=en',
            },
            l2Data: [
              {
                hierarchy: ['All Brands', 'All Brands'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2IdAncjOe0rtRchcOv5Yk9',
                id: '2IdAncjOe0rtRchcOv5Yk9',
                callToAction: {
                  text: 'All Brands',
                  url: 'https://shop.shoppersdrugmart.ca/all-brands?lang=en',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3GUcz9HYRRpxtK8Frjv536',
                id: '3GUcz9HYRRpxtK8Frjv536',
                callToAction: {
                  text: 'Exclusively at Shoppers Drug Mart',
                  url: 'https://shop.shoppersdrugmart.ca/shop/collections/exclusives/c/Exclusives?sort=trending&page=0&q=%3Atrending',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6b2Ue3dk3byGKx2Dy0jf65',
                    id: '6b2Ue3dk3byGKx2Dy0jf65',
                    l3Data: [
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Shop All Exclusive Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1DyMOKQSkhHq9ZV9LPGD5Q',
                        id: '1DyMOKQSkhHq9ZV9LPGD5Q',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Exclusive Brands',
                          url: 'https://shop.shoppersdrugmart.ca/shop/collections/exclusives/c/Exclusives?sort=trending&page=0&q=%3Atrending',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'About-Face'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3BDGQLo8gsLzNfeGhbGAnx',
                        id: '3BDGQLo8gsLzNfeGhbGAnx',
                        l4Data: [],
                        callToAction: {
                          text: 'About-Face',
                          url: 'https://shop.shoppersdrugmart.ca/shop/about-face/c/ABOUT%2CFACE?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Bondi Sands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3csyAc8AEV7FVFF1bum0Xz',
                        id: '3csyAc8AEV7FVFF1bum0Xz',
                        l4Data: [],
                        callToAction: {
                          text: 'Bondi Sands',
                          url: 'https://shop.shoppersdrugmart.ca/shop/bondi-sands/c/BONDI%2CSANDS?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Byoma'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5xAC3ZLByVivBmPJdukxLb',
                        id: '5xAC3ZLByVivBmPJdukxLb',
                        l4Data: [],
                        callToAction: {
                          text: 'Byoma',
                          url: 'https://shop.shoppersdrugmart.ca/shop/byoma/c/BYOMA?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Cocokind'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4gEwY46pHwDHomt3IO6BVg',
                        id: '4gEwY46pHwDHomt3IO6BVg',
                        l4Data: [],
                        callToAction: {
                          text: 'Cocokind',
                          url: 'https://shop.shoppersdrugmart.ca/shop/cocokind/c/COCOKIND?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Essence'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2qt9vRwAGKYVLAw1swpWlN',
                        id: '2qt9vRwAGKYVLAw1swpWlN',
                        l4Data: [],
                        callToAction: {
                          text: 'Essence',
                          url: 'https://shop.shoppersdrugmart.ca/shop/essence/c/ESSENCE?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Indeed Laboratories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6rz5bSEPrfUwp0MvvB7Uo7',
                        id: '6rz5bSEPrfUwp0MvvB7Uo7',
                        l4Data: [],
                        callToAction: {
                          text: 'Indeed Laboratories',
                          url: 'https://shop.shoppersdrugmart.ca/shop/indeed-laboratories/c/INDEED?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Jason Wu'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '52d4hw0UoBzKr7maFKiwba',
                        id: '52d4hw0UoBzKr7maFKiwba',
                        l4Data: [],
                        callToAction: {
                          text: 'Jason Wu',
                          url: 'https://shop.shoppersdrugmart.ca/shop/jason-wu/c/JASON%2CWU?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Kylie Cosmetics'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3JqUgkGUl90KJIiszU1GxM',
                        id: '3JqUgkGUl90KJIiszU1GxM',
                        l4Data: [],
                        callToAction: {
                          text: 'Kylie Cosmetics',
                          url: 'https://shop.shoppersdrugmart.ca/shop/kylie-cosmetics/c/KYLIE?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Maëlys'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5fLhFdp6TiXKq5SgHHEqa4',
                        id: '5fLhFdp6TiXKq5SgHHEqa4',
                        l4Data: [],
                        callToAction: {
                          text: 'Maëlys',
                          url: 'https://shop.shoppersdrugmart.ca/shop/maelys/c/MAELYS?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'my iN.gredients'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '50bpP66X6CHSysniQKKv85',
                        id: '50bpP66X6CHSysniQKKv85',
                        l4Data: [],
                        callToAction: {
                          text: 'my iN.gredients',
                          url: 'https://shop.shoppersdrugmart.ca/shop/my-in-gredients/c/MY%2CINGREDIENTS?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'mY9cx5mFWvxM0ydq8gWxV',
                    id: 'mY9cx5mFWvxM0ydq8gWxV',
                    l3Data: [
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Naturium'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5xAQPXsoqKo7GzM66jAKRx',
                        id: '5xAQPXsoqKo7GzM66jAKRx',
                        l4Data: [],
                        callToAction: {
                          text: 'Naturium',
                          url: 'https://shop.shoppersdrugmart.ca/shop/naturium/c/NATURIUM?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'No7'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '65eFjUyT2fry12yjWVYs9m',
                        id: '65eFjUyT2fry12yjWVYs9m',
                        l4Data: [],
                        callToAction: {
                          text: 'No7',
                          url: 'https://shop.shoppersdrugmart.ca/shop/no7/c/NO.7?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Pixi'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1W7cRSS1WaagoGfWIr3OAy',
                        id: '1W7cRSS1WaagoGfWIr3OAy',
                        l4Data: [],
                        callToAction: {
                          text: 'Pixi',
                          url: 'https://shop.shoppersdrugmart.ca/shop/pixi/c/PIXI?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Quo Beauty'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '52QsY1WuqZzzp20XLdqp3e',
                        id: '52QsY1WuqZzzp20XLdqp3e',
                        l4Data: [],
                        callToAction: {
                          text: 'Quo Beauty',
                          url: 'https://shop.shoppersdrugmart.ca/shop/quo-beauty/c/QUO%2CBEAUTY?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Raw Sugar'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '47Cr7uVI4fYbtpjYY2erin',
                        id: '47Cr7uVI4fYbtpjYY2erin',
                        l4Data: [],
                        callToAction: {
                          text: 'Raw Sugar',
                          url: 'https://shop.shoppersdrugmart.ca/shop/raw-sugar/c/RAW%2CSUGAR?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'r.e.m. beauty'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5wygTJOaSEY9F82cLnnJ6f',
                        id: '5wygTJOaSEY9F82cLnnJ6f',
                        l4Data: [],
                        callToAction: {
                          text: 'r.e.m. beauty',
                          url: 'https://shop.shoppersdrugmart.ca/shop/r-e-m-beauty/c/R.E.M.BEAUTY?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Revolution'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '23FwkNvIkG39KnxAotoN0K',
                        id: '23FwkNvIkG39KnxAotoN0K',
                        l4Data: [],
                        callToAction: {
                          text: 'Revolution',
                          url: 'https://shop.shoppersdrugmart.ca/shop/revolution/c/REVOLUTION?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Soap & Glory'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'mUVZP0J523LQxvhHy1Rbf',
                        id: 'mUVZP0J523LQxvhHy1Rbf',
                        l4Data: [],
                        callToAction: {
                          text: 'Soap & Glory',
                          url: 'https://shop.shoppersdrugmart.ca/shop/brands/soap-and-glory/c/M-SOAP%26GLORY?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Truly Beauty'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3SEZQsUWoUiaeJtqEvhbtU',
                        id: '3SEZQsUWoUiaeJtqEvhbtU',
                        l4Data: [],
                        callToAction: {
                          text: 'Truly Beauty',
                          url: 'https://shop.shoppersdrugmart.ca/shop/brands/truly-beauty/c/TRULY?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Versed'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6Qfq4x00DkltApJycRR627',
                        id: '6Qfq4x00DkltApJycRR627',
                        l4Data: [],
                        callToAction: {
                          text: 'Versed',
                          url: 'https://shop.shoppersdrugmart.ca/shop/versed/c/VERSED?sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Winky Lux'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4b2v20OVuhqaoDuOESrrlx',
                        id: '4b2v20OVuhqaoDuOESrrlx',
                        l4Data: [],
                        callToAction: {
                          text: 'Winky Lux',
                          url: 'https://shop.shoppersdrugmart.ca/shop/winky-lux/c/WINKY%2CLUX?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1wV0ymJF0dHBdYXzKobBvF',
                    id: '1wV0ymJF0dHBdYXzKobBvF',
                    l3Data: [
                      {
                        hierarchy: ['All Brands', 'Exclusively at Shoppers Drug Mart', 'Naked Sundays'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '73lhI7ugHyeHuWQrHfnasF',
                        id: '73lhI7ugHyeHuWQrHfnasF',
                        l4Data: [],
                        callToAction: {
                          text: 'Naked Sundays',
                          url: 'https://shop.shoppersdrugmart.ca/shop/naked-sundays/c/NAKED%2CSUNDAYS?sort=trending&page=0',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['All Brands', 'Most Searched Brands'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5epPL2s3ZE7zclTmAYJpn5',
                id: '5epPL2s3ZE7zclTmAYJpn5',
                callToAction: {
                  text: 'Most Searched Brands',
                  url: 'https://shop.shoppersdrugmart.ca/all-brands?lang=en',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'MfRoljnkKce5pkMT8vVzm',
                    id: 'MfRoljnkKce5pkMT8vVzm',
                    l3Data: [
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Shop All Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3UGxyyero0YTS7bY7eIQaW',
                        id: '3UGxyyero0YTS7bY7eIQaW',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Brands',
                          url: 'https://shop.shoppersdrugmart.ca/all-brands?nav=shop/beauty/shopallbrand',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Elf Cosmetics'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4Cs9sM7BSncQqI2eEWrXk7',
                        id: '4Cs9sM7BSncQqI2eEWrXk7',
                        l4Data: [],
                        callToAction: {
                          text: 'Elf Cosmetics',
                          url: 'https://shop.shoppersdrugmart.ca/shop/e-l-f-cosmetics/c/ELF%2CCOSMETICS?nav=/brands/elfcosmeticsmostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Lancome'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4MZzRPImj6yRwjzyFUZeDG',
                        id: '4MZzRPImj6yRwjzyFUZeDG',
                        l4Data: [],
                        callToAction: {
                          text: 'Lancome',
                          url: 'https://shop.shoppersdrugmart.ca/shop/lancome/c/LANCOME?nav=/brands/lancomemostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Dior'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'hp5RGsqxtUNyjol9FrL5F',
                        id: 'hp5RGsqxtUNyjol9FrL5F',
                        l4Data: [],
                        callToAction: {
                          text: 'Dior',
                          url: 'https://shop.shoppersdrugmart.ca/shop/dior/c/DIOR?nav=/brands/diormostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Clarins'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4sv8NhcT8gihzIrWgjOKRq',
                        id: '4sv8NhcT8gihzIrWgjOKRq',
                        l4Data: [],
                        callToAction: {
                          text: 'Clarins',
                          url: 'https://shop.shoppersdrugmart.ca/shop/clarins/c/CLARINS?nav=/brands/clarinsmostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Byoma'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6jmJQiUTCjhxhU75QEJN9D',
                        id: '6jmJQiUTCjhxhU75QEJN9D',
                        l4Data: [],
                        callToAction: {
                          text: 'Byoma',
                          url: 'https://shop.shoppersdrugmart.ca/shop/byoma/c/BYOMA?nav=/brands/byomamostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'La Roche Posay'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1lfvOokswUTmwqmLCKW4Cb',
                        id: '1lfvOokswUTmwqmLCKW4Cb',
                        l4Data: [],
                        callToAction: {
                          text: 'La Roche Posay',
                          url: 'https://shop.shoppersdrugmart.ca/shop/la-roche-posay/c/LA%2CROCHE%2CPOSAY?nav=/brands/larocheposaymostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'NYX'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1IOVTp8PxGPR5UboOj2s1N',
                        id: '1IOVTp8PxGPR5UboOj2s1N',
                        l4Data: [],
                        callToAction: {
                          text: 'NYX',
                          url: 'https://shop.shoppersdrugmart.ca/shop/nyx-professional-makeup/c/NYX?nav=/brands/nyxmostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'L’Oréal Paris'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4LGRW8GYaApqbXFR4EMFZv',
                        id: '4LGRW8GYaApqbXFR4EMFZv',
                        l4Data: [],
                        callToAction: {
                          text: 'L’Oréal Paris',
                          url: 'https://shop.shoppersdrugmart.ca/shop/l-oreal-paris/c/L%27OREAL?nav=/brands/lorealparismostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Dyson'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3DiT2RkQXYoBdnj8OsfowX',
                        id: '3DiT2RkQXYoBdnj8OsfowX',
                        l4Data: [],
                        callToAction: {
                          text: 'Dyson',
                          url: 'https://shop.shoppersdrugmart.ca/shop/dyson/c/DYSON?nav=/brands/dysonmostsearched',
                        },
                      },
                      {
                        hierarchy: ['All Brands', 'Most Searched Brands', 'Naturium'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4fFqIwtfVMY947rJ2CeaIa',
                        id: '4fFqIwtfVMY947rJ2CeaIa',
                        l4Data: [],
                        callToAction: {
                          text: 'Naturium',
                          url: 'https://shop.shoppersdrugmart.ca/shop/naturium/c/NATURIUM?nav=/brands/naturiummostsearched',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
            ],
          },
        },
      ],
    },
    region: 'ON',
  },
  footer: {
    typeId: 'pageLayoutSingleColumn',
    sections: {
      mainContentCollection: {
        components: [
          {
            componentId: 'miniInfoCardContainerComponent',
            contentId: '6Nonl4BFjD9nyjOv2w2qIO',
            id: '6Nonl4BFjD9nyjOv2w2qIO',
            data: {
              containerType: 'GRID',
              upNumber: '4UP',
              miniInfoCards: [
                {
                  contentId: '4sugiShS6FUK2mKTybfMga',
                  headline: 'Free delivery',
                  displayText1: 'on orders of $50+, excluding digital gift cards',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/Shipping.png',
                  },
                  displayText1Alignment: 'Top',
                  imageAlignment: 'Left',
                },
                {
                  contentId: '3SJYtIyWHcpnW0cEDAbUJC',
                  headline: 'Free returns by mail',
                  displayText1: 'on all orders',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/return.png',
                  },
                  imageAlignment: 'Left',
                },
                {
                  contentId: '2C7FGfC5snkrhTH0gtAqGO',
                  headline: '3 free samples',
                  displayText1: 'with every Luxury Beauty order',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/samples.png',
                  },
                },
                {
                  contentId: '7Gw4auPatlPTKwo2FghgYq',
                  headline: 'Earn & redeem',
                  displayText1: 'PC Optimum points',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/PCO.png',
                  },
                },
              ],
            },
          },
          {
            componentId: 'footerContentComponent',
            contentId: '5zICDFcQjtEDEakvNiSNK9',
            id: '5zICDFcQjtEDEakvNiSNK9',
          },
        ],
      },
    },
  },
};

export const themeTokens = {
  uppercase: 'uppercase',
  spBase: '0.167rem',
  sp00: '0rem',
  sp01: '0.167rem',
  sp02: '0.334rem',
  sp03: '0.668rem',
  sp04: '1.002rem',
  sp05: '1.336rem',
  sp06: '1.67rem',
  sp07: '2.004rem',
  sp08: '2.672rem',
  sp09: '3.34rem',
  sp10: '6.68rem',
  br00: '0px',
  br01: '2px',
  br02: '4px',
  br03: '8px',
  br04: '12px',
  br05: '20px',
  borderWidth: {
    none: '0px',
    default: '1px',
    medium: '2px',
    thick: '4px',
    extraThick: '8px',
  },
  compact: '100%',
  normal: '120%',
  tall: '150%',
  none: 'none',
  underline: 'underline',
  strikethrough: 'line-through',
  iconSize: {
    xxs: '1rem',
    xs: '1.167rem',
    s: '1.333rem',
    m: '1.667rem',
    l: '2rem',
    xl: '3rem',
    xxl: '4rem',
  },
  tracking: {
    small: '-1%',
    medium: '0',
    large: '1%',
    extraLarge: '2%',
  },
  br06: '32px',
  breakpoint: {
    xlg: '114.667rem',
    lg: '85.333rem',
    md: '75rem',
    sm: '54.167rem',
    xsm: '34.5rem',
    xxlg: '416.667rem',
  },
  siteMargin: {
    xlgDefault: '10.667rem',
    xlgNarrow: '21.333rem',
    lgDefault: '2.667rem',
    lgNarrow: '10.667rem',
    mdDefault: '2.667rem',
    mdNarrow: '10.667rem',
    smDefault: '2.667rem',
    smNarrow: '2.667rem',
    xsmDefault: '1.333rem',
    xlgWide: '3.333rem',
  },
  '25Percent': '0.25',
  '50Percent': '0.5',
  '75Percent': '0.75',
  contentMaxWidth: {
    xlg: '93.333rem',
    lg: '74.667rem',
    md: '67rem',
    sm: '45.333rem',
    xsm: '31.833rem',
    xlgWide: '108rem',
  },
  imageSize: {
    '40': '3.333rem',
    '50': '4.167rem',
    '60': '5rem',
    '70': '5.833rem',
    '80': '6.667rem',
    '90': '7.5rem',
    '100': '8.333rem',
    '110': '9.167rem',
    '120': '10rem',
    '130': '10.833rem',
    '140': '11.667rem',
    '180': '15rem',
  },
  medium: '130%',
  '95Percent': '95%',
  '90Opacity': '0.9',
  base: '1.333rem',
  regular: '300',
  common: {
    common01Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    common01Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    common02Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    common02Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    common03Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    common03Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    common04Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    common04Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    common05Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    common05Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    common01: '1.5rem',
    common02: '1.333rem',
    common03: '1.166rem',
    common04: '1rem',
    common05: '0.833rem',
  },
  progressBar: {
    borderRadius: '100px',
    gap: '0.333rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    height: '0.833rem',
    heightSmall: '0.333rem',
  },
  progressStamp: {
    borderAdded: '2px',
    gap: '0.333rem',
    numberText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    stamperBorderRadius: '12px',
    stamperSize: '2rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    trackerBorder: '4px',
    missedSize: '1.5rem',
    segmentWidth: '0.417rem',
  },
  brand: {
    beautyBoutique: '#62B5E5',
    discount: '#003DA5',
    jf: '#DA3C12',
    market: '#333333',
    murale: '#8977BA',
    sdm: '#CB333B',
    pco: '#F9DADA',
  },
  palette: {
    black: '#2F2F2F',
    darkestGrey: '#757575',
    darkGrey: '#B3B3B3',
    midGrey: '#CACACA',
    lightGrey: '#EAEAEA',
    lightestGrey: '#F5F5F5',
    white: '#ffffff',
    uiRed: '#970012',
    sdmRed: '#CB333B',
    lightRed: '#FFEBEA',
    sdmBlue: '#62B5E5',
    uiLightBlue: '#D3EEF8',
    lightBlue: '#EFF8FC',
    navyBlue: '#1B3B4A',
    healthBlue: '#003C71',
    thoughtfulChoices: '#007FAA',
    uiGreen: '#00853D',
    lightGreen: '#E7F3ED',
    uiOrange: '#FFAE58',
    lightOrange: '#FFF0E0',
    offBlack: '#333333',
    none: 'transparent',
    borderGrey: '#cccccc',
    uiDarkGreen: '#467e1b',
    pcoRed: '#AC342A',
    pcRed: '#e1251b',
    pcDarkRed: '#AE0000',
    pcxGreen: '#00817F',
    pcxDarkGreen: '#006766',
    pcxPurple: '#685BC7',
    pcxOrange: '#FFA06A',
    pcxLightBlue: '#A3D1EB',
    darkOrange: '#D57F07',
    goldenrod: '#FFEFD1',
    slimeGreen: '#F9FFD1',
    skyBlue: '#E2F4FF',
    powderPink: '#F6E6E6',
    pcxTeal: '#BDDDD9',
    pcxLightPurple: '#ECEAFF',
    charcoal: '#403840',
    yellow: '#FED700',
  },
  offertileheader: {
    logoSize: '2.167rem',
    paddingVertical: '0.167rem',
    gap: '0.167rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    iconSize: '1.333rem',
  },
  shoppingListItem: {
    rewardTextActive: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    rewardTextExpired: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    name: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    nameChecked: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '0',
      textDecoration: 'line-through',
    },
    subcopy: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.166rem',
      letterSpacing: '0',
    },
    brand: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    currentPrice: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    currentPriceExpired: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    completionIndicator: '2.667rem',
    image: '3.333rem',
    completionIndicatorIcon: '2rem',
    removeIcon: '1.167rem',
    border: '1px',
    paddingLarge: '1.336rem',
    paddingMedium: '0.668rem',
    emptyMaxWidth: '50.667rem',
    completionIndicatorRadius: '100px',
    imageInactive: '0.25',
  },
  atc: {
    borderRadius: '20px',
    fullBorderRadius: '32px',
    backgroundDefault: '#2F2F2F',
    backgroundPressed: '#757575',
    borderDefault: '#2F2F2F',
    borderPressed: '#757575',
    disabled: '#B3B3B3',
    onDefault: '#ffffff',
    onPressed: '#ffffff',
    onDisabled: '#CACACA',
    buttonMaxWidth: '20rem',
    incrementBorderRadius: '12px',
    disabledIconOpacity: '0.4',
    inputWidth: '4.167rem',
    inputWidthCart: '3.333rem',
  },
  desktop: {
    large01: '3.666rem',
    large02: '2.666rem',
    large03: '1.999rem',
    large04: '1.666rem',
    desktopLarge01Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    desktopLarge02Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    desktopLarge02Regular: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '300',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '0',
    },
    desktopLarge03Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    desktopLarge04Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
  },
  tablet: {
    large01: '2.666rem',
    large02: '1.999rem',
    large03: '1.666rem',
    large04: '1.5rem',
    tabletLarge01Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    tabletLarge02Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    tabletLarge02Regular: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '300',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '0',
    },
    tabletLarge03Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    tabletLarge04Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
  },
  mobile: {
    large01: '2.333rem',
    large02: '1.999rem',
    large03: '1.666rem',
    large04: '1.5rem',
    mobileLarge01Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    mobileLarge02Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    mobileLarge02Regular: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '300',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '0',
    },
    mobileLarge03Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    mobileLarge04Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
  },
  icon: {
    default: '#2F2F2F',
    onPrimary: '#ffffff',
    onInteractive: '#ffffff',
    onSecondary: '#2F2F2F',
    disabled: '#CACACA',
    star: '#2F2F2F',
    error: '#970012',
    warning: '#FFAE58',
    info: '#1B3B4A',
    success: '#00853D',
    accent1: '#CB333B',
    accent2: '#2F2F2F',
    accent3: '#62B5E5',
    interactive: '#2F2F2F',
    deemphasized: '#757575',
    onActionPrimary: '#ffffff',
    onActionPrimaryPressed: '#EAEAEA',
    onActionPrimaryDisabled: '#cccccc',
    onActionSecondary: '#2F2F2F',
    onActionSecondaryPressed: '#B3B3B3',
    onActionSecondaryDisabled: '#cccccc',
    onActionTertiary: '#2F2F2F',
    onActionTertiaryPressed: '#757575',
    onActionTertiaryDisabled: '#cccccc',
  },
  interactive: {
    default: '#757575',
    pressed: '#B3B3B3',
    selected: '#2F2F2F',
    selectedPressed: '#757575',
    backgroundSelected: '#2F2F2F',
    backgroundSelectedPressed: '#757575',
    backgroundDefault: '#F5F5F5',
    backgroundDefaultPressed: '#ffffff',
    error: '#970012',
    disabled: '#CACACA',
    onSelected: '#ffffff',
    deemphasized: '#B3B3B3',
    menuButton: '#ffffff',
  },
  border: {
    default: '#CACACA',
    hover: '#757575',
    onPrimary: '#ffffff',
    onSecondary: '#2F2F2F',
    disabled: '#CACACA',
    emphasized: '#2F2F2F',
    success: '#00853D',
    error: '#970012',
    stepper: '#EAEAEA',
    sponsored: '#EFF8FC',
    accent1: '#CB333B',
    accent2: '#2F2F2F',
    accent3: '#62B5E5',
    actionPrimary: '#2F2F2F',
    actionPrimaryPressed: '#757575',
    actionPrimaryDisabled: '#cccccc',
    actionSecondary: '#B3B3B3',
    actionSecondaryPressed: '#2F2F2F',
    actionSecondaryDisabled: '#cccccc',
    actionTertiary: 'transparent',
    actionTertiaryPressed: 'transparent',
    actionTertiaryDisabled: 'transparent',
  },
  headline: {
    default: '#2F2F2F',
    onPrimary: '#ffffff',
    desktop01: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    desktop02: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    desktop03: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    desktop04: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    tablet01: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    tablet02: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    tablet03: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    tablet04: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    mobile01: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    mobile02: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    mobile03: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    mobile04: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
  },
  text: {
    default: '#2F2F2F',
    onPrimary: '#ffffff',
    onSecondary: '#2F2F2F',
    onTertiary: '#2F2F2F',
    onInteractive: '#ffffff',
    disabled: '#757575',
    deemphasized: '#757575',
    error: '#970012',
    success: '#00853D',
    sale: '#CB333B',
    save: '#00817F',
    deal: '#CB333B',
    accent1: '#2F2F2F',
    accent2: '#CB333B',
    accent3: '#62B5E5',
    onActionPrimary: '#ffffff',
    onActionPrimaryPressed: '#EAEAEA',
    onActionPrimaryDisabled: '#cccccc',
    onActionSecondary: '#2F2F2F',
    onActionSecondaryPressed: '#CACACA',
    onActionSecondaryDisabled: '#cccccc',
    onActionTertiary: '#2F2F2F',
    onActionTertiaryPressed: '#757575',
    onActionTertiaryDisabled: '#cccccc',
  },
  background: {
    default: '#ffffff',
    mid: '#F5F5F5',
    brand: '#CB333B',
    accent1: '#FFEBEA',
    accent2: '#EAEAEA',
    accent3: '#EFF8FC',
    accent3Subdued: '#F5F5F5',
    accent3SubduedPressed: '#EAEAEA',
    dark: '#2F2F2F',
    darkPressed: '#757575',
    accent4: '#EFF8FC',
  },
  surface: {
    default: '#ffffff',
    banner: '#EAEAEA',
    secondary: '#F5F5F5',
    disabled: '#EAEAEA',
    success: '#E7F3ED',
    error: '#FFEBEA',
    warning: '#FFF0E0',
    info: '#D3EEF8',
    sponsored: '#EFF8FC',
    overlay: '#000000',
    accent1: '#CB333B',
    accent2: '#2F2F2F',
    accent3: '#62B5E5',
    dark: '#2F2F2F',
  },
  button: {
    smallVertical: '0.334rem',
    smallHorizontal: '0.334rem',
    smallText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    borderRadius: '32px',
    smallIcon: '1.167rem',
    mediumIcon: '1.333rem',
    smallInterior: '0.334rem',
    mediumVertical: '0.334rem',
    mediumHorizontal: '0.668rem',
    mediumInterior: '0.334rem',
    mediumText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    largeIcon: '2rem',
    largeVertical: '0.668rem',
    largeHorizontal: '0.668rem',
    largeInterior: '0.334rem',
    largeText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
    smallHeight: '2rem',
    mediumHeight: '2.667rem',
    largeHeight: '3.333rem',
    faveBorderRadius: '32px',
    utilityBorderRadius: '8px',
    utilityBorderRadiusSm: '4px',
    utilityInterior: '0.668rem',
    utilityInteriorSm: '0.334rem',
    utilityText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    utilityTextSm: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    minWidth: '2rem',
    menuHeight: '2.667rem',
    menuBorderRadius: '20px',
    menuInterior: '0.668rem',
    menuButtonText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
  },
  browsesidebar: {
    listHeight: '26.333rem',
    width: '25rem',
    titleText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    sectionHeadingLarge: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    widthSm: '17.5rem',
    activeFilterIcon: '0.667rem',
    sectionHeadingSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  miniproducttile: {
    height: '7.667rem',
    imageSize: '5rem',
    opacity: '0.5',
    border: '1px',
    borderRadius: '8px',
  },
  form: {
    mediumLabelText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    mediumVertical: '0.334rem',
    mediumHorizontal: '0.668rem',
    interior: '0.668rem',
    mediumInputText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    largeInputText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    largeVertical: '0.668rem',
    borderRadius: '4px',
    largeHorizontal: '1.002rem',
    largeLabelText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    searchMediumHorizontal: '1.336rem',
    searchBorderRadius: '20px',
    searchlargeHeight: '3.333rem',
    textareaHeight: '7.5rem',
    miniFormButtonAlignment: '1.833rem',
    textMinWidth: '20.833rem',
  },
  element: {
    spacingSmall: '0.334rem',
    spacingMedium: '0.668rem',
    spacingLarge: '1.336rem',
    spacingExtraLarge: '1.67rem',
  },
  content: {
    spacingSmall: '1.336rem',
    spacingMedium: '2.004rem',
    spacingLarge: '3.34rem',
    spacingExtraSmall: '0.668rem',
    spacingExtraLarge: '6.68rem',
    spacingExtraExtraLarge: '8rem',
  },
  control: {
    iconMedium: '1.667rem',
    iconLarge: '2rem',
    mediumLabel: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    largeLabel: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
    mediumLabelPadding: '0.334rem',
    largeLabelPadding: '0.167rem',
  },
  checkbox: {
    borderRadius: '2px',
  },
  switch: {
    borderRadius: '20px',
  },
  badge: {
    iconSizeSmall: '1.333rem',
    paddingVertical: '0.167rem',
    paddingHorizontal: '0.668rem',
    borderRadius: '20px',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    iconSizeLarge: '1.333rem',
    stockPadding: '0.167rem',
    stockBorderRadius: '4px',
    badge01: {
      bg: '#757575',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge02: {
      bg: '#CB333B',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge03: {
      bg: '#CB333B',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge04: {
      bg: '#1B3B4A',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge05: {
      bg: '#00853D',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge06: {
      bg: '#CB333B',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge07: {
      bg: '#1B3B4A',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge08: {
      bg: '#007FAA',
      icon: '#ffffff',
      text: '#ffffff',
    },
    height: '1.5rem',
    badge09: {
      bg: '#F5F5F5',
      icon: '#2F2F2F',
      text: '#2F2F2F',
    },
  },
  dealbadge: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    textLarge: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  variants: {
    selectedRing: '4rem',
    swatch: '3rem',
    paddingDefault: '0.668rem',
    paddingLarge: '1.336rem',
    spacingSmall: '0.334rem',
    borderRadiusLarge: '40px',
    borderRadiusMedium: '10px',
    borderRadiusSmall: '4px',
    swatchDefault: '1px',
    dropdownIcon: '2rem',
    chipText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    dropdownHeight: '25rem',
  },
  price: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bigDeal: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    textSecondary: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    wasText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    wasTextSecondary: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    textPrimary: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    wasTextPrimary: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    descriptor: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    infoTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoSubtitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoDetails: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    infoBorderWidth: '3px',
    unitText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    textSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    wasTextSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
  },
  producttile: {
    variant: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    inventory: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    inventoryIcon: '1.167rem',
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    packageSize: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    pcoText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    variantIcon: '1.167rem',
    padding: '0.668rem',
    borderRadius: '4px',
    brandTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    imageHeight: '9.167rem',
    badgeHeight: '1.5rem',
    productInfoHeight: '5.167rem',
    packageSizePadding: '0.167rem',
    memberPrice: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    horizAtlMinHeight: '11.75rem',
  },
  blocks: {
    itemTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoDefault: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    itemMediumInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    mediumInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    itemSmallInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    smallInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    itemImageMax: '6.667rem',
    itemImageMin: '4.167rem',
    infoMin: '10rem',
    priceMin: '4.167rem',
    itemScrollHeight: '33.333rem',
    iconSize: '2rem',
    itemImagePreview: '4.167rem',
  },
  banner: {
    padding: '0.668rem',
    paddingDesktop: '1.336rem',
    badgesmall: '4.167rem',
    badgebig: '5.833rem',
    paddingTablet: '1.336rem',
    paddingMobile: '1.336rem',
    textSpacingDesktopLarge: '1.336rem',
    textSpacingMobileLarge: '1.336rem',
    headlineDefaultDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    headlineDefaultTablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    headlineDefaultMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    bodyDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    bodyTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    textSpacingTabletLarge: '1.336rem',
    desktopStandardHeight: '25rem',
    desktopShort: '17.5rem',
    tabletTall: '24.167rem',
    tabletStandard: '20.333rem',
    tabletShort: '11.333rem',
    headlineSmallDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    headlineSmallTablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    headlineSmallMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    verticalImageHeight: '29.167rem',
    superheadlineDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    superheadlineTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    superheadlineMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    textSpacingDesktopSmall: '0.334rem',
    textSpacingTabletSmall: '0.334rem',
    textSpacingMobileSmall: '0.334rem',
    spotlightBorderRadius: '8px',
    stampMobileTablet: '8.333rem',
    stampDesktop: '9.583rem',
    stampPaddingSmall: '2.004rem',
    stampPaddingDefault: '3.34rem',
    smallVerticalImageHeight: '20.833rem',
    spotlightContentMinHeight: '15rem',
    logoHeight: '2.667rem',
    maxWidth: '114.667rem',
    fullBleedMaxWidth: '416.667rem',
    spotlightLogoSize: '8.333rem',
    mediaSpotlightImageDesktopMax: '33.333rem',
    mediaSpotlightScrollerDesktopMax: '56.25rem',
    mediaSpotlightImageDesktopMin: '20.833rem',
    mediaSpotlightImageDesktopSmallMax: '31.667rem',
    mediaSpotlightScrollerDesktopSmallMax: '44.333rem',
    mediaSpotlightImageTabletlMax: '20.917rem',
    mediaSpotlightScrollerTabletlMax: '44.75rem',
    mediaSpotlightImageMobileMax: '50rem',
    mediaSpotlightScrollerMobileMax: '50rem',
    stampTextSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    stampTextLarge: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    stampTextExtraLarge: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    desktopContainerMaxWidth: '112rem',
    tabletContainerMaxWidth: '74.667rem',
  },
  link: {
    iconSize: '1.333rem',
    extraSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
  },
  star: {
    large: '1.667rem',
    small: '1.333rem',
    spaceBetween: '0rem',
  },
  bold: '600',
  action: {
    primaryDefault: '#2F2F2F',
    primaryHover: '#757575',
    primaryFocus: '#2F2F2F',
    primaryPressed: '#757575',
    primaryLoading: '#2F2F2F',
    primaryDisabled: '#EAEAEA',
    secondaryDefault: '#ffffff',
    secondaryHover: '#F5F5F5',
    secondaryFocus: '#ffffff',
    secondaryPressed: '#F5F5F5',
    secondaryLoading: '#ffffff',
    secondaryDisabled: '#EAEAEA',
    tertiaryDefault: 'transparent',
    tertiaryHover: '#F5F5F5',
    tertiaryFocus: 'transparent',
    tertiaryPressed: '#F5F5F5',
    tertiaryLoading: 'transparent',
    tertiaryDisabled: 'transparent',
  },
  elevation: {
    low: '0px 4px 8px 0px rgba(64, 56, 64, 0.12)',
    lowUpward: '0px -4px 8px 0px rgba(64,56,64, 0.12)',
    high: '0px 12px 22px 0px rgba(117,117,117, 0.25)',
    highUpward: '0px -12px 22px 0px rgba(117,117,117, 0.25)',
  },
  ordersummary: {
    elevation: '0px 4px 14px 0px rgba(0, 0, 0, 0.08)',
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    default: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    small: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    widthDesktop: '25rem',
    lineItemWidth: '7.083rem',
    tooltipWidth: '16.667rem',
    borderRadius: '8px',
    padding: '2.004rem',
  },
  modals: {
    title: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    itemHeaderBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    closeIcon: '1.667rem',
    closeFrame: '4rem',
    largeContentSpacing: '2.004rem',
    mediumContentSpacing: '1.336rem',
    smallContentSpacing: '0.668rem',
    defaultPadding: '2.004rem',
    largePadding: '3.34rem',
    smallPadding: '0.668rem',
    itemHeaderRegular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    titleSmall: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    detailsBody: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    borderRadius: '4px',
    borderRadiusSheet: '12px',
    scrollHeight: '20.833rem',
    small: '33.333rem',
    medium: '50rem',
    large: '66.667rem',
    extraLarge: '83.333rem',
    minHeight: '20.833rem',
    maxWidth: '116.667rem',
    maxHeight: '48.333rem',
    minWidth: '25rem',
    contentMaxHeight: '16.667rem',
    offerReward: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    offerRewardWas: {
      fontFamily: 'GT Eesti Display',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '0',
      textDecoration: 'line-through',
      fontWeight: '600',
    },
    offerImgTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    offerQualification: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    offerQualificationBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    offerSubcopy: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    offerImageMaxHeight: '18.333rem',
  },
  tile: {
    headlineDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    headlineTablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    headlineMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    bodyDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    paddingDesktop: '1.002rem',
    paddingTablet: '0.668rem',
    paddingMobile: '0.668rem',
    textSpacing: '0.668rem',
    horizontalHeight: '16.083rem',
    verticalHeight: '17.333rem',
    verticalImageHeight: '14.417rem',
    tallVerticalImageHeight: '28.833rem',
  },
  scrollbar: {
    borderRadius: '12px',
    horizontalHeight: '1.167rem',
    verticalWidth: '1rem',
  },
  skeleton: {
    buttonHeight: '3.333rem',
    large01Desktop: '5.167rem',
    large02Desktop: '4rem',
    large04Desktop: '2.5rem',
    bodyText: '1.667rem',
    smallText: '1.333rem',
    large01Tablet: '4.333rem',
    large02Tablet: '3rem',
    large04Tablet: '2rem',
    large01Mobile: '3.333rem',
    large02Mobile: '2.667rem',
    large04Mobile: '1.833rem',
  },
  alert: {
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    subtext: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    details: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderRadius: '4px',
    icon: '1.667rem',
    border: '1px',
    padding: '1.336rem',
  },
  fontFamilies: {
    primary: 'GT Eesti Display',
    secondary: 'GT Eesti Text',
  },
  accordion: {
    titleText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    defaultPadding: '0rem',
    largePadding: '1.336rem',
    innerSpacing: '1.336rem',
    extraLargePadding: '2.672rem',
    extraSmallPadding: '0.167rem',
    mobilePadding: '4.667rem',
    tabletPadding: '6rem',
  },
  borderStyle: {
    default: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    none: {
      color: 'transparent',
      width: '1px',
      style: 'solid',
    },
    card: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    emphasized: {
      color: '#2F2F2F',
      width: '1px',
      style: 'solid',
    },
  },
  card: {
    border: '1px',
    horizontalBorderRadius: '8px',
    infoBorderRadius: '8px',
    horizontalImageRadius: '4px',
    headlineDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    headlineTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    headlineMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    bodyDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    bodyTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    bodyMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    contentSpacing: '0.668rem',
    contentPadding: '1.67rem',
    verticalBorderRadius: '8px',
    horizontalImage: '10rem',
    horizontalImageShort: '6.667rem',
    collectionSpacing: '1.67rem',
    miniInfoImage: '4.167rem',
    infoHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    infoBody: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoDisclaimer: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    infoIcon: '1.333rem',
    infoMinWidth: '23.333rem',
    infoMaxWidth: '30.583rem',
    collectionOuterPadding: '0.668rem',
    miniInfoWidth: '14.583rem',
    collectionPadding: '1.002rem',
    infoPadding: '1.336rem',
  },
  fulfillment: {
    body: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    bodyEmphasized: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    image: '3.333rem',
    imageBorderRadius: '4px',
    headline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    iconInfo: '1.333rem',
    icon: '3.333rem',
    iconStatus: '1.333rem',
    disclaimer: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
  },
  pagination: {
    buttonSize: '3rem',
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    buttonText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  slider: {
    arrowDefault: '4.333rem',
    arrowSmall: '3.167rem',
    indicatorPadding: '0.668rem',
    indicatorSpacing: '0.668rem',
    borderRadius: '20px',
    arrowBorderRadius: '32px',
    indicatorBorder: '1px',
    indicatorDot: '0.833rem',
    chevronIcon: '2.333rem',
    arrowDistance: '16.667rem',
  },
  breadcrumbs: {
    paddingHorizontal: '0.668rem',
  },
  drawer: {
    widthMed: '42.667rem',
    widthSm: '37.333rem',
    widthLg: '56rem',
  },
  menutilescroller: {
    textHeight: '2.667rem',
    borderRadius: '12px',
    padding: '0.668rem',
    spacing: '0.334rem',
    widthDefault: '8.333rem',
  },
  producttileframe: {
    borderStyle: {
      color: '#EFF8FC',
      width: '2px',
      style: 'solid',
    },
    badgeText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    badgeHeight: '2.667rem',
    badgePadding: '0.668rem',
  },
  menu: {
    smallInnerSpacing: '0.334rem',
    mediumInnerSpacing: '0.5rem',
    extrasmallPadding: '0.5rem',
    smallPadding: '0.668rem',
    defaultButton: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    defaultIcon: '2rem',
    emphasizedButton: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    extralargePadding: '2.672rem',
    extrasmallInnerSpacing: '0.167rem',
    smallIcon: '1.667rem',
    mediumButton: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    mediumPadding: '1.336rem',
    smallBorderRadius: '12px',
    defaultBorderRadius: '20px',
    largePadding: '2.004rem',
    largeInnerSpacing: '1.002rem',
    default: '#2F2F2F',
    hover: '#CB333B',
    selected: '#CB333B',
    lightDefault: '#ffffff',
    lightHover: '#ffffff',
    lightSelected: '#ffffff',
    extralargeInnerSpacing: '2.672rem',
    leftsidebarwidth: '19rem',
    maxWidth: '114.667rem',
    simpleHeight: '6.667rem',
    simpleLogoWidth: '11.667rem',
    smallText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
  },
  informationtext: {
    smallInnerSpacing: '0.334rem',
    mediumInnerSpacing: '0.668rem',
    defaultIcon: '1.667rem',
    defaultText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
  },
  filter: {
    chipVertical: '0.167rem',
    chipHorizontal: '0.668rem',
    sortPadding: '0.334rem',
    chipBorderRadius: '20px',
    quickFilterBorderRadius: '4px',
    chipText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    chipIcon: '1.333rem',
    quickfilterHeight: '2.667rem',
    sortIcon: '1.667rem',
    sortItemHeight: '2.667rem',
    sortDropdownWidth: '15rem',
    sortDropdownHeight: '2.667rem',
  },
  focus: {
    default: '#5D9ACDb3',
    onLight: '#000000b3',
    onDark: '#ffffffb3',
  },
  focusRingOnLight: '0px 0px 0px 3px #000000b3',
  focusRingOnDark: '0px 0px 0px 3px #ffffffb3',
  focusRingDefault: '0px 0px 0px 3px #5D9ACDb3',
  logo: {
    largeWidth: '16.667rem',
    largeHeight: '2.167rem',
    smallWidth: '9.75rem',
    smallHeight: '1.75rem',
  },
  atl: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    backgroundDefault: '#ffffff',
    backgroundHover: '#ffffff',
    backgroundPressed: '#F5F5F5',
    backgroundAdded: '#ffffff',
    backgroundAddedPressed: '#ffffff',
    borderDefault: '#2F2F2F',
    borderHover: '#2F2F2F',
    borderPressed: '#2F2F2F',
    borderAdded: '#2F2F2F',
    borderAddedPressed: '#ffffff',
    onDefault: '#2F2F2F',
    onHover: '#2F2F2F',
    onPressed: '#CB333B',
    onAdded: '#CB333B',
    onAddedPressed: '#2F2F2F',
    secondaryBackgroundDefault: '#ffffff',
    secondaryBackgroundHover: '#ffffff',
    secondaryBackgroundPressed: '#ffffff',
    secondaryBackgroundAdded: '#ffffff',
    secondaryBackgroundAddedPressed: '#ffffff',
    secondaryBorderDefault: '#ffffff',
    secondaryBorderHover: '#2F2F2F',
    secondaryBorderPressed: '#ffffff',
    secondaryBorderAdded: '#ffffff',
    secondaryBorderAddedPressed: '#ffffff',
    secondaryOnDefault: '#2F2F2F',
    secondaryOnHover: '#2F2F2F',
    secondaryOnPressed: '#ffffff',
    secondaryOnAdded: '#2F2F2F',
    secondaryOnAddedPressed: '#2F2F2F',
    buttonWidth: '5.333rem',
    buttonHeight: '2.833rem',
    iconSize: '1.167rem',
    padding: '0.667rem',
    gap: '0.333rem',
    borderRadius: '4px',
    borderWidthAdded: '1px',
    borderWidthSecondary: '2px',
    elevation: '0px 0px 0px 0px #403840',
    added: '#ffffff',
    hover: '#F5F5F5',
  },
  linklist: {
    itemDefault: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    itemSelected: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  primaryWeight: 'undefined',
  iconbutton: {
    smallIconSize: '1.333rem',
    smallSize: '2.667rem',
    smallborderRadius: '16px',
    largeSize: '6.667rem',
    largeSizeMobile: '5rem',
    largeIconSize: '2rem',
    largePadding: '1.002rem',
    smallPadding: '0.668rem',
    largeborderRadius: '40px',
    defaultText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    largeIconSizeMobile: '1.667rem',
  },
  starRating: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    largeText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  tab: {
    height: '2.667rem',
    spaceBetween: '1.67rem',
    lconSize: '2rem',
    textDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    textDesktopBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    textMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    textMobileBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  paragraph: {
    iconSmall: '1.333rem',
    iconMedium: '1.667rem',
  },
  table: {
    mediumPadding: '1.336rem',
    narrowheaderWidth: '14rem',
    smallPadding: '1.002rem',
    emphasizedText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    defaultText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  default: '0.3',
  imagegallery: {
    thumbnailSize: '6.667rem',
    tallThumbnailSize: '8.333rem',
    thumbnailImageSize: '5rem',
    tallThumbnailImageSize: '6.667rem',
    buttonpaddingLarge: '2.004rem',
    borderRadius: '8px',
    headlinePadding: '4.667rem',
    imageMaxHeightLarge: '41.667rem',
    imageZoomMaxHeight: '66.667rem',
    imageMaxHeightSmall: '25rem',
    thumbnailZoomMaxWidthLg: '73.333rem',
    thumbnailZoomMaxWidthMd: '72.667rem',
    thumbnailZoomMaxWidthSm: '30rem',
  },
  productTitle: {
    brandText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  caption: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  imageGallery: {
    seeMoreText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    thumbnailText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
  },
  pcobasepoints: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  search: {
    queryIconSize: '1.667rem',
    queryImageSize: '3.333rem',
    itemPaddingRight: '1.336rem',
    itemPaddingLeft: '0.668rem',
    queryItemTyped: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    queryItemSuggested: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    leftIndicatorSize: '3.333rem',
    itemTextPaddingTop: '1.336rem',
    itemTextPaddingBottom: '1.336rem',
    dropdownWidth: '46.167rem',
    dropdownPaddingLeft: '0rem',
    dropdownPaddingRight: '0rem',
    dropdownPaddingTop: '0rem',
    dropdownPaddingBottom: '0rem',
    dropdownBorderRadius: '8px',
  },
  queryitem: {
    borderWidth: '1px',
  },
  radiocard: {
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    body: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    smallSpacing: '0.334rem',
    largeSpacing: '1.336rem',
    largePadding: '1.336rem',
  },
  storeIdentifier: {
    logoHeightDesktop: '3rem',
    logoHeightMobile: '2rem',
    logoWidthDesktop: '10.667rem',
    logoWidthMobile: '8rem',
    storeName: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  header: {
    iconLarge: '4rem',
    iconMedium: '3rem',
    iconSmall: '2rem',
    logoHeight: '8.333rem',
    bannerLogoImageHeightMax: '6.667rem',
    bannerImageHeightMax: '4.167rem',
    bannerLogoImageSpacing: '-2rem',
    logoSmallHeight: '3.333rem',
  },
  utility: {
    utility01: {
      fill01: '#ffffff',
      fill02: '#B3B3B3',
      fill03: '#B3B3B3',
      fill04: '#B3B3B3',
    },
    utility02: {
      fill01: '#ffffff',
      fill02: '#B3B3B3',
      fill03: '#B3B3B3',
      fill04: '#B3B3B3',
    },
    utility03: {
      fill01: '#ffffff',
      fill02: '#B3B3B3',
      fill03: '#B3B3B3',
      fill04: '#B3B3B3',
    },
  },
  testLocalFix: '0.083rem',
  offertile: {
    standardWidth: '14.5rem',
    borderRadius: '8px',
    miniHeight: '7.833rem',
    imageAreaHeight: '12.223rem',
    imageActive: '1',
    imageInactive: '0.5',
    productImageSize: '8.333rem',
    heroImageSize: '12.223rem',
    miniMinWidth: '23.333rem',
    miniMaxWidth: '30.583rem',
    rewardText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    rewardTextWas: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      fontSize: '1.333rem',
      lineHeight: '130%',
      letterSpacing: '0',
      textDecoration: 'line-through',
    },
    titleText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    subcopyText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      fontSize: '1rem',
      lineHeight: '130%',
      letterSpacing: '0',
      textDecoration: 'none',
    },
    qualificationText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    qualificationBoldText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
    largeWidth: '29.333rem',
    paddingMedium: '0.668rem',
    paddingLarge: '1.336rem',
    miniQualificationText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    miniQualificationBoldText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    miniImageSize: '6.167rem',
    minHeight: '22.333rem',
    miniMinHeight: '7.833rem',
    miniMaxHeight: '9.833rem',
  },
  progressbar: {
    borderStyle: {
      style: 'solid',
      width: '1px',
      color: '#62B5E5',
    },
  },
  actionList: {
    minHeight: '4.833rem',
    minWidth: '23.333rem',
    maxWidth: '50rem',
    containerMaxWidth: '101.333rem',
  },
  inventoryBadge: {
    opacity: '95%',
  },
  multiStep: {
    medium: '2.004rem',
    small: '1.336rem',
    extrasmall: '0.668rem',
    borderRadius: '12px',
  },
  iab: {
    leaderboardMin: '37.583rem',
    leaderboardMax: '100rem',
    bigboxMin: '25rem',
    bigboxMax: '37.5rem',
    doublebigboxMin: '25rem',
    doublebigboxMax: '37.5rem',
    mbannerMin: '25rem',
    mbannerMax: '37.5rem',
  },
  imagegrid: {
    sixUpDesktopMax: '14.417rem',
    sixUpDesktopMin: '10.5rem',
    fourUpDesktopMin: '16.167rem',
    fourUpDesktopMax: '22.083rem',
    threeUpDesktopMax: '30rem',
    threeUpDesktopMin: '22.083rem',
    twoUpDesktopMax: '45.333rem',
    twoUpDesktopMin: '33.5rem',
    sixUpMobileMax: '15.333rem',
    sixUpMobileMin: '9.667rem',
    fourUpMobileMin: '15.25rem',
    fourUpMobileMax: '23.75rem',
    threeUpMobileMax: '23.75rem',
    threeUpMobileMin: '15.25rem',
    twoUpMobileMax: '33.417rem',
    twoUpMobileMin: '23.083rem',
  },
  swapAndSave: {
    iconSize: '1.667rem',
    headerIconSize: '2rem',
    imageSizeDesktop: '5rem',
    imageSizeMobile: '3.333rem',
    itemPadding: '1.336rem',
    suggestionBorderRadius: '8px',
    imageBorderRadius: '2px',
    subcopy: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    savingsHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    productName: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    price: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    currentHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  navchip: {
    iconSize: '1.333rem',
    paddingVertical: '0.667rem',
    paddingHorizontal: '1.333rem',
    borderRadius: '40px',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    maxWidth: '16.667rem',
  },
  box: {
    borderRadiusXs: '4px',
    borderRadiusSm: '8px',
    borderRadiusMd: '12px',
    borderRadiusLg: '20px',
    borderRadiusXl: '32px',
    paddingXs: '0.167rem',
    paddingSm: '0.334rem',
    paddingMd: '0.668rem',
    paddingLg: '1.336rem',
    paddingXl: '2.004rem',
    paddingNull: '0rem',
  },
  gridSpacing: {
    default: '0.667rem',
  },
  pointsWidget: {
    ptsValueDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    ptsValueMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    ptsDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    ptsMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    redeemableValue: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    shadow: '0px 0px 0px 0px #000000',
    insightText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    imgHeight: '4.167rem',
    boxLg: '2.004rem',
    boxMd: '1.336rem',
    boxSm: '0.668rem',
    gap: '0.668rem',
    borderRadius: '20px',
    gapSm: '0.334rem',
    gapContentLg: '3.34rem',
    gapContentSm: '1.336rem',
    maxWidthDesktop: '66rem',
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    headerImgHeightDesktop: '25.333rem',
    headerImgHeightTablet: '20.667rem',
    headerImgHeightMobile: '29rem',
  },
  markdown: {
    heading1Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    heading2Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    heading3Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    heading4Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    heading5Desktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading6Desktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    heading1Tablet: 'tablet.Tablet Large 01 Bold}',
    heading2Tablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    heading3Tablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    heading4Tablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading5Tablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading6Tablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    heading1Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    heading2Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    heading3Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    heading4Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading5Mobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading6Mobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    paragraph: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    listItem: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  loadIndicator: {
    iconSize: '2rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderRadius: '32px',
    border: {
      color: 'transparent',
      width: '0',
      style: 'solid',
    },
  },
  productItemImage: {
    boxPadding: '0rem',
    opacity: '0.25',
  },
  accordionBodyText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  accordionTitleText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  accordionIcon: '2rem',
  accordionDefaultPadding: '0rem',
  accordionLargePadding: '1.336rem',
  accordionInnerSpacing: '1.336rem',
  accordionSmallIcon: '1.667rem',
  accordionExtraLargePadding: '2.672rem',
  accordionExtraSmallPadding: '0.167rem',
  accordionMobilePadding: '4.667rem',
  accordionTabletPadding: '6rem',
  actionListMinHeight: 'actionList.minHeight',
  actionListMinWidth: '23.333rem',
  actionListMaxWidth: '50rem',
  actionListContainerMaxWidth: '101.333rem',
  alertsBorderRadius: '4px',
  alertsIconSize: '1.667rem',
  elevationShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
  alertsInteriorSpacing: '0.668rem',
  alertsBorderWidth: '0.667rem',
  alertsTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  alertsSubtext: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  alertsUnderline: 'underline',
  alertsDetails: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  alertBorder: '1px',
  alertPadding: '1.336rem',
  atcIconSize: '2rem',
  atcPaddingVertical: '0.668rem',
  atcPaddingHorizontal: '0.668rem',
  atcBorderRadius: '20px',
  atcQuantityText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  atcBorderRadiusFull: '32px',
  atcButtonMaxWidth: '20rem',
  atcIncrementBorderRadius: '12px',
  atcDisabledIconOpacity: '0.4',
  atcInputWidth: '4.167rem',
  atcInputWidthCart: '3.333rem',
  atlIconSize: '1.167rem',
  atlIconSizeFull: '2rem',
  atlButtonWidth: '5.333rem',
  atlPadding: '0.667rem',
  atlPaddingFullHorizontal: '0.668rem',
  atlPaddingFullVertical: '0.668rem',
  atlBorderRadius: '4px',
  atlBorderRadiusFull: '32px',
  atlBorderWidthAdded: '1px',
  atlElevation: '0px 0px 0px 0px #000',
  atlText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  atlTextFull: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  atlGap: '0.333rem',
  atlButtonHeight: '2.833rem',
  atlButtonHeightFull: '3.333rem',
  badgePaddingVertical: '0.167rem',
  badgeBorderRadius: '20px',
  badgeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  badgePaddingHorizontal: '0.668rem',
  badgeIconSize: '1.333rem',
  badgeElevation: '0px 0px 0px 0px #000',
  dealbadgeIconSize: '1.333rem',
  dealBadgeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  badgeStockPadding: '0.167rem',
  badgeStockBorderRadius: '4px',
  dealBadgeTextLarge: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  badgeHeight: '1.5rem',
  productMessageIconSize: '1.333rem',
  inventoryBadgeOpacity: '95%',
  bannerDefaultHeadlineDesktop: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '2.666rem',
    letterSpacing: '1%',
  },
  bannerContentPaddingDesktop: '1.336rem',
  bannerContentSpacingDesktop: '2.004rem',
  bannerTextSpacingDesktopLarge: '1.336rem',
  bannerBadgeBig: '5.833rem',
  bannerBadgeSmall: '4.167rem',
  bannerLogoHeight: '2.667rem',
  bannerDefaultHeadlineTablet: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerDefaultHeadlineMobile: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerBodyDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  bannerBodyTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  bannerContentPaddingTablet: '1.336rem',
  bannerTextSpacingTabletLarge: '1.336rem',
  bannerContentSpacingTablet: '1.336rem',
  bannerBodyMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  bannerContentPaddingMobile: '1.336rem',
  bannerContentSpacingMobile: '1.336rem',
  bannerTextSpacingMobileLarge: '1.336rem',
  bannerSmallHeadlineDesktop: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerSmallHeadlineTablet: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  bannerSmallHeadlineMobile: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  bannerVerticalImageHeight: '29.167rem',
  bannerSmallVerticalImageHeight: '20.833rem',
  bannerSuperheadlineDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  bannerSuperheadlineTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  bannerSuperheadlineMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  bannerTextSpacingDesktopSmall: '0.334rem',
  bannerTextSpacingTabletSmall: '0.334rem',
  bannerTextSpacingMobileSmall: '0.334rem',
  bannerSpotlightBorderRadius: '8px',
  bannerStampMobileTablet: '8.333rem',
  bannerStampDesktop: '9.583rem',
  bannerStampPaddingSmall: '2.004rem',
  bannerStampPaddingDefault: '3.34rem',
  bannerStampTextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  bannerStampTextLarge: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  bannerStampTextExtraLarge: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerSpotlightContentMinHeight: '15rem',
  bannerMaxWidth: '114.667rem',
  bannerFullBleedMaxWidth: '416.667rem',
  spotlightLogoImageSize: '8.333rem',
  mediaSpotlightImageDesktopMax: '33.333rem',
  mediaSpotlightScrollerDesktopMax: '56.25rem',
  mediaSpotlightImageDesktopSmallMax: '31.667rem',
  mediaSpotlightScrollerDesktopSmallMax: '44.333rem',
  mediaSpotlightImageTabletMax: '20.917rem',
  mediaSpotlightScrollerTabletMax: '44.75rem',
  mediaSpotlightImageMobileMax: '50rem',
  mediaSpotlightScrollerMobileMax: '50rem',
  mediaSpotlightImageDesktopMin: '20.833rem',
  bannerDesktopContainerMaxWidth: '112rem',
  bannerTabletContainerMaxWidth: '74.667rem',
  tableMediumPadding: '1.336rem',
  tableSmallPadding: '1.002rem',
  tableNarrowHeaderWidth: '14rem',
  tableEmphasizedText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tableDefaultText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  blocksItemSmallInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  blocksSmallInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  blocksItemMediumInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksMediumInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksItemTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksInfoDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksItemImageMin: '4.167rem',
  blocksItemImageMax: '6.667rem',
  blocksItemImagePreview: '4.167rem',
  blocksInfoMin: '10rem',
  blocksPriceMin: '4.167rem',
  blocksItemScrollHeight: '33.333rem',
  blocksIconSize: '2rem',
  breadcrumbsPaddingHorizontal: '0.668rem',
  browseSidebarAccordionTitleText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  browseSidebarListHeight: '26.333rem',
  linkListItemDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  linkListItemSelected: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  linkListItemHover: 'underline',
  browseSidebarWidth: '25rem',
  browseSidebarSectionHeadingLarge: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  browseSidebarWidthSm: '17.5rem',
  activeFilterIcon: '0.667rem',
  browseSidebarSectionHeadingSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  buttonSmallVertical: '0.334rem',
  buttonSmallHorizontal: '0.334rem',
  buttonSmallIcon: '1.167rem',
  buttonBorderRadius: '32px',
  buttonSmallInterior: '0.334rem',
  buttonMediumIcon: '1.333rem',
  buttonMediumHorizontal: '0.668rem',
  buttonMediumVertical: '0.334rem',
  buttonMediumInterior: '0.334rem',
  buttonLargeIcon: '2rem',
  buttonLargeVertical: '0.668rem',
  buttonLargeHorizontal: '0.668rem',
  buttonLargeInterior: '0.334rem',
  buttonBorderWidth: '1px',
  buttonSmallText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  buttonMediumText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  buttonLargeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  buttonSmallHeight: '2rem',
  buttonMediumHeight: '2.667rem',
  buttonLargeHeight: '3.333rem',
  buttonFaveBorderRadius: '32px',
  buttonUtilityBorderRadius: '8px',
  buttonUtilityBorderRadiusSm: '4px',
  buttonUtilityInterior: '0.668rem',
  buttonUtilityInteriorSm: '0.334rem',
  buttonUtilityText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  buttonUtilityTextSm: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  buttonMinWidth: '2rem',
  buttonMenuHeight: '2.667rem',
  menuBorderRadius: '20px',
  buttonMenuInterior: '0.668rem',
  menuButtonText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  cardHeadlineDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  cardHeadlineTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardHeadlineMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardBodyDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardBodyTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  cardBodyMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  cardContentPadding: '1.67rem',
  cardContentSpacing: '0.668rem',
  cardHorizontalImage: '10rem',
  cardHorizontalImageShort: '6.667rem',
  cardHorizontalBorderRadius: '8px',
  cardBorder: '1px',
  cardHorizontalImageRadius: '4px',
  cardVerticalBorderRadius: '8px',
  cardBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  cardCollectionSpacing: '1.67rem',
  cardMiniInfoImage: '4.167rem',
  cardInfoHeadline: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  cardInfoBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardInfoDisclaimer: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  cardInfoMinWidth: '23.333rem',
  cardInfoMaxWidth: '30.583rem',
  cardInfoIcon: '1.333rem',
  cardInfoPadding: '1.336rem',
  cardMiniInfoWidth: '14.583rem',
  cardInfoBorderRadius: '8px',
  cardCollectionPadding: '1.002rem',
  cardCollectionOuterPadding: '0.668rem',
  controlIconMedium: '1.667rem',
  controlIconLarge: '2rem',
  checkboxBorderRadius: '2px',
  controlMediumLabel: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  controlLargeLabel: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  controlBorderWidth: '1px',
  switchBorderRadius: '20px',
  controlMediumLabelPadding: '0.334rem',
  controlLargeLabelPadding: '0.167rem',
  drawerWidthMed: '42.667rem',
  drawerWidthSm: '37.333rem',
  drawerWidthLg: '56rem',
  filterChipIconSize: '1.333rem',
  filterChipVertical: '0.167rem',
  filterChipHorizontal: '0.668rem',
  filterChipBorderRadius: '20px',
  filterChipText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  filterQuickfilterHeight: '2.667rem',
  filterSortIconSize: '1.667rem',
  filterSortPadding: '0.334rem',
  filterSortItemHeight: '2.667rem',
  filterQuickFilterBorderRadius: '4px',
  quickFilterBorderWidth: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  sortDropdownWidth: '15rem',
  sortDropdownHeight: '2.667rem',
  navChipIconSize: '1.333rem',
  navChipPaddingVertical: '0.667rem',
  navChipPaddingHorizontal: '1.333rem',
  navChipBorderRadius: '40px',
  navChipText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  navChipMaxWidth: '16.667rem',
  formMediumLabelText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  formMediumIcon: '1.667rem',
  formLargeIcon: '2rem',
  tooltipSize: '1.333rem',
  formHelperText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  formMediumInputText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  formMediumVertical: '0.334rem',
  formMediumHorizontal: '0.668rem',
  formInterior: '0.668rem',
  formLargeInputText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  formLargeVertical: '0.668rem',
  formBorderRadius: '4px',
  formSearchBorderRadius: '20px',
  formLargeHorizontal: '1.002rem',
  formLargeLabelText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  formSearchMediumHorizontal: '1.336rem',
  formSearchLargeHeight: '3.333rem',
  formTextAreaHeight: '7.5rem',
  formMiniFormButtonAlignment: '1.833rem',
  formTextMinWidth: '20.833rem',
  fulfillmentImage: '3.333rem',
  fulfillmentImageBorderRadius: '4px',
  fulfillmentHeadline: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  fulfillmentIconInfo: '1.333rem',
  fulfillmentBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  fulfillmentBodyEmphasized: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  fulfillmentIcon: '3.333rem',
  fulfillmentIconStatus: '1.333rem',
  fulfillmentDisclaimer: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  iconButtonSmallIconSize: '1.333rem',
  iconButtonLargeIconSizeMobile: '2rem',
  iconButtonSmallBorderRadius: '16px',
  iconButtonSmallSize: '2.667rem',
  iconButtonLargeSize: '6.667rem',
  iconButtonLargeSizeMobile: '5rem',
  iconButtonLargeIconSize: '2rem',
  iconButtonLargeSpacing: '1.002rem',
  iconButtonSmallPadding: '0.668rem',
  iconButtonLargeBorderRadius: '40px',
  iconButtonDefaultText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  imageGalleryThumbnailSize: '6.667rem',
  imageGalleryTallThumbnailSize: '8.333rem',
  imageGalleryThumbnailImageSize: '5rem',
  imageGalleryTallThumbnailImageSize: '6.667rem',
  imageGalleryButtonPaddingLarge: '2.004rem',
  imageGalleryBorderRadius: '8px',
  imageGalleryHeadlinePadding: '4.667rem',
  imageGalleryCaptionText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  imageGallerySeeMoreText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  imageGalleryControlNavDotLarge: '0.833rem',
  imageGalleryControlNavDotMed: '0.667rem',
  imageGalleryControlNavDotSmall: '0.5rem',
  imageGalleryControlNavDotLast: '0.333rem',
  imageGalleryImageMaxHeightLarge: '41.667rem',
  imageGalleryImageMaxHeightSmall: '25rem',
  imageGalleryImageZoomMaxHeight: '66.667rem',
  imageGalleryThumbnailText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  imageGalleryThumbnailZoomMaxWidthLg: '73.333rem',
  imageGalleryThumbnailZoomMaxWidthMd: '72.667rem',
  imageGalleryThumbnailZoomMaxWidthSm: '30rem',
  imageGridSixUpDesktopMax: '14.417rem',
  imageGridSixUpDesktopMin: '10.5rem',
  imageGridFourUpDesktopMax: '22.083rem',
  imageGridFourUpDesktopMin: '16.167rem',
  imageGridThreeUpDesktopMin: '22.083rem',
  imageGridThreeUpDesktopMax: '30rem',
  imageGridTwoUpDesktopMax: '45.333rem',
  imageGridTwoUpDesktopMin: '33.5rem',
  imageGridSixUpMobileMax: '15.333rem',
  imageGridSixUpMobileMin: '9.667rem',
  imageGridFourUpMobileMax: '23.75rem',
  imageGridFourUpMobileMin: '15.25rem',
  imageGridThreeUpMobileMax: '23.75rem',
  imageGridThreeUpMobileMin: '15.25rem',
  imageGridTwoUpMobileMax: '33.417rem',
  imageGridTwoUpMobileMin: '23.083rem',
  informationtextSmallInnerSpacing: '0.334rem',
  informationtextMediumInnerSpacing: '0.668rem',
  informationtextDefaultIcon: '1.667rem',
  informationtextDefaultText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  informationtextBorderWidth: '1px',
  linkIconSize: '1.333rem',
  linkDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  linkUnderline: 'underline',
  linkSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  linkExtraSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  menuSmallInnerSpacing: '0.334rem',
  menuMediumInnerSpacing: '0.5rem',
  menuExtraSmallPadding: '0.5rem',
  menuSmallPadding: '0.668rem',
  menuDefaultButton: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  menuDefaultIcon: '2rem',
  menuEmphasizedButton: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  menuExtraLargePadding: '2.672rem',
  menuExtraSmallInnerSpacing: '0.167rem',
  menuSmallIcon: '1.667rem',
  menuMediumButton: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  menuMediumPadding: '1.336rem',
  menuSmallBorderRadius: '12px',
  menuDefaultBorderRadius: '20px',
  menuLargePadding: '2.004rem',
  menuLargeInnerSpacing: '1.002rem',
  menubuttonUnderline: 'underline',
  menuExtraLargeInnerSpacing: '2.672rem',
  menuLeftSidebarWidth: '19rem',
  menuMaxWidth: '114.667rem',
  menuSimpleLogoWidth: '11.667rem',
  menuSimpleHeight: '6.667rem',
  menuSmallText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  menuTileScrollerTextHeight: '2.667rem',
  menuTileScrollerPadding: '0.668rem',
  menuTileScrollerBorderRadius: '12px',
  menuTileScrollerSpacing: '0.334rem',
  menuTileScrollerWidthDefault: '8.333rem',
  miniProductTileHeight: '7.667rem',
  miniProductTileImageSize: '5rem',
  miniProductTileBorderRadius: '8px',
  miniProductTileBorder: '1px',
  miniProductTileActiveOpacity: '0.5',
  modalsItemHeaderBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  modalsItemHeaderRegular: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  modalsDetailsBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  modalsItemImage: '6.667rem',
  modalsItemListBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsStrikethrough: 'line-through',
  modalsTitle: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  modalsTitleSmall: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  modalsLargeContentSpacing: '2.004rem',
  modalsMediumContentSpacing: '1.336rem',
  modalsSmallContentSpacing: '0.668rem',
  modalsDefaultPadding: '2.004rem',
  modalsLargePadding: '3.34rem',
  modalsSmallPadding: '0.668rem',
  modalsCloseIcon: '1.667rem',
  modalsCloseFrame: '4rem',
  modalsBorderRadius: '4px',
  modalsBorderRadiusSheet: '12px',
  modalsScrollHeight: '20.833rem',
  modalsSmall: '33.333rem',
  modalsMedium: '50rem',
  modalsLarge: '66.667rem',
  modalsExtraLarge: '83.333rem',
  modalsMinHeight: '20.833rem',
  modalsMaxWidth: '116.667rem',
  modalsMaxHeight: '48.333rem',
  modalsMinWidth: '25rem',
  modalsContentMaxHeight: '16.667rem',
  modalsOfferReward: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  modalsOfferRewardWas: {
    fontFamily: 'GT Eesti Display',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '0',
    textDecoration: 'line-through',
    fontWeight: '600',
  },
  modalsOfferImgTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsOfferQualification: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsOfferQualificationBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsOfferSubcopy: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  modalsOfferImageMaxHeight: '18.333rem',
  offerTileHeaderLogoSize: '2.167rem',
  offerTileHeaderPaddingVertical: '0.167rem',
  offerTileHeaderGap: '0.167rem',
  offerTileHeaderText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  offerTileHeaderIconSize: '1.333rem',
  offerTileStandardWidth: '14.5rem',
  offerTileBorderRadius: '8px',
  offerTileMiniHeight: '7.833rem',
  offerTileImageAreaHeight: '12.223rem',
  offerTileImageActive: '1',
  offerTileImageInactive: '0.5',
  offerTileProductImageSize: '8.333rem',
  offerTileHeroImageSize: '12.223rem',
  offerTileMiniMinWidth: '23.333rem',
  offerTileMiniMaxWidth: '30.583rem',
  offerTileRewardText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  offerTileRewardTextWas: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    fontSize: '1.333rem',
    lineHeight: '130%',
    letterSpacing: '0',
    textDecoration: 'line-through',
  },
  offerTileTitleText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  offerTileSubcopyText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    fontSize: '1rem',
    lineHeight: '130%',
    letterSpacing: '0',
    textDecoration: 'none',
  },
  offerTileQualificationText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  offerTileQualificationBoldText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  offerTileBorderWidth: '1px',
  offerTileLargeWidth: '29.333rem',
  offerTilePaddingLarge: '1.336rem',
  offerTilePaddingMedium: '0.668rem',
  offerTileMiniQualificationText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  offerTileMiniQualificationBoldText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  offerTileMiniImageSize: '6.167rem',
  offerTileMinHeight: '22.333rem',
  offerTileMiniMinHeight: '7.833rem',
  offerTileMiniMaxHeight: '9.833rem',
  ordersummaryDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  ordersummaryBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  ordersummarySmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  ordersummaryWidthDesktop: '25rem',
  ordersummaryLineItemWidth: '7.083rem',
  ordersummaryTooltipWidth: '16.667rem',
  ordersummaryBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  ordersummaryBorderRadius: '8px',
  ordersummaryPadding: '2.004rem',
  ordersummaryElevation: '0px 0px 0px 0px #000',
  paginationButtonSize: '3rem',
  paginationButtonBorderRadius: '32px',
  paginationButtonText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  paginationBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  paginationIconSize: '1.333rem',
  pcoBasePointsText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  priceText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceTextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceWasTextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceBigDeal: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  priceTextSecondary: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  priceWasText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceWasTextSecondary: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceTextPrimary: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  priceWasTextPrimary: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  priceDescriptor: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceInfoTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  priceInfoSubtitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  priceInfoDetails: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceInfoBorderWidth: '3px',
  priceUnitText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTilePadding: '0.668rem',
  productTileTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  productTilePackageSize: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTilePcoCallout: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileVariants: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileInventory: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileVariantIcon: '1.167rem',
  productTileBorderRadius: '4px',
  productTileBrandTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  productTileImageHeight: '9.167rem',
  productTileBadgeHeight: '1.5rem',
  productTileInfoHeight: '5.167rem',
  productTilePackageSizePadding: '0.167rem',
  productTileMemberPrice: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileHorizAtlMinHeight: '11.75rem',
  productTitleBrandText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  productTileFrameBorderStyle: {
    color: '#EFF8FC',
    width: '2px',
    style: 'solid',
  },
  productTileFrameBadgeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileFrameBadgeHeight: '2.667rem',
  productTileFrameBadgePadding: '0.668rem',
  radiocardTitle: 'radiocard.title',
  radiocardBody: 'radiocard.body',
  radiocardSmallSpacing: '0.334rem',
  radiocardLargeSpacing: '1.336rem',
  radiocardLargePadding: '1.336rem',
  scrollbarBorderRadius: '12px',
  scrollbarHorizontalHeight: '1.167rem',
  scrollbarVerticalWidth: '1rem',
  queryIconSize: '1.667rem',
  queryImageSize: '3.333rem',
  searchItemPaddingRight: '1.336rem',
  searchItemPaddingLeft: '0.668rem',
  queryItemTyped: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  queryItemSuggested: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  queryItemBorderWidth: '1px',
  itemTextPaddingTop: '1.336rem',
  leftIndicatorSize: '3.333rem',
  itemTextPaddingBottom: '1.336rem',
  searchDropdownWidth: '46.167rem',
  searchDropdownPaddingLeft: '0rem',
  searchDropdownPaddingRight: '0rem',
  searchDropdownPaddingTop: '0rem',
  searchDropdownPaddingBottom: '0rem',
  searchDropdownBorderWidth: '8px',
  skeletonProductTileImage: '9.167rem',
  skeletonButtonHeight: '3.333rem',
  skeletonLarge01Desktop: '5.167rem',
  skeletonLarge02Desktop: '4rem',
  skeletonLarge04Desktop: '2.5rem',
  skeletonBodyText: '1.667rem',
  skeletonSmallText: '1.333rem',
  skeletonLarge01Tablet: '4.333rem',
  skeletonLarge02Tablet: '3rem',
  skeletonLarge04Tablet: '2rem',
  skeletonLarge01Mobile: '3.333rem',
  skeletonLarge02Mobile: '2.667rem',
  skeletonLarge04Mobile: '1.833rem',
  sliderBorderRadius: '20px',
  sliderIndicatorPadding: '0.668rem',
  sliderIndicatorSpacing: '0.668rem',
  sliderIndicatorBorder: '1px',
  sliderArrowBorderRadius: '32px',
  sliderArrowDefault: '4.333rem',
  sliderArrowSmall: '3.167rem',
  sliderIndicatorDot: '0.833rem',
  sliderChevronIcon: '2.333rem',
  sliderArrowDistance: '16.667rem',
  starLarge: '1.667rem',
  starSmall: '1.333rem',
  starSpaceBetween: '0rem',
  starRatingReviewText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  starRatingLargeReviewText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tileHeadlineDesktop: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  desktopTileContentPadding: '1.002rem',
  desktopTileTextSpacing: '0.668rem',
  tileHeadlineTablet: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  tileHeadlineMobile: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  tileBodyDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tileBodyTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tabletTilePadding: '1.336rem',
  tabletTileTextSpacing: '0.668rem',
  tileBodyMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  mobileTileContentPadding: '1.336rem',
  mobileTileTextSpacing: '0.668rem',
  tileHorizontalHeight: '16.083rem',
  tileVerticalContentHeight: '17.333rem',
  tileVerticalImageHeight: '14.417rem',
  tileTallVerticalImageHeight: '28.833rem',
  variantsSelectedRing: '4rem',
  variantsSwatch: '3rem',
  variantsPaddingDefault: '0.668rem',
  variantsPaddingLarge: '1.336rem',
  variantsSpacingSmall: '0.334rem',
  variantsBorderRadiusLarge: '40px',
  variantsBorderRadiusMedium: '10px',
  variantsBorderRadiusSmall: '4px',
  variantsSwatchDefault: '1px',
  variantsDropdownIcon: '2rem',
  variantsChipText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  variantsDropdownHeight: '25rem',
};
