import { lazy, useEffect, useState, type ComponentType, type FC } from 'react';

import { VIEW_RESOLVER } from '@/data/constants';

const Footer: FC = () => {
  const { API_VERSION_V2: apiVersion, MAIN_CONTENT_COLLECTION } = VIEW_RESOLVER;
  const [ViewResolverSection, setViewResolverSection] = useState<ComponentType<any> | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      setViewResolverSection(lazy(() => import('ldpViewResolverSdm/Section')));
    }
  }, []);

  return (
    <>
      {ViewResolverSection ? (
        <ViewResolverSection apiVersion={apiVersion} layoutSection={MAIN_CONTENT_COLLECTION} hasFooter />
      ) : null}
    </>
  );
};

export default Footer;
