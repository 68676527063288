import React from 'react';

import ErrorDisplay from '@/components/error-display';

interface IErrors {
  error?: {
    b3?: string;
    bffPath?: string;
    status?: string;
    statusText?: string;
  };
}

const Errors: React.FC<IErrors> = ({ error = {} }) => <ErrorDisplay error={error} />;

export default Errors;
